import { OrderType } from "../../types/common/orderType";
import { ContactAction, ContactActionTypes, ContactState, IContact, SearchContact } from "../../types/contact";

const initialState: ContactState = {
    contactSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Ascending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    searchContacts: [],
    filters: {
        searchText: ""
    },
    sortField: "First Name",
    loading: true,
    error: null
}

export const contactReducer = (state: ContactState = initialState, action: ContactAction): ContactState => {
    switch (action.type) {
        case ContactActionTypes.GET_CONTACTS:
            return { ...state, contactSearchResult: action.payload };
        case ContactActionTypes.LOAD_MORE_CONTACTS:
            return {
                ...state,
                contactSearchResult: {
                    ...action.payload,
                    itemList: state.contactSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case ContactActionTypes.SET_CONTACT_ERROR:
            return { ...state, error: action.payload };
        case ContactActionTypes.SET_CONTACT_LOADING:
            return { ...state, loading: action.payload };
        case ContactActionTypes.SET_CONTACT_PAGE:
            return { ...state, contactSearchResult: { ...state.contactSearchResult, currentPageNumber: action.payload } };
        case ContactActionTypes.SET_SEARCH_CONTACTS:
            return { ...state, searchContacts: action.payload };
        case ContactActionTypes.SET_CONTACT_FILTER:
            return { ...state, filters: { ...state.filters, searchText: action.payload } };
        case ContactActionTypes.SET_CONTACT_SORTFIELD:
            return { ...state, sortField: action.payload };
        case ContactActionTypes.SET_CONTACT_SORT:
            return { ...state, contactSearchResult: { ...state.contactSearchResult, order: action.payload } };
        case ContactActionTypes.CREATE_CONTACT:
            return {
                ...state,
                contactSearchResult: { ...state.contactSearchResult, itemList: [action.payload, ...state.contactSearchResult.itemList] },
                searchContacts: [{ ...action.payload, id: action.payload.id }, ...state.searchContacts]
            }
        case ContactActionTypes.UPDATE_CONTACT:
            return { ...state, contactSearchResult: { ...state.contactSearchResult, itemList: updateContact(state, action.payload) } }
        case ContactActionTypes.REMOVE_CONTACT:
            return {
                ...state,
                contactSearchResult: { ...state.contactSearchResult, itemList: deleteContact(state, action) },
                searchContacts: deleteSearchContact(state, action)
            };
        default: return state;
    }
}

function updateContact(state: ContactState, contactToUpdate: IContact): Array<IContact> {
    return state.contactSearchResult.itemList.map((contact: IContact) => {
        if (contact.id === contactToUpdate.id) return contactToUpdate;
        return contact;
    })
}

function deleteContact(state: ContactState, action: ContactAction): Array<IContact> {
    return state.contactSearchResult.itemList.filter(contact => contact.id !== action.payload)
}

function deleteSearchContact(state: ContactState, action: ContactAction): Array<SearchContact> {
    return state.searchContacts.filter(contact => contact.id !== action.payload)
}