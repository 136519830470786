import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { AppFormStatus, IAppForm, SentByOption } from "../../../../types/appForm";
import { ISnackBarMessageState } from "../../../../types/common/snackBarMessageState";
import { createFormAxios } from "../../../../api/form";
import { Alert, CircularProgress, Container, Grid, Snackbar } from "@mui/material";
import AutocompleteFormSelect from "../AutocompleteFormSelect/AutocompleteFormSelect";
import DraftFormCard from "./DraftFormCard";
import DraftsSortFieldBar from "./DraftsSortFieldBar";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import FormBuildPage from "./FormBuildPage";
import { IntersectionObserverComponent } from "../../../../components/IntersectionObserver/IntersectionObserverComponent";
import MuiButton from "../../../../components/Button/MuiButton";
import Spinner from "../../../../components/Spinner/Spinner";

export default function DraftPage(): JSX.Element {
    const { formSearchResult, searchForms, filters, sortField, loading, error } = useTypedSelector(state => state.draftForm);
    const { searchContacts } = useTypedSelector(state => state.contact);
    const { createDraftForm, getDraftForms, getSearchDraftForms, getSearchContacts, setDraftFormPage, setDraftFormSearchCriteria, loadMoreDraftForms } = useActions();
    const [editCreateForm, setEditCreateForm] = useState<IAppForm | null>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (formSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && formSearchResult.currentPageNumber * formSearchResult.pageSize <= formSearchResult.totalItemCount) {
                loadMoreDraftForms(formSearchResult.pageSize, formSearchResult.currentPageNumber + 1, filters.searchText, filters.contactId, sortField, formSearchResult.order);
                setDraftFormPage(formSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (formSearchResult.itemList.length === 0)
            getDraftForms(formSearchResult.pageSize, formSearchResult.currentPageNumber, filters.searchText, filters.contactId, sortField, formSearchResult.order);
        if (searchForms.length === 0) getSearchDraftForms(AppFormStatus.Draft);
        if (searchContacts.length === 0) getSearchContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const onSearchChanged = (text: string) => {
        setDraftFormPage(1);
        setDraftFormSearchCriteria(text);
        getDraftForms(formSearchResult.pageSize, 1, text, filters.contactId, sortField, formSearchResult.order);
    }

    const onEdit = (form: null | IAppForm) => setEditCreateForm(form);

    const onCreateNewForm = async () => {
        try {
            setLoadingState(true)
            const form: IAppForm = await createFormAxios({
                title: 'Form title',
                statement: 'Form statement',
                logo: null,
                status: AppFormStatus.Draft,
                isRead: false,
                skipSignature: true,
                sentByOption: SentByOption.Link,
                contactId: null,
                userId: JSON.parse(localStorage.getItem("auth")).user.id,
                questions: [],
                createdAt: new Date(new Date().toUTCString())
            })
            createDraftForm(form)
            setEditCreateForm(form)
        } catch {
            setSnackBarState({ message: "Please refresh the page and try again.", severity: "error" });
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <Container maxWidth="lg" className='layout-container' >
            {
                editCreateForm ?
                    <FormBuildPage form={editCreateForm} closeForm={() => setEditCreateForm(null)} />
                    :
                    <>
                        <Helmet>
                            <title>DoYourForm.com - Drafts</title>
                            <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
                        </Helmet>
                        {/* Page Header */}
                        <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid>
                                        <AutocompleteFormSelect forms={searchForms} onChange={onSearchChanged} />
                                    </Grid>
                                    <Grid item>
                                        <DraftsSortFieldBar fields={["Title", "Created At"]} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <MuiButton
                                    variant="contained"
                                    color="inherit"
                                    onClickHandler={onCreateNewForm}
                                >
                                    {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />}
                                    <span style={{ padding: "8px" }}>
                                        + New Form
                                    </span>
                                </MuiButton>
                            </Grid>
                        </Grid>
                        {/* List of form cards */}
                        {
                            formSearchResult.itemList.length > 0 ?
                                <div style={{ margin: "30px 0" }}>
                                    {formSearchResult.itemList.map(form =>
                                        <DraftFormCard key={form.id}
                                            form={form}
                                            onEdit={form => onEdit(form)}
                                        />
                                    )}
                                </div> :
                                loading ? <Spinner /> :
                                    error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> :
                                        <p style={{ padding: "0 15px" }}>No drafts</p>
                        }
                        <IntersectionObserverComponent onIntersection={handleIntersection} />
                        {(formSearchResult.itemList.length > 0 && loading) && <Spinner />}
                    </>
            }
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Container>
    )
}
