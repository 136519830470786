import { OrderType } from "./common/orderType";
import { ISearchResult } from "./common/searchResult";
import { SearchContact } from "./contact";
import { IImage } from "./image";
import { IOption, IQuestion } from "./question";
import { IReminder } from "./reminder";
import { IUser } from "./user";

export interface IAppForm {
    id?: string;
    title: string;
    statement: string;
    logo?: string;
    status: AppFormStatus;
    isRead: boolean;
    skipSignature: boolean;
    sentByOption: SentByOption;
    contactId?: string;
    contact?: SearchContact;// instead IContact because of circular reference
    reminder?: IReminder;
    comment?: string;
    userId: string;
    questions?: IQuestion[],
    createdAt: Date;
    sentAt?: Date;
    completedAt?: Date;
}

export interface SearchForm {
    id: string;
    title: string;
}

export enum AppFormStatus {
    All,
    Draft,
    Done,
    Template,
    Sent
}

export enum SentByOption {
    Email,
    Link
}

export interface FormFilters {
    searchText: string;
    status: AppFormStatus;
    contactId: string;
}

export interface FormState {
    formSearchResult: ISearchResult<IAppForm>;
    searchForms: SearchForm[],
    filters: FormFilters,
    sortField: string,
    loading: boolean;
    error: null | string;
}

export interface FormFillDto {
    form: IAppForm;
    user: IUser;
}

export interface FormFillState {
    form: null | IAppForm;
    user: null | IUser;
    step: number;
    loading: boolean;
    error: null | string;
    showResults: boolean;
    latitude: null | number;
    longitude: null | number;
}

export interface SendForm {
    appFormId: string;
    contacts: SearchContact[];
    sentForms: IAppForm[];
}

export enum FormActionTypes {
    GET_DRAFT_FORMS = "GET_DRAFT_FORMS",
    GET_SEND_FORMS = "GET_SEND_FORMS",
    GET_COMPLETED_FORMS = "GET_COMPLETED_FORMS",
    GET_FORMFILL = "GET_FORMFILL",
    GET_TEMPLATE_FORMS = "GET_TEMPLATE_FORMS",

    LOAD_MORE_DRAFT_FORMS = "LOAD_MORE_DRAFT_FORMS",
    LOAD_MORE_SEND_FORMS = "LOAD_MORE_SEND_FORMS",
    LOAD_MORE_COMPLETED_FORMS = "LOAD_MORE_COMPLETED_FORMS",
    LOAD_MORE_TEMPLATE_FORMS = "LOAD_MORE_TEMPLATE_FORMS",

    SET_DRAFT_FORM_ERROR = "SET_DRAFT_FORM_ERROR",
    SET_SEND_FORM_ERROR = "SET_SEND_FORM_ERROR",
    SET_COMPLETED_FORM_ERROR = "SET_COMPLETED_FORM_ERROR",
    SET_FORMFILL_ERROR = "SET_FORMFILL_ERROR",
    SET_TEMPLATE_FORM_ERROR = "SET_TEMPLATE_FORM_ERROR",

    SET_DRAFT_FORM_LOADING = "SET_DRAFT_FORM_LOADING",
    SET_SEND_FORM_LOADING = "SET_SEND_FORM_LOADING",
    SET_COMPLETED_FORM_LOADING = "SET_COMPLETED_FORM_LOADING",
    SET_FORMFILL_LOADING = "SET_FORMFILL_LOADING",
    SET_TEMPLATE_FORM_LOADING = "SET_TEMPLATE_FORM_LOADING",

    SET_SHOW_RESULTS = "SET_SHOW_RESULTS",

    SET_DRAFT_FORM_PAGE = "SET_DRAFT_FORM_PAGE",
    SET_SEND_FORM_PAGE = "SET_SEND_FORM_PAGE",
    SET_COMPLETED_FORM_PAGE = "SET_COMPLETED_FORM_PAGE",
    SET_FORMFILL_STEP = "SET_FORMFILL_STEP",
    SET_TEMPLATE_FORM_PAGE = "SET_TEMPLATE_FORM_PAGE",

    SET_SEARCH_DRAFT_FORMS = "SET_SEARCH_DRAFT_FORMS",
    SET_SEARCH_SEND_FORMS = "SET_SEARCH_SEND_FORMS",
    SET_SEARCH_COMPLETED_FORMS = "SET_SEARCH_COMPLETED_FORMS",
    SET_SEARCH_TEMPLATE_FORMS = "SET_SEARCH_TEMPLATE_FORMS",

    SET_DRAFT_FORM_FILTER = "SET_DRAFT_FORM_FILTER",
    SET_SEND_FORM_FILTER = "SET_SEND_FORM_FILTER",
    SET_COMPLETED_FORM_FILTER = "SET_COMPLETED_FORM_FILTER",
    SET_TEMPLATE_FORM_FILTER = "SET_TEMPLATE_FORM_FILTER",

    SET_DRAFT_FORM_SORTFIELD = "SET_DRAFT_FORM_SORTFIELD",
    SET_SEND_FORM_SORTFIELD = "SET_SEND_FORM_SORTFIELD",
    SET_COMPLETED_FORM_SORTFIELD = "SET_COMPLETED_FORM_SORTFIELD",
    SET_TEMPLATE_FORM_SORTFIELD = "SET_TEMPLATE_FORM_SORTFIELD",

    SET_DRAFT_FORM_SORT = "SET_DRAFT_FORM_SORT",
    SET_SEND_FORM_SORT = "SET_SEND_FORM_SORT",
    SET_COMPLETED_FORM_SORT = "SET_COMPLETED_FORM_SORT",
    SET_TEMPLATE_FORM_SORT = "SET_TEMPLATE_FORM_SORT",

    SET_DRAFT_FORM_FILTER_STATUS = "SET_DRAFT_FORM_FILTER_STATUS",
    SET_SEND_FORM_FILTER_STATUS = "SET_SEND_FORM_FILTER_STATUS",
    SET_SEND_FORM_FILTER_CONTACT = "SET_SEND_FORM_FILTER_CONTACT",
    SET_COMPLETED_FORM_FILTER_STATUS = "SET_COMPLETED_FORM_FILTER_STATUS",
    SET_COMPLETED_FORM_FILTER_CONTACT = "SET_COMPLETED_FORM_FILTER_CONTACT",

    CREATE_DRAFT_FORM = "CREATE_DRAFT_FORM",
    // CREATE_SEND_FORM = "CREATE_SEND_FORM",
    // CREATE_COMPLETED_FORM = "CREATE_COMPLETED_FORM",
    CREATE_FORMFILL_IMAGES = "CREATE_FORMFILL_IMAGES",
    SEND_FORM = "SEND_FORM",

    UPDATE_DRAFT_FORM = "UPDATE_DRAFT_FORM",
    UPDATE_SEND_FORM = "UPDATE_SEND_FORM",
    UPDATE_COMPLETED_FORM = "UPDATE_COMPLETED_FORM",
    UPDATE_COMPLETED_FORM_ISREAD = "UPDATE_COMPLETED_FORM_ISREAD",
    UPDATE_COMPLETED_FORM_COMMENT = "UPDATE_COMPLETED_FORM_COMMENT",
    UPDATE_FORMFILL = "UPDATE_FORMFILL", //FormFillReducerState
    UPDATE_FORMFILL_QUESTION = "UPDATE_FORMFILL_QUESTION",
    UPDATE_COMPLETED_FORM_QUESTION = "UPDATE_COMPLETED_FORM_QUESTION",
    UPDATE_FORMFILL_OPTION = "UPDATE_FORMFILL_OPTION",
    UPDATE_REMINDER = "UPDATE_REMINDER",

    REMOVE_DRAFT_FORM = "REMOVE_DRAFT_FORM",
    REMOVE_SEND_FORM = "REMOVE_SEND_FORM",
    REMOVE_COMPLETED_FORM = "REMOVE_COMPLETED_FORM",
    REMOVE_FORMFILL_IMAGE = "REMOVE_FORMFILL_IMAGE",
    REMOVE_REMINDER = "REMOVE_REMINDER",

    CREATE_QUESTION = "CREATE_QUESTION",
    UPDATE_QUESTION = "UPDATE_QUESTION",
    REMOVE_QUESTION = "REMOVE_QUESTION",

    SET_GEOPOSITION = "SET_GEOPOSITION"
}

interface GetDraftFormsAction { type: FormActionTypes.GET_DRAFT_FORMS; payload: ISearchResult<IAppForm>; }
interface GetSendFormsAction { type: FormActionTypes.GET_SEND_FORMS; payload: ISearchResult<IAppForm>; }
interface GetCompletedFormsAction { type: FormActionTypes.GET_COMPLETED_FORMS; payload: ISearchResult<IAppForm>; }
interface GetFormToFill { type: FormActionTypes.GET_FORMFILL; payload: FormFillDto }
interface GetTemplateFormsAction { type: FormActionTypes.GET_TEMPLATE_FORMS, payload: ISearchResult<IAppForm>; }

interface LoadMoreDraftFormsAction { type: FormActionTypes.LOAD_MORE_DRAFT_FORMS; payload: ISearchResult<IAppForm>; }
interface LoadMoreSendFormsAction { type: FormActionTypes.LOAD_MORE_SEND_FORMS; payload: ISearchResult<IAppForm>; }
interface LoadMoreCompletedFormsAction { type: FormActionTypes.LOAD_MORE_COMPLETED_FORMS; payload: ISearchResult<IAppForm>; }
interface LoadMoreTemplateFormsAction { type: FormActionTypes.LOAD_MORE_TEMPLATE_FORMS; payload: ISearchResult<IAppForm>; }

interface SetDraftFormErrorAction { type: FormActionTypes.SET_DRAFT_FORM_ERROR; payload: null | string; }
interface SetSendFormErrorAction { type: FormActionTypes.SET_SEND_FORM_ERROR; payload: null | string; }
interface SetCompletedFormErrorAction { type: FormActionTypes.SET_COMPLETED_FORM_ERROR; payload: null | string; }
interface SetFormFillErrorAction { type: FormActionTypes.SET_FORMFILL_ERROR; payload: null | string; }
interface SetTemplateFormErrorAction { type: FormActionTypes.SET_TEMPLATE_FORM_ERROR; payload: null | string; }

interface SetDraftFormLoadingAction { type: FormActionTypes.SET_DRAFT_FORM_LOADING; payload: boolean; }
interface SetSendFormLoadingAction { type: FormActionTypes.SET_SEND_FORM_LOADING; payload: boolean; }
interface SetCompletedFormLoadingAction { type: FormActionTypes.SET_COMPLETED_FORM_LOADING; payload: boolean; }
interface SetFormFillLoadingAction { type: FormActionTypes.SET_FORMFILL_LOADING; payload: boolean; }
interface SetTemplateFormLoadingAction { type: FormActionTypes.SET_TEMPLATE_FORM_LOADING; payload: boolean; }

interface SetShowResults { type: FormActionTypes.SET_SHOW_RESULTS; payload: boolean; }

interface SetDraftFormPageAction { type: FormActionTypes.SET_DRAFT_FORM_PAGE; payload: number; }
interface SetSendFormPageAction { type: FormActionTypes.SET_SEND_FORM_PAGE; payload: number; }
interface SetCompletedFormPageAction { type: FormActionTypes.SET_COMPLETED_FORM_PAGE; payload: number; }
interface SetFormFillStepAction { type: FormActionTypes.SET_FORMFILL_STEP; payload: number; }
interface SetTemplateFormPageAction { type: FormActionTypes.SET_TEMPLATE_FORM_PAGE; payload: number; }

interface SetSearchDraftForms { type: FormActionTypes.SET_SEARCH_DRAFT_FORMS; payload: SearchForm[]; }
interface SetSearchSendForms { type: FormActionTypes.SET_SEARCH_SEND_FORMS; payload: SearchForm[]; }
interface SetSearchCompletedForms { type: FormActionTypes.SET_SEARCH_COMPLETED_FORMS; payload: SearchForm[]; }
interface SetSearchTemplateForms { type: FormActionTypes.SET_SEARCH_TEMPLATE_FORMS; payload: SearchForm[]; }

interface SetDraftFormFilterAction { type: FormActionTypes.SET_DRAFT_FORM_FILTER; payload: string; }
interface SetSendFormFilterAction { type: FormActionTypes.SET_SEND_FORM_FILTER; payload: string; }
interface SetCompletedFormFilterAction { type: FormActionTypes.SET_COMPLETED_FORM_FILTER; payload: string; }
interface SetTemplateFormFilterAction { type: FormActionTypes.SET_TEMPLATE_FORM_FILTER; payload: string; }

interface SetDraftFormSortField { type: FormActionTypes.SET_DRAFT_FORM_SORTFIELD; payload: string; }
interface SetSendFormSortField { type: FormActionTypes.SET_SEND_FORM_SORTFIELD; payload: string; }
interface SetCompletedFormSortField { type: FormActionTypes.SET_COMPLETED_FORM_SORTFIELD; payload: string; }
interface SetTemplateFormSortField { type: FormActionTypes.SET_TEMPLATE_FORM_SORTFIELD; payload: string; }

interface SetDraftFormSort { type: FormActionTypes.SET_DRAFT_FORM_SORT; payload: OrderType; }
interface SetSendFormSort { type: FormActionTypes.SET_SEND_FORM_SORT; payload: OrderType; }
interface SetCompletedFormSort { type: FormActionTypes.SET_COMPLETED_FORM_SORT; payload: OrderType; }
interface SetTemplateFormSort { type: FormActionTypes.SET_TEMPLATE_FORM_SORT; payload: OrderType; }

interface SetDraftFormFilterStatus { type: FormActionTypes.SET_DRAFT_FORM_FILTER_STATUS; payload: AppFormStatus; }
interface SetSendFormFilterStatus { type: FormActionTypes.SET_SEND_FORM_FILTER_STATUS; payload: AppFormStatus; }
interface SetSendFormFilterContact { type: FormActionTypes.SET_SEND_FORM_FILTER_CONTACT; payload: string; }
interface SetCompletedFormFilterStatus { type: FormActionTypes.SET_COMPLETED_FORM_FILTER_STATUS; payload: AppFormStatus; }
interface SetCompletedFormFilterContact { type: FormActionTypes.SET_COMPLETED_FORM_FILTER_CONTACT; payload: string; }

interface CreateDraftFormAction { type: FormActionTypes.CREATE_DRAFT_FORM; payload: IAppForm; }
interface CreateFormFillImagesAction { type: FormActionTypes.CREATE_FORMFILL_IMAGES, payload: IImage[] }
interface SendFormAction { type: FormActionTypes.SEND_FORM; payload: SendForm }

interface UpdateDraftFormAction { type: FormActionTypes.UPDATE_DRAFT_FORM; payload: IAppForm; }
interface UpdateSendFormAction { type: FormActionTypes.UPDATE_SEND_FORM; payload: IAppForm; }
interface UpdateCompletedFormAction { type: FormActionTypes.UPDATE_COMPLETED_FORM; payload: IAppForm; }
interface UpdateCompletedFormIsReadAction { type: FormActionTypes.UPDATE_COMPLETED_FORM_ISREAD; payload: IAppForm; }
interface UpdateCompletedFormCommentAction { type: FormActionTypes.UPDATE_COMPLETED_FORM_COMMENT; payload: IAppForm; }
interface UpdateFormFillAction { type: FormActionTypes.UPDATE_FORMFILL; payload: IAppForm; }
interface UpdateFormFillQuestionAction { type: FormActionTypes.UPDATE_FORMFILL_QUESTION; payload: IQuestion; }
interface UpdateCompletedFormQuestionAction { type: FormActionTypes.UPDATE_COMPLETED_FORM_QUESTION; payload: IQuestion; }
interface UpdateFormFillOptionAction { type: FormActionTypes.UPDATE_FORMFILL_OPTION; payload: IOption; }
interface UpdateFormReminderAction { type: FormActionTypes.UPDATE_REMINDER; payload: IReminder; }

interface RemoveDraftFormAction { type: FormActionTypes.REMOVE_DRAFT_FORM; payload: string; }
interface RemoveSendFormAction { type: FormActionTypes.REMOVE_SEND_FORM; payload: string; }
interface RemoveCompletedFormAction { type: FormActionTypes.REMOVE_COMPLETED_FORM; payload: string; }
interface RemoveFormFillImageAction { type: FormActionTypes.REMOVE_FORMFILL_IMAGE; payload: IImage; }
interface RemoveFormReminderAction { type: FormActionTypes.REMOVE_REMINDER; payload: IReminder; }

interface CreateQuestionAction { type: FormActionTypes.CREATE_QUESTION; payload: IQuestion; }

interface UpdateQuestionAction { type: FormActionTypes.UPDATE_QUESTION; payload: IQuestion; }

interface RemoveQuestionAction { type: FormActionTypes.REMOVE_QUESTION; payload: IQuestion; }

interface SetGeoPositionAction { type: FormActionTypes.SET_GEOPOSITION; payload: { latitude: number, longitude: number } }

export type FormAction = GetDraftFormsAction | GetSendFormsAction | GetCompletedFormsAction | GetFormToFill | GetTemplateFormsAction |
    LoadMoreDraftFormsAction | LoadMoreSendFormsAction | LoadMoreCompletedFormsAction | LoadMoreTemplateFormsAction |
    SetDraftFormErrorAction | SetSendFormErrorAction | SetCompletedFormErrorAction | SetFormFillErrorAction | SetTemplateFormErrorAction |
    SetDraftFormLoadingAction | SetSendFormLoadingAction | SetCompletedFormLoadingAction | SetFormFillLoadingAction | SetTemplateFormLoadingAction |
    SetDraftFormPageAction | SetSendFormPageAction | SetCompletedFormPageAction | SetFormFillStepAction | SetTemplateFormPageAction |
    SetShowResults |
    SetSearchDraftForms | SetSearchSendForms | SetSearchCompletedForms | SetSearchTemplateForms |
    SetDraftFormFilterAction | SetSendFormFilterAction | SetCompletedFormFilterAction | SetTemplateFormFilterAction |
    SetDraftFormSortField | SetSendFormSortField | SetCompletedFormSortField | SetTemplateFormSortField |
    SetDraftFormSort | SetSendFormSort | SetCompletedFormSort | SetTemplateFormSort |
    SetDraftFormFilterStatus | SetSendFormFilterStatus | SetSendFormFilterContact | SetCompletedFormFilterStatus | SetCompletedFormFilterContact |
    CreateDraftFormAction | CreateFormFillImagesAction | SendFormAction |
    UpdateDraftFormAction | UpdateSendFormAction | UpdateCompletedFormAction | UpdateCompletedFormIsReadAction | UpdateCompletedFormCommentAction | UpdateFormFillAction | UpdateFormFillQuestionAction | UpdateCompletedFormQuestionAction | UpdateFormFillOptionAction | UpdateFormReminderAction |
    RemoveDraftFormAction | RemoveSendFormAction | RemoveCompletedFormAction | RemoveFormFillImageAction | RemoveFormReminderAction |
    CreateQuestionAction |
    UpdateQuestionAction |
    RemoveQuestionAction |
    SetGeoPositionAction
