import { OrderType } from "../../types/common/orderType";
import { AppFormStatus, FormAction, FormActionTypes, FormState, IAppForm } from "../../types/appForm";
import { IQuestion } from "../../types/question";

const initialState: FormState = {
    formSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Descending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    searchForms: [],
    filters: {
        searchText: "",
        status: AppFormStatus.Done,
        contactId: ""
    },
    sortField: "Completed At",
    loading: false,
    error: null
}

export const completedFormReducer = (state: FormState = initialState, action: FormAction): FormState => {
    switch (action.type) {
        case FormActionTypes.GET_COMPLETED_FORMS:
            return { ...state, formSearchResult: action.payload };
        case FormActionTypes.LOAD_MORE_COMPLETED_FORMS:
            return {
                ...state,
                formSearchResult: {
                    ...action.payload,
                    itemList: state.formSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case FormActionTypes.SET_COMPLETED_FORM_ERROR:
            return { ...state, error: action.payload };
        case FormActionTypes.SET_COMPLETED_FORM_LOADING:
            return { ...state, loading: action.payload };
        case FormActionTypes.SET_COMPLETED_FORM_PAGE:
            return { ...state, formSearchResult: { ...state.formSearchResult, currentPageNumber: action.payload } };
        case FormActionTypes.SET_SEARCH_COMPLETED_FORMS:
            return { ...state, searchForms: action.payload };
        case FormActionTypes.SET_COMPLETED_FORM_FILTER:
            return { ...state, filters: { ...state.filters, searchText: action.payload } };
        case FormActionTypes.SET_COMPLETED_FORM_SORTFIELD:
            return { ...state, sortField: action.payload };
        case FormActionTypes.SET_COMPLETED_FORM_SORT:
            return { ...state, formSearchResult: { ...state.formSearchResult, order: action.payload } };
        case FormActionTypes.SET_COMPLETED_FORM_FILTER_STATUS:
            return { ...state, filters: { ...state.filters, status: action.payload } };
        case FormActionTypes.SET_COMPLETED_FORM_FILTER_CONTACT:
            return { ...state, filters: { ...state.filters, contactId: action.payload } }
        case FormActionTypes.UPDATE_COMPLETED_FORM:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateForm(state, action.payload) } }
        case FormActionTypes.UPDATE_COMPLETED_FORM_QUESTION:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateFormsQuestion(state, action.payload) } }
        case FormActionTypes.UPDATE_COMPLETED_FORM_ISREAD:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateForm(state, action.payload) } }
        case FormActionTypes.UPDATE_COMPLETED_FORM_COMMENT:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateForm(state, action.payload) } }
        case FormActionTypes.REMOVE_COMPLETED_FORM:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: deleteForm(state, action) } };
        default: return state;
    }
}

function updateForm(state: FormState, formToUpdate: IAppForm): Array<IAppForm> {
    return state.formSearchResult.itemList.map((form: IAppForm) => {
        if (form.id === formToUpdate.id) return formToUpdate;
        return form;
    })
}

function updateFormsQuestion(state: FormState, questionToUpdate: IQuestion): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === questionToUpdate.appFormId)[0] };
    formToUpdate.questions = formToUpdate.questions.map((question: IQuestion) => {
        if (question.id === questionToUpdate.id) return questionToUpdate;
        return question;
    })

    return updateForm(state, formToUpdate);
}

function deleteForm(state: FormState, action: FormAction): Array<IAppForm> {
    return state.formSearchResult.itemList.filter(form => form.id !== action.payload)
}


