import { OrderType } from "../../types/common/orderType";
import { PricepackageAction, PricepackageActionTypes, PricepackageState, IPricepackage } from "../../types/pricepackage";

const initialState: PricepackageState = {
    pricepackageSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Ascending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    sortField: "Price",
    loading: true,
    error: null
}

export const pricepackageReducer = (state: PricepackageState = initialState, action: PricepackageAction): PricepackageState => {
    switch (action.type) {
        case PricepackageActionTypes.GET_PRICEPACKAGES:
            return { ...state, pricepackageSearchResult: action.payload };
        case PricepackageActionTypes.SET_PRICEPACKAGE_ERROR:
            return { ...state, error: action.payload };
        case PricepackageActionTypes.SET_PRICEPACKAGE_LOADING:
            return { ...state, loading: action.payload };
        case PricepackageActionTypes.SET_PRICEPACKAGE_PAGE:
            return { ...state, pricepackageSearchResult: { ...state.pricepackageSearchResult, currentPageNumber: action.payload } };
        case PricepackageActionTypes.SET_PRICEPACKAGE_SORTFIELD:
            return { ...state, sortField: action.payload };
        case PricepackageActionTypes.SET_PRICEPACKAGE_SORT:
            return { ...state, pricepackageSearchResult: { ...state.pricepackageSearchResult, order: action.payload } };
        case PricepackageActionTypes.CREATE_PRICEPACKAGE:
            return { ...state, pricepackageSearchResult: { ...state.pricepackageSearchResult, itemList: [action.payload, ...state.pricepackageSearchResult.itemList] } }
        case PricepackageActionTypes.UPDATE_PRICEPACKAGE:
            return { ...state, pricepackageSearchResult: { ...state.pricepackageSearchResult, itemList: updatePricepackage(state, action.payload) } }
        case PricepackageActionTypes.REMOVE_PRICEPACKAGE:
            return { ...state, pricepackageSearchResult: { ...state.pricepackageSearchResult, itemList: deletePricepackage(state, action) } };
        default: return state;
    }
}

function updatePricepackage(state: PricepackageState, contactToUpdate: IPricepackage): Array<IPricepackage> {
    return state.pricepackageSearchResult.itemList.map((contact: IPricepackage) => {
        if (contact.id === contactToUpdate.id) return contactToUpdate;
        return contact;
    })
}

function deletePricepackage(state: PricepackageState, action: PricepackageAction): Array<IPricepackage> {
    return state.pricepackageSearchResult.itemList.filter(contact => contact.id !== action.payload)
}
