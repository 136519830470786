import { useState } from "react";
import { FormQuestionCardProps } from '../types'
import { InputType } from "../../../../types/question";
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AppDeleteConfirmDialog from "../../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog";
import StyledDeleteIcon from '../../../../components/StyledIcons/StyledDeleteIcon';
import StyledEditIcon from "../../../../components/StyledIcons/StyledEditIcon";

export default function FormQuestionCard({ question, onDeleteQuestion, onDeleteOption, onEdit, shomMoveUp, showMoveDown, onMoveUp, onMoveDown }: FormQuestionCardProps) {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);

    const handleClose = () => setAnchorElUser(null);

    const convertInputTypeToHumanRedableText = (type: InputType): string => {
        switch (type) {
            case InputType.Checkbox:
                return "Check boxes";
            case InputType.Date:
                return "Date";
            case InputType.Number:
                return "Number";
            case InputType.Radio:
                return "Radio buttons";
            case InputType.Time:
                return "Time";
            default:// InputType.Text
                return "Text field";
        }
    }

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                <QuestionAnswerOutlinedIcon sx={{ fill: '#5F7C78' }} />
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, margin: "0 10px" }}>
                <span>{question.text}</span>
                <span className="text-12" style={{ color: "var(--darkGrey)" }}>
                    {convertInputTypeToHumanRedableText(question.inputType)}
                </span>
                {
                    (question.inputType === InputType.Checkbox || question.inputType === InputType.Radio) &&
                    question.options.length > 0 &&
                    <Grid item>
                        {
                            question.options.map((option) => (
                                <div key={option.id} style={{ display: "flex", padding: "5px 0", maxWidth: "800px" }}>
                                    <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                                        {
                                            question.inputType === InputType.Checkbox ?
                                                <CheckBoxOutlineBlankIcon fontSize="small" sx={{ fill: '#5F7C78' }} /> :
                                                <CircleOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        }
                                    </div>
                                    <div style={{ flexGrow: 1, margin: "0 10px" }}>
                                        <span className="text-14">{option.value}</span>
                                    </div>
                                    <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                                        <Tooltip title="Remove Option">
                                            <DeleteOutlineOutlinedIcon
                                                sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                                onClick={() => onDeleteOption(option)}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            ))
                        }
                    </Grid>
                }
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "140px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    {
                        shomMoveUp &&
                        <div style={{ display: "flex" }}>
                            <Tooltip title='Move Up' placement="top">
                                <ArrowUpwardIcon
                                    sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                    onClick={() => onMoveUp(question)}
                                />
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                        </div>
                    }
                    {
                        showMoveDown &&
                        <div style={{ display: "flex" }}>
                            <Tooltip title='Move Down' placement="top">
                                <ArrowDownwardIcon
                                    sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                    onClick={() => onMoveDown(question)}
                                />
                            </Tooltip>
                            <Divider orientation="vertical" flexItem />
                        </div>
                    }
                    <StyledEditIcon tooltipTitle="Edit Question" onEdit={() => onEdit(question)} />
                    <Divider orientation="vertical" flexItem />
                    <StyledDeleteIcon tooltipTitle="Remove Question" onDelete={() => setConfirmDialogOpen(true)} />
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        {
                            shomMoveUp &&
                            <MenuItem onClick={handleClose}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => onMoveUp(question)}>
                                    <ArrowUpwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                    <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Move Up</Typography>
                                </Grid>
                            </MenuItem>
                        }
                        {
                            showMoveDown &&
                            <MenuItem onClick={handleClose}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => onMoveDown(question)}>
                                    <ArrowDownwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                    <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Move Down</Typography>
                                </Grid>
                            </MenuItem>
                        }
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => onEdit(question)}>
                                <EditIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Edit</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Remove</Typography>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {confirmDialogOpen && <AppDeleteConfirmDialog onCancel={() => setConfirmDialogOpen(false)} onDelete={() => onDeleteQuestion(question)} />}
        </div>
    )
}
