import { Helmet } from "react-helmet";
import { Container, Grid } from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import ProfileForm from "./ProfileForm";

export default function ProfilePage(): JSX.Element {
    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Profile</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Profile" text="Check or update your profile settings." />
            <Grid container direction="column" alignItems="center">
                <ProfileForm />
            </Grid>
        </Container>
    )
}