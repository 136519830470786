import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { clonedObject } from '../../../../hooks/clonedObject';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { ReminderFormProps } from '../types';
import { IReminder, ReminderPeriod } from '../../../../types/reminder';
import { createReminderAxios, removeReminderAxios, updateReminderAxios } from '../../../../api/reminder';
import { FormControl, Grid, InputLabel, MenuItem, Select, SwipeableDrawer } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ErrorMessage from '../../../../components/Messages/ErrorMessage';
import MuiButton from '../../../../components/Button/MuiButton';

export default function ReminderForm({ reminder, closeForm }: ReminderFormProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const { createReminder, removeReminder, updateDraftFormReminder, updateAuthUser } = useActions();
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [loadingRemoveState, setLoadingRemoveState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<null | string>(null);
    const [startDateState, setStartDateState] = useState<moment.Moment | null>(moment.utc(reminder.start).local());
    const [finishDateState, setFinishDateState] = useState<Date>(reminder.finish);

    const periods: ReminderPeriod[] = [ReminderPeriod["Business days"], ReminderPeriod.Daily, ReminderPeriod.Weekly, ReminderPeriod.Monthly, ReminderPeriod.Yearly];

    const toggleDrawer = (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) return;
            if (!open) closeForm();
        };

    const validationSchema = Yup.object({})

    const defaultValues: IReminder = {
        id: reminder.id,
        appFormId: reminder.appFormId,
        period: reminder.period,
        start: reminder.start,
        next: reminder.next,
        finish: reminder.finish,
        timezoneOffset: reminder.timezoneOffset
    }

    const { control, handleSubmit, reset } = useForm({ resolver: yupResolver(validationSchema), defaultValues })

    const onChangeStartTime = (value: moment.Moment) => setStartDateState(value);

    const onRemove = async () => {
        try {
            setLoadingRemoveState(true);
            setErrorState(null);
            await removeReminderAxios(reminder.id);
            removeReminder(reminder);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentReminders -= 1;
            updateAuthUser(userToUpdate);
            onCancel();
        } catch (error) {
            setErrorState(error.message || 'Unable to remove the reminder.');
        } finally {
            setLoadingRemoveState(false);
        }
    }

    const onSubmit = async (reminder: IReminder): Promise<void> => {
        setErrorState(null);
        if (moment(startDateState).toDate() < new Date() || finishDateState < new Date()) {
            setErrorState('Past Date & Time disabled.');
            return;
        }
        setLoadingState(true);
        reminder.start = moment(startDateState).toDate();
        reminder.next = moment(startDateState).toDate();
        reminder.finish = finishDateState;
        try {
            if (reminder.id) {
                updateDraftFormReminder(await updateReminderAxios(reminder))
            } else {
                createReminder(await createReminderAxios(reminder));
                let userToUpdate = clonedObject(auth.user);
                userToUpdate.account.currentReminders += 1;
                updateAuthUser(userToUpdate);
            }
            onCancel();
        } catch (error) {
            // todo: check and add it: if error.message==="Unsupported MediaType" setErrorState("Please refresh the page and try again");
            setErrorState(error.message || 'Unable to save the reminder.');
        } finally {
            setLoadingState(false);
        }
    }

    const onCancel = () => {
        reset();
        closeForm();
    }

    return (
        <SwipeableDrawer
            open={true}
            anchor='right'
            transitionDuration={1500}
            sx={{ zIndex: 1202 }}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={onCancel}
            />
            <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '360px' }}>
                <Grid container direction={'column'} justifyContent="center" spacing={2} sx={{ marginTop: '20px', padding: '20px' }}>
                    <Grid item>
                        <FormControl sx={{ width: 260 }}>
                            <InputLabel id="level-label">Period</InputLabel>
                            <Controller
                                name="period"
                                defaultValue={periods[0]}
                                control={control}
                                render={({ field }) => (
                                    <Select label="Period" {...field}>
                                        {periods.map((period, index) =>
                                            <MenuItem key={index} value={period}>{ReminderPeriod[period]}</MenuItem>)}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Controller name="start" control={control}
                            render={({ field }) =>
                                <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
                                    <DateTimePicker
                                        {...field}
                                        label="Start Date"
                                        disablePast
                                        value={startDateState}
                                        onChange={(newValue) => onChangeStartTime(newValue)}
                                    />
                                </LocalizationProvider>
                            } />
                    </Grid>
                    <Grid item>
                        <Controller name="finish" control={control}
                            render={({ field }) =>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        {...field}
                                        label="Finish Date"
                                        disablePast
                                        value={moment(finishDateState)}
                                        onChange={(newValue) => setFinishDateState(newValue.toDate())}
                                    />
                                </LocalizationProvider>
                            } />
                    </Grid>
                    {
                        errorState &&
                        <ErrorMessage appearance="small" >
                            <p style={{ maxWidth: "260px", color: "var(--red)" }}>{errorState}</p>
                        </ErrorMessage>
                    }
                </Grid>
                <Grid container direction="row" justifyContent="space-around" alignItems="center" mb={2}>
                    <MuiButton variant="outlined" onClickHandler={onRemove} disabled={reminder.id === undefined}
                        color={reminder.id === undefined ? "inherit" : "error"}>
                        {loadingRemoveState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Remove
                    </MuiButton>
                    <MuiButton variant="outlined" type="submit">
                        {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                    </MuiButton>
                </Grid>
            </form>
        </SwipeableDrawer>
    )
}
