import { Dispatch } from "redux";
import { getFormsAxios, updateFormAxios, getSearchFormsAxios, updateFormCommentAxios } from "../../api/form";
import { OrderType } from "../../types/common/orderType";
import { IAppForm, FormAction, FormActionTypes, AppFormStatus } from "../../types/appForm";
import { IQuestion } from "../../types/question";
import { updateQuestionAxios } from "../../api/question";

export const getCompletedForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_FILTER_STATUS, payload: AppFormStatus.Done })
            dispatch({
                type: FormActionTypes.GET_COMPLETED_FORMS, payload:
                    await getFormsAxios(limit, page, search || "", contactId || "", AppFormStatus.Done, sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: false });
        }
    }
}

export const loadMoreCompletedForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            dispatch({
                type: FormActionTypes.LOAD_MORE_COMPLETED_FORMS, payload:
                    await getFormsAxios(limit, page,
                        search || "", contactId || "",
                        AppFormStatus.Done, sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: false });
        }
    }
}

export const getSearchCompletedForms = (status: AppFormStatus) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_SEARCH_COMPLETED_FORMS, payload: await getSearchFormsAxios(status) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Error of loading search Forms." })
        }
    }
}

export const setCompletedFormError = (message: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: message });
}

export const setCompletedFormLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: isLoading });
}

export const setCompletedFormPage = (page: number) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_PAGE, payload: page });
}

export const setCompletedFormSearchCriteria = (search: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_FILTER, payload: search });
}

export const setCompletedFormSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_SORTFIELD, payload: sortField });
}

export const setCompletedFormSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_SORT, payload: sort });
}

export const setCompletedFormContactFilter = (contactId: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_FILTER_CONTACT, payload: contactId });
}

export const updateCompletedForm = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.UPDATE_COMPLETED_FORM, payload: await updateFormAxios(form) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Error while updating the form." })
        } finally {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: false });
        }
    }
}

export const updateCompletedFormQuestion = (question: IQuestion) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.UPDATE_COMPLETED_FORM_QUESTION, payload: await updateQuestionAxios(question) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Error while updating the question." })
        } finally {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: false });
        }
    }
}

export const updateCompletedFormIsRead = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.UPDATE_COMPLETED_FORM_ISREAD, payload: form });
}

export const updateCompletedFormComment = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: null });
            await updateFormCommentAxios(form.id, form.comment)
            dispatch({ type: FormActionTypes.UPDATE_COMPLETED_FORM_COMMENT, payload: form });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_ERROR, payload: error.message || "Unable to update the comment to the form." })
        } finally {
            dispatch({ type: FormActionTypes.SET_COMPLETED_FORM_LOADING, payload: false });
        }
    }
}

export const removeCompletedForm = (id: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.REMOVE_COMPLETED_FORM, payload: id });
}