import axios from 'axios';
import { IOption } from '../types/question';

/**
 * Creates a new option
 * @param option<IOption> object of type IOption
 * @returns<IOption> Created option
 */
export async function createOptionAxios(option: IOption): Promise<IOption> {
    return (await axios.post("/option/create", option)).data;
}

/**
 * Updates the existing option
 * @param form<IOption> Object of type IOption
 * @returns<IOption> Updated option
 */
export async function updateOptionAxios(option: IOption): Promise<IOption> {
    return (await axios.put("/option/update", option)).data;
}

/**
 * Delete's the option specified by identifier
 * @param id<string> Identifier
 */
export async function removeOptionAxios(id: string): Promise<void> {
    return await axios.delete(`/option/delete/${id}`);
}