import axios from 'axios';
import { IQuestion } from '../types/question';

/**
 * Creates a new question
 * @param question<IQuestion> object of type IQuestion
 * @returns<IQuestion> Created question
 */
export async function createQuestionAxios(question: IQuestion): Promise<IQuestion> {
    return (await axios.post("/question/create", question)).data;
}

/**
 * Updates the existing question
 * @param form<IQuestion> Object of type IQuestion
 * @returns<IQuestion> Updated question
 */
export async function updateQuestionAxios(question: IQuestion): Promise<IQuestion> {
    return (await axios.put("/question/update", question)).data;
}

/**
 * Delete's the question specified by identifier
 * @param id<string> Identifier
 */
export async function removeQuestionAxios(id: string): Promise<void> {
    return await axios.delete(`/question/delete/${id}`);
}