import { useState } from 'react';
import { IAppForm } from '../../../types/appForm';
import { TemplateCardProps } from './types';
import { ISnackBarMessageState } from '../../../types/common/snackBarMessageState';
import { updateAccountAxios } from '../../../api/account';
import { copyTemplateFormAxios, getImagesSizeAxios } from '../../../api/form';
import { clonedObject } from '../../../hooks/clonedObject';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Alert, CircularProgress, Grid, Snackbar } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MuiButton from '../../../components/Button/MuiButton';

export default function TemplateCard({ form, onPreview }: TemplateCardProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const { createDraftForm, updateAuthUser } = useActions();
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const onCopyHandler = async () => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        try {
            setLoading(true);
            const filesSizeToBeCopied = await getImagesSizeAxios(form);
            if (auth.user.account.currentFileSize + filesSizeToBeCopied > auth.user.account.fileSizeLimit) {
                setSnackBarState({ message: "Your files size limit doesn't allow to copy template.", severity: "warning" });
                return;
            }
            const draftForm: IAppForm = await copyTemplateFormAxios(form);
            createDraftForm(draftForm);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize += filesSizeToBeCopied;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
            setSnackBarState({ message: "New draft created.", severity: "success" });
        } catch {
            setSnackBarState({ message: "Unable to create a new Draft.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid item xs={12} md={4}>
            <p className='pt2' style={{ padding: '0 20px', textAlign: 'center' }}>{form.title}</p>
            <p style={{ textAlign: 'center', padding: '0 20px' }}>
                {
                    form.logo ?
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            width='100%'
                            alt=''
                            style={{ margin: '0' }}
                        /> :
                        <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                }
            </p>
            <p style={{ textAlign: 'center' }}>
                <span style={{ display: "inline-block", margin: "4px" }}>
                    <MuiButton size='small' variant='contained' onClickHandler={() => onPreview(form)}>Preview</MuiButton>
                </span>
                <span style={{ display: "inline-block", margin: "4px" }}>
                    <MuiButton size='small' variant='contained' onClickHandler={() => onCopyHandler()}>
                        {loading && <CircularProgress size="1rem" sx={{ mr: '10px', color: "white" }} />} Use as draft
                    </MuiButton>
                </span>
            </p>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Grid>
    )
}
