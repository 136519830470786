import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Avatar, Grid } from '@mui/material'
import StepsHeader from './components/StepsHeader';
import PageHeader from '../../components/PageHeader/PageHeader';
import ErrorMessage from '../../components/Messages/ErrorMessage';

export default function StepNotFound() {
    const { error } = useTypedSelector(state => state.formFill)

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'} >
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor="#158f7d" isPhoneDialogActive={false} />
            </Grid>
            <Grid item sx={{ margin: "45px 0 5px" }}>
                <Avatar
                    alt="Budda"
                    src={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/Assets/budda-avatar.png"}
                    sx={{ width: 250, height: 250 }}
                />
            </Grid>
            <Grid item>
                <PageHeader title="Oops! The form is no longer available." />
            </Grid>
            {
                error &&
                <div>
                    <ErrorMessage appearance="small" >{error}</ErrorMessage>
                </div>
            }
            <Grid item>
                <p style={{ textAlign: 'center' }}>Please contact form owner for more details.</p>
            </Grid>
        </Grid>
    )
}
