import { useState } from "react";
import { OrderType } from "../../../../types/common/orderType";
import { SortFieldBarProps } from "../../../../types/common/sortFieldBarProps";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function DraftsSortFieldBar({ fields }: SortFieldBarProps): JSX.Element {
    const { formSearchResult, filters, sortField } = useTypedSelector(state => state.draftForm);
    const { getDraftForms, setDraftFormPage, setDraftFormSortfield, setDraftFormSort } = useActions();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleClose = () => setAnchorElUser(null);

    const onSelectHandler = (field: string) => {
        if (field === sortField) {
            formSearchResult.order === OrderType.Ascending ? setDraftFormSort(OrderType.Descending) : setDraftFormSort(OrderType.Ascending)
        } else {
            setDraftFormSortfield(field);
        }
        setDraftFormPage(1);
        getDraftForms(formSearchResult.pageSize, 1, filters.searchText, filters.contactId, field,
            formSearchResult.order === OrderType.Ascending ? OrderType.Descending : OrderType.Ascending);
    }

    return (
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Sort by">
                <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                    <SortOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-sortfield-bar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClose}
            >
                {
                    fields.map(field =>
                        <MenuItem key={field} onClick={() => {
                            onSelectHandler(field)
                            handleClose();
                        }}>
                            {field}
                            &nbsp;
                            {
                                field === sortField ?
                                    formSearchResult.order === OrderType.Ascending ?
                                        <ArrowUpwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        :
                                        <ArrowDownwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                    : null
                            }
                        </MenuItem>
                    )
                }
            </Menu>
        </Box>
    )
}
