import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import moment from "moment";
import { clonedObject } from "../../../hooks/clonedObject";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { isPhoneValid } from "../../../hooks/IsPhoneValid";
import { isAppFileValid } from "../../../hooks/IsAppFileValid";
import { IContact } from "../../../types/contact";
import { ContactFormProps } from "./types";
import { EMAIL_REG_EXP } from "../../../types/common/RegularExpressions";
import { ISnackBarMessageState } from "../../../types/common/snackBarMessageState";
import { createContactAxios, updateContactAxios } from "../../../api/contact";
import { removeImageAxios, uploadImageAxios } from "../../../api/image";
import { Alert, Button, Grid, IconButton, InputAdornment, Snackbar, SwipeableDrawer, TextField, Tooltip } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactFormCard from "./ContactFormCard";
import MuiButton from "../../../components/Button/MuiButton";
import ErrorMessage from "../../../components/Messages/ErrorMessage";

export default function ContactForm({ contact, closeForm }: ContactFormProps): JSX.Element {
    const { auth } = useTypedSelector(state => state.auth);
    const { createContact, updateContact, updateAuthUser } = useActions();
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<null | string>(null);
    const [birthdayState, setBirthdayState] = useState<Date>(contact.birthday);

    const [previewBlockVisible, setPreviewBlockVisible] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [avatarSource, setAvatarSource] = useState<string | ArrayBuffer | null | Blob>(null);

    useEffect(() => {
        if (contact.avatar) {
            setAvatarSource((process.env.NODE_ENV === "production" ?
                process.env.REACT_APP_BASE_API_URL_PROD :
                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + contact.avatar);
            setPreviewBlockVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const fileSelectedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        if (e.target.files) {
            const file = e.target.files[0];
            if (!isAppFileValid(file)) {
                setSnackBarState({ message: "File is not valid", severity: "warning" });
                return;
            }
            setSelectedFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => { setAvatarSource(reader.result) }
        }
        setPreviewBlockVisible(true);
    }

    const deleteHandler = () => {
        setSelectedFile(null);
        setAvatarSource(null);
        setPreviewBlockVisible(false);
    }

    const toggleDrawer = (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            if (!open) closeForm();
        };

    const validationSchema = Yup.object({
        firstName: Yup.string()
            .required('Required field.')
            .max(20, 'First name may not be greater than 20 characters.'),
        lastName: Yup.string()
            .required('Required field.')
            .max(20, 'Last name may not be greater than 20 characters.'),
        email: Yup.string()
            .required('Required field.')
            .max(256, 'Email may not be greater than 256 characters.')
            .matches(EMAIL_REG_EXP, "Email is not valid and may not be greater than 256 characters.")
    })

    const defaultValues: IContact = {
        id: contact.id,
        firstName: contact.firstName || '',
        lastName: contact.lastName || '',
        phone: contact.phone || '',
        email: contact.email || '',
        linkForMessage: contact.linkForMessage || '',
        position: contact.position || '',
        company: contact.company || '',
        birthday: contact.birthday,
        avatar: contact.avatar || '',
        notes: contact.notes || '',
        appForms: contact.appForms,
        userId: contact.userId,
        createdAt: contact.createdAt
    }

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    })

    const onChangeBirthday = (date: Date) => {
        const now = new Date();
        // add time to date
        const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), now.getHours(), now.getMinutes());
        setBirthdayState(newDate);
    }

    const onSubmit = async (contact: IContact): Promise<void> => {
        const offset = new Date().getTimezoneOffset();
        contact.birthday = moment(birthdayState).add(-offset, 'm').toDate();
        try {
            setLoadingState(true);
            setErrorState(null);
            if (contact.id) {
                if (contact.avatar && avatarSource === null) { //avatar file was deleted during editing the form
                    await removeImageAxios(contact.avatar);
                    contact.avatar = null;
                }
                if (selectedFile) { // avatar file was updated so - remove old file and then upload new file:
                    if (contact.avatar) await removeImageAxios(contact.avatar);
                    const uploadedFile = await uploadImageAxios(selectedFile);
                    contact.avatar = uploadedFile.fileName;
                }
                const contactToUpdate = await updateContactAxios(contact);
                updateContact(contactToUpdate);
            } else {
                if (selectedFile) {
                    const uploadedFile = await uploadImageAxios(selectedFile);
                    contact.avatar = uploadedFile.fileName;
                }
                const contactToCreate = await createContactAxios(contact);
                createContact(contactToCreate);
                let userToUpdate = clonedObject(auth.user);
                userToUpdate.account.currentContacts += 1;
                updateAuthUser(userToUpdate);
            }
            onCancelHandler();
        } catch (error) {
            setErrorState(error.message || "Unable to save the contact.");
        } finally {
            setLoadingState(false);
        }
    }

    const onCancelHandler = () => {
        reset();
        closeForm();
    }

    return (
        <SwipeableDrawer
            open={true}
            anchor='right'
            transitionDuration={1500}
            sx={{ zIndex: 1202 }}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={onCancelHandler}
            />
            <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '360px' }}>
                <Grid container direction={'column'} justifyContent="center" spacing={2} sx={{ padding: '20px' }}>
                    <Grid item>
                        {previewBlockVisible ?
                            <div className='preview-wrapper'>
                                <img
                                    src={avatarSource && typeof (avatarSource) != undefined ?
                                        avatarSource.toString() :
                                        (process.env.NODE_ENV === "production" ?
                                            process.env.REACT_APP_BASE_API_URL_PROD :
                                            process.env.REACT_APP_BASE_API_URL_DEV) + "/Assets/default-avatar.png"
                                    }
                                    alt="Preview" className='preview-image'
                                />
                                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        onClick={deleteHandler}
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                    >
                                        <DeleteOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Delete</span>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                    >
                                        <EditOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Replace</span>
                                        <input
                                            type="file"
                                            hidden
                                            onChange={fileSelectedHandler}
                                        />
                                    </Button>
                                </Grid>
                            </div>
                            :
                            <div className='uploader-wrapper'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '10px 0' }}
                                >
                                    <Tooltip title="Choose from gallery" placement="bottom">
                                        <AddAPhotoIcon fontSize="large" sx={{ fill: '#5F7C78' }} />
                                    </Tooltip>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={fileSelectedHandler}
                                    />
                                </Button>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    style={{ textTransform: 'none', boxShadow: 'none', margin: '15px 0' }}
                                >
                                    <AddAPhotoOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                    <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Add avatar</span>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={fileSelectedHandler}
                                    />
                                </Button>
                            </div>
                        }
                    </Grid>
                    <Grid item>
                        <Controller name="firstName" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="First Name" type="text" margin="normal" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <AccountCircleIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }}
                                    error={Boolean(errors.firstName)} helperText={errors.firstName?.message} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="lastName" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Last Name" type="text" margin="normal" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <AccountCircleIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }}
                                    error={Boolean(errors.lastName)} helperText={errors.lastName?.message} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) =>
                                <TextField  {...field}
                                    label="Email" type="email" margin="normal" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <MailOutlineIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }}
                                    error={Boolean(errors.email)} helperText={errors.email?.message}
                                />
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="phone" control={control}
                            render={({ field }) =>
                                <>
                                    <TextField {...field} label="Phone" type="text" margin="normal" fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton edge="end" >
                                                        <PhoneIcon />
                                                    </IconButton>
                                                </InputAdornment>),
                                        }}
                                        error={Boolean(errors.phone)} helperText={errors.phone?.message} />
                                    {
                                        !isPhoneValid(field.value) &&
                                        <p className="error-wrapper">
                                            Phone number is not valid. Must contain from 11 up to 13 characters, valid formats: +31636363634, 1234567890, 075-63546725, 123-456-7890, (123)456-7890, (123) 456-7890, 123.456.7890.
                                        </p>
                                    }
                                </>
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="linkForMessage" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Link for message" type="text" margin="normal" fullWidth
                                    helperText={"Write down link to a messanger like Facebook. LinkedIn, etc."}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <AccountCircleIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="position" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Position" type="text" margin="normal" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <AccountCircleIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="company" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Company" type="text" margin="normal" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end" >
                                                    <AccountCircleIcon />
                                                </IconButton>
                                            </InputAdornment>),
                                    }} />}
                        />
                    </Grid>
                    <Grid item>
                        <Controller name="birthday" control={control}
                            render={({ field }) =>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        {...field}
                                        label="Birthday"
                                        sx={{ width: "100%" }}
                                        format="DD/MM/YYYY"
                                        value={moment(birthdayState)}
                                        onChange={newValue => onChangeBirthday(newValue.toDate())}
                                    />
                                </LocalizationProvider>
                            } />
                    </Grid>
                    <Grid item>
                        <Controller name="notes" control={control}
                            render={({ field }) =>
                                <TextField {...field} label="Notes" fullWidth
                                    margin="normal" multiline rows={4} variant='outlined' style={{ height: 'none' }}
                                    error={Boolean(errors.notes)} helperText={errors.notes?.message} />} />
                    </Grid>
                    {errorState && <ErrorMessage appearance="small" >{errorState}</ErrorMessage>}
                </Grid>
                <Grid container direction="row" justifyContent="space-around" alignItems="center" mb={2}>
                    <MuiButton variant="outlined" onClickHandler={onCancelHandler}>Cancel</MuiButton>
                    <MuiButton variant="outlined" type="submit">
                        {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                    </MuiButton>
                </Grid>
            </form>
            {
                contact.appForms?.length > 0 &&
                <div style={{ padding: '20px' }}>
                    <Divider><Chip label="Contact's forms" /></Divider>
                    <Grid container direction={'column'} justifyContent="center" spacing={2} sx={{ padding: '20px' }}>
                        {
                            contact.appForms.map(form =>
                                <ContactFormCard key={form.id} form={form} />)
                        }
                    </Grid>
                </div>
            }
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </SwipeableDrawer>
    )
}