import { useEffect, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { BillingTabPageProps } from "./types";
import { IPricepackage, PricePackageType } from "../../../types/pricepackage";
import { Button, Container, Grid, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Spinner from "../../../components/Spinner/Spinner";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import { IntersectionObserverComponent } from "../../../components/IntersectionObserver/IntersectionObserverComponent";
import InvoiceCard from "./InvoiceCard";
import InvoiceSortFieldBar from "./InvoiceSortFieldBar";

export default function InvoiceTabPage({ onCreate }: BillingTabPageProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openNewInvoiceMenu = Boolean(anchorEl);
    const { invoiceSearchResult, sortField, loading, error } = useTypedSelector(state => state.invoice);
    const { pricepackageSearchResult } = useTypedSelector(state => state.pricepackage);
    const { getInvoices, getPricepackages, setInvoicePage, loadMoreInvoices } = useActions();

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (invoiceSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && invoiceSearchResult.currentPageNumber * invoiceSearchResult.pageSize <= invoiceSearchResult.totalItemCount) {
                loadMoreInvoices(invoiceSearchResult.pageSize, invoiceSearchResult.currentPageNumber + 1, sortField, invoiceSearchResult.order);
                setInvoicePage(invoiceSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (invoiceSearchResult.itemList.length === 0)
            getInvoices(invoiceSearchResult.pageSize, invoiceSearchResult.currentPageNumber, sortField, invoiceSearchResult.order);
        if (pricepackageSearchResult.itemList.length === 0) getPricepackages(pricepackageSearchResult.pageSize, pricepackageSearchResult.currentPageNumber, sortField, pricepackageSearchResult.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const onCloseMenu = () => setAnchorEl(null);

    const onCreateInvoice = (pricepackage: IPricepackage) => {
        onCreate(pricepackage);
        setAnchorEl(null);
    }

    return (
        <Container maxWidth="lg" className='layout-container'>
            {/* Page Header */}
            <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <InvoiceSortFieldBar
                        fields={["Invoice No", "Created At"]}
                    />
                </Grid>
                <Grid item>
                    <Button
                        id="create-invoice-button"
                        aria-controls={openNewInvoiceMenu ? 'create-invoice-button' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openNewInvoiceMenu ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={onOpenMenu}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ textTransform: 'none', borderRadius: "16px" }}
                    >
                        + Order
                    </Button>
                    <Menu
                        sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                        open={openNewInvoiceMenu} onClose={onCloseMenu}
                    >
                        {
                            pricepackageSearchResult.itemList.map(pricepackage =>
                                pricepackage.pricePackageType !== PricePackageType.Free &&
                                <MenuItem key={pricepackage.id} onClick={() => onCreateInvoice(pricepackage)}>
                                    <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>
                                        {PricePackageType[pricepackage.pricePackageType]}
                                    </Typography>
                                </MenuItem>)
                        }
                    </Menu>
                </Grid>
            </Grid>
            {/* Invoices List */}
            {
                invoiceSearchResult.itemList.length > 0 ?
                    <div style={{ margin: "30px 0" }}>
                        {invoiceSearchResult.itemList.map(invoice =>
                            <InvoiceCard key={invoice.id} invoice={invoice} />
                        )}
                    </div> :
                    loading ? <Spinner /> :
                        error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> :
                            <p style={{ padding: "0 15px" }}>No invoices</p>
            }
            <IntersectionObserverComponent onIntersection={handleIntersection} />
            {(invoiceSearchResult.itemList.length > 0 && loading) && <Spinner />}
        </Container>
    )
}
