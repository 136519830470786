import { Dispatch } from "redux";
import { getContactsAxios, getContactByIdAxios, getSearchContactsAxios } from "../../api/contact";
import { OrderType } from "../../types/common/orderType";
import { IContact, ContactAction, ContactActionTypes, ContactFilters } from "../../types/contact";

export const getContacts = (limit: number, page: number, filters: ContactFilters, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<ContactAction>) => {
        try {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: true });
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: null });
            dispatch({
                type: ContactActionTypes.GET_CONTACTS, payload:
                    await getContactsAxios(limit, page, filters.searchText === null ? "" : filters.searchText, sortField, order)
            });
        } catch (error) {
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: error.message || "Error of loading contacts." })
        } finally {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: false });
        }
    }
}

export const getContactById = (id: string) => {
    return async (dispatch: Dispatch<ContactAction>) => {
        try {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: true });
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: null });
            dispatch({ type: ContactActionTypes.GET_CONTACT_BY_ID, payload: await getContactByIdAxios(id) });
        } catch (error) {
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: error.message || "Error of loading choosed contact." })
        } finally {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: false });
        }
    }
}

export const loadMoreContacts = (limit: number, page: number, filters: ContactFilters, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<ContactAction>) => {
        try {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: true });
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: null });
            dispatch({ type: ContactActionTypes.LOAD_MORE_CONTACTS, payload: await getContactsAxios(limit, page, filters.searchText === null ? "" : filters.searchText, sortField, order) });
        } catch (error) {
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: error.message || "Error of loading contacts." })
        } finally {
            dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: false });
        }
    }
}

export const getSearchContacts = () => {
    return async (dispatch: Dispatch<ContactAction>) => {
        try {
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: null });
            dispatch({ type: ContactActionTypes.SET_SEARCH_CONTACTS, payload: await getSearchContactsAxios() });
        } catch (error) {
            dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: error.message || "Error of loading search contacts." })
        }
    }
}

export const setContactLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_LOADING, payload: isLoading });
}

export const setContactError = (message: string) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_ERROR, payload: message });
}

export const setContactPage = (page: number) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_PAGE, payload: page });
}

export const setContactSearchCriteria = (search: string) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_FILTER, payload: search });
}

export const setContactSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_SORTFIELD, payload: sortField });
}

export const setContactSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.SET_CONTACT_SORT, payload: sort });
}

export const createContact = (contact: IContact) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.CREATE_CONTACT, payload: contact });
}

export const updateContact = (contact: IContact) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.UPDATE_CONTACT, payload: contact });
}

export const removeContact = (contact: IContact) => {
    return async (dispatch: Dispatch<ContactAction>) =>
        dispatch({ type: ContactActionTypes.REMOVE_CONTACT, payload: contact.id });
}