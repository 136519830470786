import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { isAppFileValid } from "../../../../hooks/IsAppFileValid";
import { FormBuildPageProps } from "../types";
import { IAppForm, SentByOption } from "../../../../types/appForm";
import { ISnackBarMessageState } from "../../../../types/common/snackBarMessageState";
import { IOption, IQuestion, InputType } from "../../../../types/question";
import { updateFormAxios } from "../../../../api/form";
import { removeImageAxios, uploadImageAxios } from "../../../../api/image";
import { createOptionAxios, removeOptionAxios } from "../../../../api/option";
import { createQuestionAxios, removeQuestionAxios } from "../../../../api/question";
import { removeReminderAxios } from "../../../../api/reminder";
import { Alert, Box, Button, CircularProgress, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, Menu, MenuItem, Radio, RadioGroup, Select, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventIcon from '@mui/icons-material/Event';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import NumbersIcon from '@mui/icons-material/Numbers';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import MuiButton from "../../../../components/Button/MuiButton";
import FormQuestionCard from "./FormQuestionCard";
import QuestionForm from "./QuestionForm";

export default function FormBuildPage({ form, closeForm }: FormBuildPageProps): JSX.Element {
    const { auth } = useTypedSelector(state => state.auth);
    const { searchContacts } = useTypedSelector(state => state.contact);
    const { formSearchResult } = useTypedSelector(state => state.draftForm);
    const { createQuestion, updateDraftFormQuestion, updateDraftForm, removeQuestion, setDraftFormError } = useActions();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openNewQuestionMenu = Boolean(anchorEl);
    const [editorValue, setEditorValue] = useState(form.statement);
    const [question, setQuestion] = useState<IQuestion | null>(null);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const [questionsState, setQuestionsState] = useState<IQuestion[]>(form.questions);

    const [previewLogoVisible, setPreviewLogoVisible] = useState<boolean>(false);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoSource, setLogoSource] = useState<string | ArrayBuffer | null | Blob>(null);

    const [sentByOptionState, setSendByOptionState] = useState<SentByOption>(form.sentByOption);

    useEffect(() => {
        const updatedQuestionsList = formSearchResult.itemList.filter(f => f.id === form.id)[0].questions;
        setQuestionsState(updatedQuestionsList);
        if (form.logo) {
            setLogoSource((process.env.NODE_ENV === "production" ?
                process.env.REACT_APP_BASE_API_URL_PROD :
                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo)
            setPreviewLogoVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, formSearchResult.itemList);

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const validationSchema = Yup.object({
        title: Yup.string()
            .required("Required")
            .max(450, 'The Title Name may not be greater than 450 characters.'),
        statement: Yup.string()
            .required("Required")
    })

    const defaultValues: IAppForm = {
        id: form.id,
        title: form.title || '',
        statement: form.statement || '',
        logo: form.logo || '',
        status: form.status,
        isRead: form.isRead,
        skipSignature: form.skipSignature,
        sentByOption: form.sentByOption,
        contactId: form.contactId || '',
        contact: form.contact,
        reminder: form.reminder,
        userId: form.userId,
        questions: form.questions,
        createdAt: form.createdAt
    }

    const { control, handleSubmit, formState: { errors }, reset, resetField } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    })

    const onDeleteLogo = () => {
        setLogoFile(null);
        setLogoSource(null);
        setPreviewLogoVisible(false);
    }

    const onLogoSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        if (e.target.files) {
            const file = e.target.files[0];
            if (!isAppFileValid(file)) {
                setSnackBarState({ message: "File is not valid", severity: "warning" });
                return;
            }
            setLogoFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => { setLogoSource(reader.result) }
        }
        setPreviewLogoVisible(true);
    }

    const onSentByOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value === SentByOption.Email.toString() ?
            setSendByOptionState(SentByOption.Email) : setSendByOptionState(SentByOption.Link);
    };

    const onAddQuestionClick = async (type: InputType) => {
        let defaultQuestion: IQuestion = {
            appFormId: form.id,
            text: "New question",
            inputType: type,
            rank: questionsState.length === 0 ? 0 : [...questionsState].sort((a, b) => b.rank - a.rank)[0].rank + 1,// Max rank of existing questions + 1
            isRequired: true,
            showUpload: false
        }
        try {
            // setDraftFormLoading(true);
            setDraftFormError(null);
            let createdQuestion = await createQuestionAxios(defaultQuestion);
            let createdDefaultOption: IOption = null;
            switch (type) {
                case InputType.Checkbox: // Create at least 2 default options
                    const createdCheckboxOption1 = await createOptionAxios({ questionId: createdQuestion.id, name: "Option 1", value: "Option 1", checked: false, createdAt: new Date() })
                    createdQuestion.options.push(createdCheckboxOption1);
                    const createdCheckboxOption2 = await createOptionAxios({ questionId: createdQuestion.id, name: "Option 2", value: "Option 2", checked: false, createdAt: new Date(new Date().getTime() + 1000) })
                    createdQuestion.options.push(createdCheckboxOption2);
                    break;
                case InputType.Radio: // Create at least 2 default variants
                    const createdRadioOption1 = await createOptionAxios({ questionId: createdQuestion.id, name: "Variant 1", value: "Variant 1", checked: false, createdAt: new Date() })
                    createdQuestion.options.push(createdRadioOption1);
                    const createdRadioOption2 = await createOptionAxios({ questionId: createdQuestion.id, name: "Variant 2", value: "Variant 2", checked: false, createdAt: new Date(new Date().getTime() + 1000) })
                    createdQuestion.options.push(createdRadioOption2);
                    break;
                default:
                    let optionName = "";
                    switch (type) {
                        case InputType.Date:
                            optionName = "Date 1";
                            break;
                        case InputType.Time:
                            optionName = "Time 1";
                            break;
                        case InputType.Number:
                            optionName = "Number 1";
                            break;
                        default: // InputType.Text
                            optionName = "Textfield 1";
                            break;
                    }
                    createdDefaultOption = await createOptionAxios({
                        questionId: createdQuestion.id,
                        name: optionName,
                        value: "",
                        checked: false,
                        createdAt: new Date()
                    })
                    createdQuestion.options.push(createdDefaultOption);
                    break;
            }
            setQuestionsState([...questionsState, createdQuestion])
            createQuestion(createdQuestion);
            // then open QuestionForm to edit just created question
            setQuestion({ ...createdQuestion });// pass question as clone to avoid changing stored object
            setSnackBarState({ message: "New question created.", severity: "success" });
        } catch (error) {
            setDraftFormError(error.message || "Error while creating the new question.");
            setSnackBarState({ message: "Unable to create the question.", severity: "error" });
        } finally {
            // setDraftFormLoading(false);
        }
        setAnchorEl(null);
    }

    const onRemoveQuestion = async (question: IQuestion) => {
        try {
            await removeQuestionAxios(question.id);
            setQuestionsState(questionsState.filter(q => q.id !== question.id));
            removeQuestion(question);
            setSnackBarState({ message: "Question removed.", severity: "success" });
        } catch (error) {
            setSnackBarState({ message: "Unable to remove the question.", severity: "error" });
        }
    }

    const onUpdateQuestion = (question: IQuestion) =>
        setQuestionsState(questionsState.map(q => { return q.id === question.id ? question : q }));

    const onMoveUpQuestion = async (questionToMoveUp: IQuestion) => {
        if (questionsState.length < 2 || questionToMoveUp.rank === 0) return;
        // In array without question to move up - find the next closest element with MAX value of rank
        let questionToMoveDown = questionsState.filter(q => q.rank <= questionToMoveUp.rank && q.id !== questionToMoveUp.id).sort((a, b) => b.rank - a.rank)[0];
        // replace rank values between both questions
        updateDraftFormQuestion({ ...questionToMoveUp, rank: questionToMoveDown.rank });
        updateDraftFormQuestion({ ...questionToMoveDown, rank: questionToMoveUp.rank });
    }

    const onMoveDownQuestion = async (questionToMoveDown: IQuestion) => {
        if (questionsState.length < 2) return;
        // In array without question to move down - find the next closest element with MIN value of rank
        let questionToMoveUp = questionsState.filter(q => q.rank >= questionToMoveDown.rank && q.id !== questionToMoveDown.id).sort((a, b) => a.rank - b.rank)[0];
        // replace rank values between both questions
        updateDraftFormQuestion({ ...questionToMoveUp, rank: questionToMoveDown.rank });
        updateDraftFormQuestion({ ...questionToMoveDown, rank: questionToMoveUp.rank });
    }

    const onRemoveOptionHandler = (option: IOption) => {
        const questionToUpdate = questionsState.filter(question => question.id === option.questionId)[0];
        removeOptionAxios(option.id);
        updateDraftFormQuestion({ ...questionToUpdate, options: questionToUpdate.options.filter(o => o.id !== option.id) });
    }

    const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const onCloseMenu = () => setAnchorEl(null);

    const onSubmit = async (formToSave: IAppForm): Promise<void> => {
        formToSave.statement = editorValue;
        try {
            setLoadingState(true)
            if (formToSave.logo && logoSource === null) { //logo file was deleted during editing the form
                await removeImageAxios(formToSave.logo);
                formToSave.logo = null;
            }
            if (logoFile) {// logo file was updated so - remove old file, then upload new file:
                if (formToSave.logo) await removeImageAxios(formToSave.logo);
                const uploadedFile = await uploadImageAxios(logoFile);
                formToSave.logo = uploadedFile.fileName;
            }
            formToSave.sentByOption = sentByOptionState;
            if (formToSave.sentByOption.toString() === SentByOption.Link.toString()) {
                formToSave.contactId = null;
                formToSave.contact = null;
                if (formToSave.reminder) {
                    await removeReminderAxios(formToSave.reminder.id);
                    formToSave.reminder = null;
                }
            }
            formToSave.questions = questionsState;
            formToSave.contact = searchContacts.find(c => c.id === formToSave.contactId);
            if (formToSave.contactId === "") {
                formToSave.contactId = null;
                if (formToSave.reminder) {
                    await removeReminderAxios(formToSave.reminder.id);
                    formToSave.reminder = null;
                }
            }
            const formToUpdate = await updateFormAxios(formToSave);
            updateDraftForm(formToUpdate);
            reset();
            closeForm();
        } catch (error) {
            setSnackBarState({ message: "Unable to save the form.", severity: "error" });
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <Container maxWidth="lg" className='layout-container' sx={{ position: "relative" }} >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" }, position: "absolute", top: "0px", right: "0px" }}
                onClick={closeForm}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Editable fields */}
                <Grid container spacing={2} direction='column' justifyContent='center' alignItems='flex-start'
                    sx={{ marginBottom: "20px" }}>
                    <Grid item sx={{ width: "100%" }}>
                        <Controller name="title" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Title" type="text" margin="normal" fullWidth
                                    error={Boolean(errors.title)} helperText={errors.title?.message} />} />
                    </Grid>
                    {/* Logo */}
                    <div style={{ marginTop: "20px", paddingLeft: "16px", width: "100%" }}>
                        {
                            previewLogoVisible ?
                                <div className='preview-wrapper'>
                                    <img
                                        src={(logoSource && typeof (logoSource) != undefined) && logoSource.toString()}
                                        alt="Preview" className='preview-image'
                                        style={{ minWidth: '240px' }}
                                    />
                                    <div style={{ minWidth: '240px' }}>
                                        <Button variant="outlined" component="label" onClick={onDeleteLogo}
                                            style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                        >
                                            <DeleteOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                            <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Delete</span>
                                        </Button>
                                        <Button variant="outlined" component="label"
                                            style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                        >
                                            <EditOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                            <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Replace</span>
                                            <input type="file" hidden onChange={onLogoSelected} />
                                        </Button>
                                    </div>
                                </div>
                                :
                                <div className='uploader-wrapper'>
                                    <Button variant="contained" component="label"
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '10px 0' }}
                                    >
                                        <Tooltip title="Choose from gallery" placement="bottom">
                                            <ImageOutlinedIcon fontSize="large" sx={{ fill: '#5F7C78' }} />
                                        </Tooltip>
                                        <input type="file" hidden onChange={onLogoSelected} />
                                    </Button>
                                    <Button variant="outlined" component="label"
                                        style={{ textTransform: 'none', boxShadow: 'none', margin: '15px 0' }}
                                    >
                                        <ImageOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Add form picture</span>
                                        <input type="file" hidden onChange={onLogoSelected} />
                                    </Button>
                                </div>
                        }
                    </div>
                    <Grid item sx={{ width: "100%" }}>
                        <FormLabel component="legend">Statement:</FormLabel>
                        <Controller
                            name="statement"
                            control={control}
                            render={({ field }) =>
                                <ReactQuill
                                    {...field}
                                    theme="snow"
                                    value={editorValue}
                                    onChange={setEditorValue}
                                />
                            }
                        />
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ margin: "24px 0", width: "100%" }} component="fieldset" variant="standard"  >
                            <FormLabel component="legend">Form sharing options:</FormLabel>
                            <Controller rules={{ required: true }} control={control} name="sentByOption"
                                render={({ field }) => (
                                    <RadioGroup {...field}
                                        onChange={onSentByOptionChanged}
                                        value={sentByOptionState}
                                    >
                                        <FormControlLabel value={SentByOption.Email} control={<Radio />}
                                            label={
                                                <span className="text-14">
                                                    Email. Send a link to fill out the form to your contact in an email message.
                                                </span>
                                            }
                                        />
                                        <FormControlLabel value={SentByOption.Link} control={<Radio />}
                                            label={
                                                <span className="text-14">
                                                    Link. Impersonal form, commenting is not available.
                                                </span>
                                            }
                                        />
                                    </RadioGroup>
                                )}
                            />
                            <FormHelperText>Choose a convenient option.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: { xs: "150px", sm: "300px" } }}>
                            <InputLabel id="level-label">Contact</InputLabel>
                            <Controller name="contactId" defaultValue={defaultValues.contactId} control={control}
                                render={({ field }) => (
                                    <Select label="Contact" {...field}>
                                        {searchContacts.map((contact) =>
                                            <MenuItem key={contact.id} disabled={sentByOptionState !== SentByOption.Email} value={contact.id}>{contact.firstName + " " + contact.lastName}</MenuItem>)}
                                    </Select>
                                )}
                            />
                            <FormHelperText>
                                Choose one to attach a reminder to the form.
                            </FormHelperText>
                        </FormControl>
                        <CloseIcon sx={{ cursor: 'pointer', fill: '#5F7C78', margin: "14px 0 0 10px", '&:hover': { fill: "red" } }}
                            onClick={() => resetField("contactId", { defaultValue: "" })}
                        />
                    </Grid>
                    {/* <Grid item>
                        <Typography component={'span'}>Skip signature</Typography>
                        <Controller
                            name="skipSignature"
                            control={control}
                            render={({ field }) =>
                                <Switch
                                    {...field}
                                    checked={field.value}
                                />
                            } />
                        <FormHelperText>Turn ON: the "Sign" step will be skipped when filling out the form.</FormHelperText>
                    </Grid> */}
                </Grid>
                {/* Question section */}
                <Divider><Chip label="Questions" /></Divider>
                {
                    questionsState.length > 0 ?
                        questionsState.map((question) =>
                            <FormQuestionCard
                                key={question.id}
                                question={question}
                                onDeleteQuestion={question => onRemoveQuestion(question)}
                                onDeleteOption={onRemoveOptionHandler}
                                onEdit={() => setQuestion({ ...question })}
                                shomMoveUp={questionsState.length > 1 && question !== questionsState[0]}
                                showMoveDown={questionsState.length > 1 && question !== questionsState[questionsState.length - 1]}
                                onMoveUp={() => onMoveUpQuestion(question)}
                                onMoveDown={() => onMoveDownQuestion(question)} />)
                        :
                        <Typography variant="body2" component={'p'} sx={{ padding: '20px', fontWeight: 400 }}>
                            The form hasn't questions.
                        </Typography>
                }
                <Box sx={{ margin: "30px 0" }}>
                    <Button
                        id="create-question-button"
                        aria-controls={openNewQuestionMenu ? 'create-question-button' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openNewQuestionMenu ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        color="success"
                        onClick={onOpenMenu}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{ textTransform: 'none', borderRadius: "16px" }}
                    >
                        + New Question
                    </Button>
                    <Menu
                        sx={{ mt: '45px' }} id="menu-appbar" anchorEl={anchorEl}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                        keepMounted transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                        open={openNewQuestionMenu} onClose={onCloseMenu}
                    >
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Text)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <TextFieldsOutlinedIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Text field</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Checkbox)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <CheckBoxOutlinedIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Checkboxes</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Radio)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <RadioButtonUncheckedOutlinedIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Radio buttons</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Number)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <NumbersIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Number</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Date)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <EventIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Date</Typography>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={() => onAddQuestionClick(InputType.Time)}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
                                <AccessTimeIcon sx={{ color: "var(--darkGrey)" }} />
                                <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Time</Typography>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
                {/* Form footer */}
                <Grid container justifyContent="center" spacing={2} sx={{ padding: '20px' }} >
                    <Grid item sm={6} sx={{ textAlign: 'center' }}>
                        <MuiButton variant="outlined" onClickHandler={closeForm}>Cancel</MuiButton>
                    </Grid>
                    <Grid item sm={6} sx={{ textAlign: 'center' }}>
                        <MuiButton variant="outlined" type="submit">
                            {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                        </MuiButton>
                    </Grid>
                </Grid>
            </form >
            {question && <QuestionForm question={question} onSave={onUpdateQuestion} closeForm={() => setQuestion(null)} />}
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Container >
    )
}
