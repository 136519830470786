import { HomeTemplateCardProps } from './types';
import { Grid } from '@mui/material';
import MuiButton from '../../components/Button/MuiButton';

export default function HomeTemplateCard({ form, onPreview }: HomeTemplateCardProps) {
    return (
        <Grid item xs={12} md={4} sx={{ mb: "30px" }}>
            <p className='pt2' style={{ textAlign: 'center' }}>{form.title}</p>
            <p style={{ textAlign: 'center', margin: '20px 0' }}>
                <img
                    src={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                    alt=""
                    width='80%'
                    style={{ maxHeight: '344px' }}
                />
            </p>
            <p style={{ textAlign: 'center' }}>
                <span style={{ display: "inline-block", margin: "4px" }}>
                    <MuiButton size='small' variant='contained' onClickHandler={() => onPreview(form)}>Preview</MuiButton>
                </span>
            </p>
        </Grid>
    )
}
