import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { LayoutProps } from './types';
import { RouteNames } from '../../routing';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import '../../index.css';

const mdTheme = createTheme();

export default function FormFillLayout({ children }: LayoutProps): JSX.Element {

    return (
        <ThemeProvider theme={mdTheme}>
            <Helmet>
                <title>DoYourForm.com - Fill out the form</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments = DoYourForm.com" />
            </Helmet>
            <Box sx={{ display: 'flex', minHeight: '100%' }}>
                <Box component="main" id='main' sx={{ backgroundColor: 'white', flexGrow: 1, height: '100vh', overflow: 'auto' }}  >
                    <Grid id="container" sx={{ minHeight: 'calc(100vh - 20px)', margin: '0!important', padding: '0!important' }}>
                        {children}
                    </Grid>
                    <footer>
                        <Typography variant="body2" color="text.secondary" align="center">
                            {'Copyright © '}
                            <Link color="inherit" to={RouteNames.HOME}>
                                Do Your Form
                            </Link>{' '}
                            {new Date().getFullYear()}{'.'}
                        </Typography>
                    </footer>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
