import { useState } from 'react';
import { useActions } from '../../../../hooks/useActions';
import { clonedObject } from '../../../../hooks/clonedObject';
import { CompletedFormCardProps } from '../types';
import { SentByOption } from '../../../../types/appForm';
import { ISnackBarMessageState } from '../../../../types/common/snackBarMessageState';
import { getImagesSizeAxios, removeFormAxios, updateFormIsReadAxios } from '../../../../api/form';
import { Alert, Box, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import StyledDeleteIcon from '../../../../components/StyledIcons/StyledDeleteIcon';
import AppDeleteConfirmDialog from '../../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { updateAccountAxios } from '../../../../api/account';

export default function CompletedFormCard({ form, onPreview }: CompletedFormCardProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const { removeCompletedForm, updateCompletedFormIsRead, updateAuthUser } = useActions();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);

    const handleClose = () => setAnchorElUser(null);

    const сloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const markAsReadHandler = async () => {
        const formToUpdate = clonedObject(form);
        formToUpdate.isRead = !form.isRead;
        try {
            setLoading(true);
            setSnackBarState(null);
            await updateFormIsReadAxios(form.id, !form.isRead);
            updateCompletedFormIsRead(formToUpdate);
        } catch (error) {
            setSnackBarState({ message: "Unable to update isRead status.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onPreviewHandler = async () => {
        try {
            if (!form.isRead) {
                const formToUpdate = clonedObject(form);
                formToUpdate.isRead = !form.isRead;
                setLoading(true);
                setSnackBarState(null);
                await updateFormIsReadAxios(form.id, true);
                updateCompletedFormIsRead(formToUpdate);
            };
            onPreview(form);// to add comments
        } catch (error) {
            setSnackBarState({ message: "Unable to preview the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onDeleteCompletedForm = async () => {
        try {
            setLoading(true);
            setSnackBarState(null);
            const filesSizeToBeDeleted = await getImagesSizeAxios(form);
            await removeFormAxios(form.id);
            removeCompletedForm(form.id);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize -= filesSizeToBeDeleted;
            if (userToUpdate.account.currentFileSize < 0) userToUpdate.account.currentFileSize = 0;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
        } catch (error) {
            setSnackBarState({ message: "Unable to remove the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div onClick={onPreviewHandler} style={{ cursor: "pointer", flexBasis: "24px", flexShrink: 0 }}>
                {
                    form.logo ?
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            width="32px"
                            alt=''
                        /> :
                        <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                }
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"
                sx={{ flexGrow: 1, margin: "0 10px", cursor: "pointer" }}
                onClick={onPreviewHandler}
            >
                <span style={{ fontWeight: form.isRead ? "normal" : "bold" }}>{form.title}</span>
                <span className='text-14' style={{ fontWeight: form.isRead ? "normal" : "bold" }}>{form.contact ? form.contact.firstName + ' ' + form.contact.lastName : 'Not assigned'}</span>
                <span className='text-12' style={{ fontWeight: form.isRead ? "normal" : "bold" }}>
                    Sent by {SentByOption[form.sentByOption]}{form.sentByOption === SentByOption.Email && (" at " + (new Date(form.sentAt.toString() + "Z")).toLocaleString())}
                </span>
                <span className='text-12' style={{ fontWeight: form.isRead ? "normal" : "bold" }}>
                    Completed at {(new Date(form.completedAt.toString() + "Z")).toLocaleString()}
                </span>
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "105px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    {loading && <CircularProgress size="1rem" sx={{ m: '4px 10px 0 0' }} />}
                    <Tooltip title={form.isRead ? 'Mark as unread' : 'Mark as read'} placement="top">
                        <MarkChatReadOutlinedIcon
                            sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                            onClick={markAsReadHandler}
                        />
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    <Tooltip title={form.sentByOption === SentByOption.Email ? 'View & Comment' : 'View'} placement="top">
                        <PreviewOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} onClick={onPreviewHandler} />
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    <StyledDeleteIcon tooltipTitle="Remove" onDelete={() => setConfirmDialogOpen(true)} />
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={markAsReadHandler}>
                                <MarkChatReadOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>{form.isRead ? 'Mark as unread' : 'Mark as read'}</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={onPreviewHandler}>
                                <PreviewOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>{form.sentByOption === SentByOption.Email ? 'View & Comment' : 'View'}</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Remove</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {confirmDialogOpen && <AppDeleteConfirmDialog onCancel={() => setConfirmDialogOpen(false)} onDelete={onDeleteCompletedForm} />}
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={сloseSnackbar}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </div>
    )
}