import { Helmet } from "react-helmet";
import { Container, Grid } from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import BrandingForm from "./BrandingForm";

export default function BrandingPage(): JSX.Element {
    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Branding</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Branding" text="Check or update your branding settings." />
            <Grid container direction="column" alignItems="center">
                <BrandingForm />
            </Grid>
        </Container>
    )
}