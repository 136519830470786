import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { RouteNames } from "../../routing";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Badge from '@mui/material/Badge';
import Collapse from "@mui/material/Collapse";
import DashboardIcon from '@mui/icons-material/Dashboard';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import Groups2Icon from '@mui/icons-material/Groups2';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Tooltip } from "@mui/material";

export default function LeftMenuItems() {
    const [adminOpen, setAdminOpen] = useState<boolean>(true);
    const [formsOpen, setFormsOpen] = useState<boolean>(true);
    const { invoiceSearchResult } = useTypedSelector(state => state.invoice);
    const { formSearchResult } = useTypedSelector(state => state.completedForm);
    const { auth } = useTypedSelector(state => state.auth);

    return (
        <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {
                auth.roles.includes("Admin") &&
                <Fragment>
                    <Tooltip title="Admin" placement="right">
                        <ListItemButton onClick={() => setAdminOpen(!adminOpen)}>
                            <ListItemIcon>
                                <AdminPanelSettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Admin" className="dashboard-menu-link" />
                            {adminOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </Tooltip>
                    <Collapse in={adminOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Tooltip title="Invoices" placement="right">
                                <Link to={RouteNames.ADMIN_INVOICES} className='dashboard-menu-link'>
                                    <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon>
                                            <ShoppingCartIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Invoices" />
                                    </ListItemButton>
                                </Link>
                            </Tooltip>
                            <Tooltip title="Users" placement="right">
                                <Link to={RouteNames.ADMIN_USERS} className='dashboard-menu-link'>
                                    <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon>
                                            <PeopleAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Users" />
                                    </ListItemButton>
                                </Link>
                            </Tooltip>
                            <Tooltip title="Reminders" placement="right">
                                <Link to={RouteNames.ADMIN_REMINDERS} className='dashboard-menu-link'>
                                    <ListItemButton sx={{ pl: 3 }}>
                                        <ListItemIcon>
                                            <TimerOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Reminders" />
                                    </ListItemButton>
                                </Link>
                            </Tooltip>
                        </List>
                    </Collapse>
                </Fragment>
            }
            <Tooltip title="Dashboard" placement="right">
                <Link to={RouteNames.DASHBOARD} className='dashboard-menu-link'>
                    <ListItemButton>
                        <ListItemIcon>
                            <DashboardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                </Link>
            </Tooltip>
            <Tooltip title="Forms" placement="right">
                <ListItemButton onClick={() => setFormsOpen(!formsOpen)}>
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Forms" className="dashboard-menu-link" />
                    {formsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </Tooltip>
            <Collapse in={formsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <Tooltip title="Drafts" placement="right">
                        <Link to={RouteNames.FORMS_DRAFT} className='dashboard-menu-link'>
                            <ListItemButton sx={{ pl: 3 }}>
                                <ListItemIcon>
                                    <FolderOpenOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Drafts" />
                            </ListItemButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Sent" placement="right">
                        <Link to={RouteNames.FORMS_SENT} className='dashboard-menu-link'>
                            <ListItemButton sx={{ pl: 3 }}>
                                <ListItemIcon>
                                    <DriveFolderUploadOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sent" />
                            </ListItemButton>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Completed" placement="right">
                        <Link to={RouteNames.FORMS_DONE} className='dashboard-menu-link'>
                            <ListItemButton sx={{ pl: 3 }}>
                                <ListItemIcon>
                                    <Badge color="primary"
                                        badgeContent={
                                            formSearchResult.itemList.filter(i => i.isRead === false).length > 0 ?
                                                formSearchResult.itemList.filter(i => i.isRead === false).length : 0}
                                    >
                                        <FolderSharedOutlinedIcon />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary="Completed" />
                            </ListItemButton>
                        </Link>
                    </Tooltip>
                </List>
            </Collapse>
            <Tooltip title="Contacts" placement="right">
                <Link to={RouteNames.CONTACTS} className='dashboard-menu-link'>
                    <ListItemButton>
                        <ListItemIcon>
                            <Groups2Icon />
                        </ListItemIcon>
                        <ListItemText primary="Contacts" />
                    </ListItemButton>
                </Link>
            </Tooltip>
            <Tooltip title="Billing" placement="right">
                <Link to={RouteNames.INVOICES} className='dashboard-menu-link'>
                    <ListItemButton>
                        <ListItemIcon>
                            <Badge color="primary"
                                badgeContent={
                                    invoiceSearchResult.itemList.filter(i => i.isRead === false).length > 0 ?
                                        invoiceSearchResult.itemList.filter(i => i.isRead === false).length : 0}
                            >
                                <AccountBalanceIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Billing" />
                    </ListItemButton>
                </Link>
            </Tooltip>
        </List>
    );
}
