import { ShareLinkProps } from "../types";
import { TextField, Modal, Box, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MuiButton from "../../../../components/Button/MuiButton";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '80%',
    display: 'block',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem'
};

export default function ShareLink({ link, onClose }: ShareLinkProps) {

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Box sx={style}>
                <CloseIcon
                    sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" }, position: "absolute", top: "15px", right: "15px" }}
                    onClick={onClose}
                />
                <Grid container direction="column" justifyContent="center" spacing={2} sx={{ padding: '20px' }}>
                    <Grid item>
                        <TextField
                            fullWidth
                            multiline
                            rows={2}
                            label="Link To Share"
                            value={link}
                            onFocus={event => { event.target.select(); }}
                        />
                    </Grid>
                    <Grid item>
                        The link will be visible to all recipients.
                    </Grid>
                    <Grid item sx={{ textAlign: 'right' }}>
                        <MuiButton variant="outlined" onClickHandler={onClose}>
                            Close
                        </MuiButton>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
