import { IAppForm } from "./appForm";
import { OrderType } from "./common/orderType";
import { ISearchResult } from "./common/searchResult";

export interface IContact {
    id?: string;
    firstName: string;
    lastName: string;
    phone?: string;
    email: string;
    linkForMessage?: string;
    position?: string;
    company?: string;
    birthday?: Date;
    avatar?: string;
    notes?: string;
    appForms?: IAppForm[];
    userId: string;
    createdAt: Date;
}

export interface SearchContact {
    id: string;
    firstName: string;
    lastName: string;
    phone?: string;
    email?: string;
    position?: string;
    company?: string;
    avatar?: string;
    appFileId?: string;
    userId: string;
}

export interface ContactFilters {
    searchText: string;
}

export interface ContactState {
    contactSearchResult: ISearchResult<IContact>;
    searchContacts: SearchContact[];
    filters: ContactFilters;
    sortField: string;
    loading: boolean;
    error: null | string;
}

export enum ContactActionTypes {
    GET_CONTACTS = "GET_CONTACTS",
    GET_CONTACT_BY_ID = "GET_CONTACT_BY_ID",
    LOAD_MORE_CONTACTS = "LOAD_MORE_CONTACTS",
    SET_CONTACT_ERROR = "SET_CONTACT_ERROR",
    SET_CONTACT_LOADING = "SET_CONTACT_LOADING",
    SET_CONTACT_PAGE = "SET_CONTACT_PAGE",
    SET_SEARCH_CONTACTS = "SET_SEARCH_CONTACTS",
    SET_CONTACT_FILTER = "SET_CONTACT_FILTER",
    SET_CONTACT_SORTFIELD = "SET_CONTACT_SORTFIELD",
    SET_CONTACT_SORT = "SET_CONTACT_SORT",
    CREATE_CONTACT = "CREATE_CONTACT",
    UPDATE_CONTACT = "UPDATE_CONTACT",
    REMOVE_CONTACT = "REMOVE_CONTACT"
}

interface GetContactsAction {
    type: ContactActionTypes.GET_CONTACTS;
    payload: ISearchResult<IContact>;
}

interface GetContactByIdAction {
    type: ContactActionTypes.GET_CONTACT_BY_ID;
    payload: IContact;
}

interface LoadMoreContactsAction {
    type: ContactActionTypes.LOAD_MORE_CONTACTS;
    payload: ISearchResult<IContact>;
}

interface SetContactErrorAction {
    type: ContactActionTypes.SET_CONTACT_ERROR;
    payload: null | string;
}

interface SetContactLoadingAction {
    type: ContactActionTypes.SET_CONTACT_LOADING;
    payload: boolean;
}

interface SetContactPageAction {
    type: ContactActionTypes.SET_CONTACT_PAGE;
    payload: number;
}

interface SetSearchContacts {
    type: ContactActionTypes.SET_SEARCH_CONTACTS;
    payload: SearchContact[];
}

interface SetContactFilterAction {
    type: ContactActionTypes.SET_CONTACT_FILTER;
    payload: string;
}

interface SetContactSortField {
    type: ContactActionTypes.SET_CONTACT_SORTFIELD;
    payload: string;
}

interface SetContactSort {
    type: ContactActionTypes.SET_CONTACT_SORT;
    payload: OrderType;
}

interface CreateContactAction {
    type: ContactActionTypes.CREATE_CONTACT;
    payload: IContact;
}

interface UpdateContactAction {
    type: ContactActionTypes.UPDATE_CONTACT;
    payload: IContact;
}

interface RemoveContactAction {
    type: ContactActionTypes.REMOVE_CONTACT;
    payload: string;
}

export type ContactAction = GetContactsAction |
    GetContactByIdAction |
    LoadMoreContactsAction |
    SetContactErrorAction |
    SetContactLoadingAction |
    SetContactPageAction |
    SetSearchContacts |
    SetContactFilterAction |
    SetContactSortField |
    SetContactSort |
    CreateContactAction |
    UpdateContactAction |
    RemoveContactAction