import axios from 'axios';
import { OrderType } from '../types/common/orderType';
import { IReminder } from '../types/reminder';
import { ISearchResult } from '../types/common/searchResult';

/**
 * Creates a new Reminder
 * @param reminder<IReminder> object of type IReminder
 * @returns<IReminder> Created reminder
 */
export async function createReminderAxios(reminder: IReminder): Promise<IReminder> {
    return (await axios.post("/reminder/create", reminder)).data;
}

/**
 * Get list of Reminders
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param earlyThanNow<boolean>flag to filter reminders untill UTC now time
 * @param sortField<string> Field name for sorting, available fields: Start, Next, Finish or Period
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getRemindersAxios(
    limit: number,
    page: number,
    earlyThanNow: boolean,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IReminder>> {
    return (
        await axios.get(`/reminder/get?limit=${limit}&page=${page}&earlyThanNow=${earlyThanNow}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get's status of Reminders timer
 * @returns<boolean> Reminders timer status: running or not
 */
export async function getRemindersStatusAxios(): Promise<boolean> {
    return (await axios.get(`/backgroundreminder/getstatus`)).data;
}

/**
 * Sets the current Timer status: running or not.
 * @param running<boolean> value to set desired status
 * @returns<Promise>
 */
export async function setRemindersTimerAxios(running: boolean): Promise<void> {
    return await axios.get(`/backgroundreminder/settimer?running=${running}`);
}

/**
 * Updates the existing Reminder
 * @param reminder<IReminder> Object of type IReminder
 * @returns<IReminder> Updated Reminder object
 */
export async function updateReminderAxios(reminder: IReminder): Promise<IReminder> {
    return (await axios.put("/reminder/update", reminder)).data;
}

/**
 * Delete's the Reminder specified by identifier
 * @param id<string> Identifier
 */
export async function removeReminderAxios(id: string): Promise<void> {
    return await axios.delete(`/reminder/delete/${id}`);
}