import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from '../hooks/ProtectedRoute';
import { RouteNames } from '../routing';
import AdminInvoicesPage from '../pages/Admin/AdminInvoices/AdminInvoicesPage';
import AdminRemindersPage from '../pages/Admin/AdminReminders/AdminRemindersPage';
import BillingPage from '../pages/GoFormUserPages/Billing/BillingPage';
import BrandingPage from '../pages/Account/Branding/BrandingPage';
import ChangeEmailPage from '../pages/Account/ChangeEmail/ChangeEmailPage';
import ChangePasswordPage from '../pages/Account/ChangePassword/ChangePasswordPage';
import CompletedPage from '../pages/GoFormUserPages/AppForm/Completed/CompletedPage';
import ContactPage from '../pages/GoFormUserPages/Contact/ContactPage';
import DemoLayout from './Layouts/DemoLayout';
import Dashboard from '../pages/GoFormUserPages/Dashboard/Dashboard';
import DraftPage from '../pages/GoFormUserPages/AppForm/Drafts/DraftPage';
import EmailConfirmPage from '../pages/Account/EmailConfirm/EmailConfirmPage';
import ErrorPage from '../pages/Error/ErrorPage';
import ForgotPasswordPage from '../pages/Account/ForgotPassword/ForgotPasswordPage';
import FormFillPage from '../pages/FormFill/FormFillPage';
import FormFillLayout from './Layouts/FormFillLayout';
import HelpPage from '../pages/Help/Help';
import HomePage from '../pages/Home/HomePage';
import InvoicePreview from '../pages/GoFormUserPages/Billing/InvoicePreview';
import LoginPage from '../pages/Account/Login/LoginPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicy/PrivacyPolicyPage';
import ProfilePage from '../pages/Account/Profile/ProfilePage';
import PublicLayout from './Layouts/PublicLayout';
import RegisterCompletePage from '../pages/Account/RegisterComplete/RegisterCompletePage';
import RegisterPage from '../pages/Account/Register/RegisterPage';
import ResetPasswordPage from '../pages/Account/ResetPassword/ResetPasswordPage';
import SentPage from '../pages/GoFormUserPages/AppForm/Sent/SentPage';
import TermsConditionsPage from '../pages/TermsConditions/TermsConditionsPage';
import TestUI from '../pages/Test/TestUI';
import UsersPage from '../pages/Admin/AdminUser/AdminUsersPage';

export default function AppRouter() {
    const publicPages = [
        { route: RouteNames.EMAIL_CONFIRM, element: <PublicLayout><EmailConfirmPage /></PublicLayout> },
        { route: RouteNames.FORGOT_PASSWORD, element: <PublicLayout><ForgotPasswordPage /></PublicLayout> },
        { route: RouteNames.FORM_FILL, element: <FormFillLayout><FormFillPage /></FormFillLayout> },
        { route: RouteNames.HOME, element: <PublicLayout ><HomePage /></PublicLayout> },
        { route: RouteNames.HELP, element: <PublicLayout ><HelpPage /></PublicLayout> },
        { route: RouteNames.TEST_UI, element: <PublicLayout ><TestUI /></PublicLayout> },
        { route: RouteNames.LOGIN, element: <PublicLayout ><LoginPage /></PublicLayout> },
        { route: RouteNames.PRIVACY_POLICY, element: <PublicLayout ><PrivacyPolicyPage /></PublicLayout> },
        { route: RouteNames.REGISTER, element: <PublicLayout ><RegisterPage /></PublicLayout> },
        { route: RouteNames.REGISTER_COMPLETE, element: <PublicLayout ><RegisterCompletePage /></PublicLayout> },
        { route: RouteNames.RESET_PASSWORD, element: <PublicLayout ><ResetPasswordPage /></PublicLayout> },
        { route: RouteNames.TERMS_CONDITIONS, element: <PublicLayout ><TermsConditionsPage /></PublicLayout> },
        { route: "*", element: <PublicLayout ><ErrorPage /></PublicLayout> },
    ]
    const privatePages = [
        { route: RouteNames.ADMIN_INVOICES, element: <DemoLayout ><AdminInvoicesPage /></DemoLayout> },
        { route: RouteNames.ADMIN_REMINDERS, element: <DemoLayout ><AdminRemindersPage /></DemoLayout> },
        { route: RouteNames.ADMIN_USERS, element: <DemoLayout ><UsersPage /></DemoLayout> },
        { route: RouteNames.BRANDING, element: <DemoLayout ><BrandingPage /></DemoLayout> },
        { route: RouteNames.CHANGE_EMAIL, element: <DemoLayout ><ChangeEmailPage /></DemoLayout> },
        { route: RouteNames.CHANGE_PASSWORD, element: <DemoLayout ><ChangePasswordPage /></DemoLayout> },
        { route: RouteNames.CONTACTS, element: <DemoLayout ><ContactPage /></DemoLayout> },
        { route: RouteNames.DASHBOARD, element: <DemoLayout ><Dashboard /></DemoLayout> },
        { route: RouteNames.FORMS_DRAFT, element: <DemoLayout ><DraftPage /></DemoLayout> },
        { route: RouteNames.FORMS_SENT, element: <DemoLayout><SentPage /></DemoLayout> },
        { route: RouteNames.FORMS_DONE, element: <DemoLayout ><CompletedPage /></DemoLayout> },
        { route: RouteNames.INVOICES, element: <DemoLayout ><BillingPage /></DemoLayout> },
        { route: RouteNames.INVOICE, element: <DemoLayout ><InvoicePreview /></DemoLayout> },
        { route: RouteNames.PROFILE, element: <DemoLayout ><ProfilePage /></DemoLayout> }
    ]

    return (
        <Routes>
            {publicPages.map(page => <Route key={page.route} path={page.route} element={page.element} />)}
            {
                privatePages.map(page => {
                    return <Route key={page.route} path={page.route} element={
                        <ProtectedRoute>
                            {page.element}
                        </ProtectedRoute>
                    } />
                })
            }
        </Routes >
    )
}