import { ImagesSectionProps } from './types';
import { Grid, Tooltip } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function ImagesSection({ images }: ImagesSectionProps) {

    return (
        <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" mt={2}>
                {
                    images.map((image) =>
                        <Grid item key={image.id} sx={{ marginRight: "10px", borderRadius: "10px", padding: "5px", maxHeight: "60px", overflow: 'hidden' }}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                <a href={image.fullPath} target="_blank" rel="noreferrer">
                                    <img src={image.fullPath} alt="" width={64} />
                                </a>
                                {
                                    image.latitude &&
                                    <a href={`https://maps.google.com/?q=${image.latitude},${image.longitude}`}
                                        target="_blank" rel="noreferrer">
                                        <Tooltip title='Location' placement="top">
                                            <MyLocationIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        </Tooltip>
                                    </a>
                                }
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    )
}
