import * as AuthActionCreators from "./auth";
import * as CompletedFormActionCreators from "./completedForm";
import * as ContactActionCreators from "./contact";
import * as DraftFormActionCreators from "./draftForm";
import * as FormFillActionCreators from "./formFill";
import * as InvoiceActionCreators from "./invoice";
import * as NotificationActionCreators from "./notification";
import * as PricepackageActionCreators from "./pricepackage";
import * as ReminderActionCreators from "./reminder";
import * as SendFormActionCreators from "./sendForm";
import * as TemplateFormActionCreators from "./templateForm";
import * as UserActionCreators from "./user";

export const ActionCreators = {
    ...AuthActionCreators,
    ...CompletedFormActionCreators,
    ...ContactActionCreators,
    ...DraftFormActionCreators,
    ...FormFillActionCreators,
    ...InvoiceActionCreators,
    ...NotificationActionCreators,
    ...PricepackageActionCreators,
    ...ReminderActionCreators,
    ...SendFormActionCreators,
    ...TemplateFormActionCreators,
    ...UserActionCreators,
}