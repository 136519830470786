import { AccountStatus, IAccount } from "./account";
import { OrderType } from "./common/orderType";
import { ISearchResult } from "./common/searchResult";
import { PricePackageType } from "./pricepackage";

export interface IUser {
    id: string;
    account: IAccount;
    firstName: string;
    lastName: string;
    email: string;
    emailConfirmed: boolean;
    phoneNumber?: string;
    avatar?: string;
    logo?: string;
    brandColor1: string;
    brandColor2: string;
    createdAt: Date;
}

export interface UserFilters {
    searchText: string;
    pricePackageType: PricePackageType;
    accountStatus: AccountStatus;
}

export interface UserState {
    userSearchResult: ISearchResult<IUser>;
    filters: UserFilters;
    sortField: string;
    loading: boolean;
    error: null | string;
}

export enum UserActionTypes {
    GET_USERS = "GET_All_USERS",
    LOAD_MORE_USERS = "LOAD_MORE_USERS",
    SET_USER_PAGE = "SET_USER_PAGE",
    SET_USER_ERROR = "SET_USER_ERROR",
    SET_USER_LOADING = "SET_USER_LOADING",
    SET_USER_FILTERS = "SET_USER_FILTERS",
    SET_USER_SORTFIELD = "SET_USER_SORTFIELD",
    SET_USER_SORT = "SET_USER_SORT",
    UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT",
    UPDATE_USER_EMAILCONFIRMED_STATUS = "UPDATE_USER_EMAILCONFIRMED_STATUS",
    REMOVE_USER_ACCOUNT = "REMOVE_USER_ACCOUNT"
}

interface GetUsersAction {
    type: UserActionTypes.GET_USERS;
    payload: ISearchResult<IUser>;
}

interface LoadMoreUsersAction {
    type: UserActionTypes.LOAD_MORE_USERS;
    payload: ISearchResult<IUser>;
}

interface SetUserPagesAction {
    type: UserActionTypes.SET_USER_PAGE;
    payload: number;
}

interface SetUserErrorAction {
    type: UserActionTypes.SET_USER_ERROR;
    payload: null | string;
}

interface SetUserLoadingAction {
    type: UserActionTypes.SET_USER_LOADING;
    payload: boolean;
}

interface SetUserFiltersAction {
    type: UserActionTypes.SET_USER_FILTERS;
    payload: UserFilters;
}

interface SetUserSortFieldAction {
    type: UserActionTypes.SET_USER_SORTFIELD;
    payload: string;
}

interface SetUserSortAction {
    type: UserActionTypes.SET_USER_SORT;
    payload: OrderType;
}

interface UpdateUserAccountAction {
    type: UserActionTypes.UPDATE_USER_ACCOUNT;
    payload: IUser;
}

interface UpdateUserEmailConfirmedStatusAction {
    type: UserActionTypes.UPDATE_USER_EMAILCONFIRMED_STATUS;
    payload: IUser;
}

interface RemoveUserAccountAction {
    type: UserActionTypes.REMOVE_USER_ACCOUNT;
    payload: string;
}

export type UserAction = GetUsersAction |
    LoadMoreUsersAction |
    SetUserPagesAction |
    SetUserErrorAction |
    SetUserLoadingAction |
    SetUserFiltersAction |
    SetUserSortFieldAction |
    SetUserSortAction |
    UpdateUserAccountAction | UpdateUserEmailConfirmedStatusAction |
    RemoveUserAccountAction

