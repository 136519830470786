import { useActions } from '../../hooks/useActions';
import { clonedObject } from '../../hooks/clonedObject';
import { InputType } from '../../types/question';
import { PreviewCardProps } from './types';
import { Grid, TextField } from '@mui/material'
import ImagesSection from './ImagesSection';
import ImageWrapper from '../ImageWrapper/ImageWrapper';

export default function TextCard({ question, showComment, commentsDisabled }: PreviewCardProps) {
    const { updateCompletedFormQuestion } = useActions();

    const onChangeHandler = (comment: string) => {
        let questionToUpdate = clonedObject(question);
        questionToUpdate.comment = comment;
        updateCompletedFormQuestion(questionToUpdate);
    }

    return (
        <Grid item sx={{ width: "100%", margin: "24px 0" }}>
            {
                question.picture &&
                <ImageWrapper source={(process.env.NODE_ENV === "production" ?
                    process.env.REACT_APP_BASE_API_URL_PROD :
                    process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + question.picture} />
            }
            <p>{question.isRequired ? question.text + ' *' : question.text}</p>
            <TextField
                type={question.inputType}
                fullWidth
                margin="normal"
                multiline={question.inputType === InputType.Text ? true : false}
                rows={question.inputType === InputType.Text ? 2 : 1}
                variant='outlined' style={{ height: 'none' }}
                value={question.options[0].value}
            />
            {question.images.length > 0 && <ImagesSection images={question.images} />}
            {
                showComment &&
                <TextField
                    label="Comment on the answer to the question"
                    disabled={commentsDisabled}
                    fullWidth
                    margin="normal" multiline rows={2}
                    variant='outlined' style={{ height: 'none' }}
                    defaultValue={question.comment || ''}
                    onBlur={(event) => onChangeHandler(event.target.value)}
                />
            }
        </Grid>
    )
}
