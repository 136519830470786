import { OrderType } from "../../types/common/orderType";
import { AppFormStatus, FormAction, FormActionTypes, FormState } from "../../types/appForm";

const initialState: FormState = {
    formSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Ascending,
        pageCount: 0,
        pageSize: 6,
        searchCriteria: "",
        totalItemCount: 0
    },
    searchForms: [],
    filters: {
        searchText: "",
        status: AppFormStatus.Template,
        contactId: ""
    },
    sortField: "Created At",
    loading: true,
    error: null
}

export const templateFormReducer = (state: FormState = initialState, action: FormAction): FormState => {
    switch (action.type) {
        case FormActionTypes.GET_TEMPLATE_FORMS:
            return { ...state, formSearchResult: action.payload };
        case FormActionTypes.LOAD_MORE_TEMPLATE_FORMS:
            return {
                ...state,
                formSearchResult: {
                    ...action.payload,
                    itemList: state.formSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case FormActionTypes.SET_TEMPLATE_FORM_ERROR:
            return { ...state, error: action.payload };
        case FormActionTypes.SET_TEMPLATE_FORM_LOADING:
            return { ...state, loading: action.payload };
        case FormActionTypes.SET_TEMPLATE_FORM_PAGE:
            return { ...state, formSearchResult: { ...state.formSearchResult, currentPageNumber: action.payload } };
        case FormActionTypes.SET_SEARCH_TEMPLATE_FORMS:
            return { ...state, searchForms: action.payload };
        case FormActionTypes.SET_TEMPLATE_FORM_FILTER:
            return { ...state, filters: { ...state.filters, searchText: action.payload } };
        case FormActionTypes.SET_TEMPLATE_FORM_SORTFIELD:
            return { ...state, sortField: action.payload };
        case FormActionTypes.SET_TEMPLATE_FORM_SORT:
            return { ...state, formSearchResult: { ...state.formSearchResult, order: action.payload } };
        default: return state;
    }
}
