import ReactHtmlParser from 'react-html-parser';
import { AppFormStatus, SentByOption } from "../../types/appForm";
import { InputType } from "../../types/question";
import { TemplatePreviewProps } from "./types";
import { Box, Button, Checkbox, Chip, Divider, FormControlLabel, Grid, Modal, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import CheckboxCard from "../../components/PreviewForm/CheckboxCard";
import RadioCard from "../../components/PreviewForm/RadioCard";
import TextCard from "../../components/PreviewForm/TextCard";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '90%',
    display: 'block',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: "75%",
    boxShadow: 24,
    p: 4,
};

export default function TemplatePreview({ form, onClose }: TemplatePreviewProps) {

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Box sx={style}>
                <CloseIcon
                    sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "10px", right: "10px" }}
                    onClick={onClose}
                />
                <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                    <Divider><Chip label={"Here is your company logo"} /></Divider>
                    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                        <p className='pt1' style={{ textAlign: 'center' }}>{form.title}</p>
                        <p className='pt2' style={{ textAlign: 'center' }}>Form Template</p>
                        {
                            form.logo &&
                            <img src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                                alt="Form Logo"
                                width="100%"
                                style={{ marginBottom: "25px" }}
                            />
                        }
                    </Grid>
                    <Grid item sx={{ color: "var(--darkGrey)" }}>
                        {ReactHtmlParser(form.statement)}
                    </Grid>
                </Grid>
                <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                    {
                        form.questions.map(question => {
                            switch (question.inputType) {
                                case InputType.Checkbox:
                                    return <CheckboxCard
                                        key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                        commentsDisabled={true}
                                    />
                                case InputType.Radio:
                                    return <RadioCard
                                        key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                        commentsDisabled={true}
                                    />
                                default:
                                    return <TextCard
                                        key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                        commentsDisabled={true}
                                    />
                            }
                        })
                    }
                    {
                        form.sentByOption === SentByOption.Email &&
                        <Grid item sx={{ width: "100%" }}>
                            <TextField
                                disabled={form.status !== AppFormStatus.Done}
                                label="Comment on the form result"
                                fullWidth
                                margin="normal" multiline rows={3}
                                variant='outlined' style={{ height: 'none' }}
                                defaultValue={form.comment || ''}
                            />
                        </Grid>
                    }
                    {
                        (form.sentByOption === SentByOption.Email && form.status === AppFormStatus.Done) &&
                        <Grid item sx={{ width: "100%" }}>
                            <FormControlLabel
                                label={`Send email to ${form.contact.firstName} ${form.contact.lastName} about adding my comments`}
                                control={
                                    <Checkbox
                                        checked={true}
                                        disabled
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                }
                            />
                        </Grid>
                    }
                </Grid>
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                    <Button variant="outlined" onClick={onClose} sx={{ textTransform: 'none', borderRadius: "16px" }} >
                        Close
                    </Button>
                </div>
            </Box >
        </Modal >
    )
}
