import { useState } from 'react';
import { clonedObject } from '../../../hooks/clonedObject';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ContactCardProps } from './types';
import { ISnackBarMessageState } from '../../../types/common/snackBarMessageState';
import { deleteContactAxios } from '../../../api/contact';
import { Alert, Avatar, Box, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AppDeleteConfirmDialog from '../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog';
import StyledEditIcon from '../../../components/StyledIcons/StyledEditIcon';
import StyledDeleteIcon from '../../../components/StyledIcons/StyledDeleteIcon';

export default function ContactCard({ contact, onEdit }: ContactCardProps) {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const { removeContact, updateAuthUser } = useActions();
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const { auth } = useTypedSelector(state => state.auth);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);

    const handleClose = () => setAnchorElUser(null);

    const сloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const onDeleteContact = async () => {
        try {
            setLoadingState(true);
            setSnackBarState(null);
            await deleteContactAxios(contact.id);
            removeContact(contact);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentContacts -= 1;
            updateAuthUser(userToUpdate);
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to delete the contact.", severity: "error" });
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                {
                    contact.avatar ?
                        <Avatar
                            alt="Avatar"
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + contact.avatar}
                        />
                        :
                        <Avatar sx={{ bgcolor: auth.user.brandColor1, color: auth.user.brandColor2 }}>{contact.firstName.charAt(0) + contact.lastName.charAt(0)}</Avatar>
                }
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, margin: "0 10px" }}>
                <span>{contact.firstName + " " + contact.lastName}</span>
                {contact.phone && <span className='text-14'>{contact.phone}</span>}
                {contact.position && <span className='text-14'>{contact.position}</span>}
                {contact.company && <span className='text-14'>At {contact.company}</span>}
                {
                    contact.birthday &&
                    <span className='text-12'>
                        Birthday: {(new Date(contact.birthday.toString())).toLocaleDateString()}
                    </span>
                }
                <span className='text-12'>Forms: {contact.appForms?.length}</span>
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "140px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    {loadingState && <CircularProgress size="1rem" sx={{ m: '4px 10px 0 0' }} />}
                    <Tooltip title="Send email" placement="top">
                        <a href={`mailto:${contact.email}`} >
                            <MailOutlineIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                        </a>
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    {
                        contact.phone &&
                        <div style={{ display: "flex" }}>
                            <div style={{ margin: '0 5px' }}>
                                <a href={`tel:${contact.phone}`} >
                                    <Tooltip title="Call" placement="top">
                                        <CallIcon sx={{ cursor: 'pointer', fill: '#5F7C78' }} />
                                    </Tooltip>
                                </a>
                            </div>
                            <Divider orientation="vertical" flexItem />
                        </div>
                    }
                    <StyledEditIcon tooltipTitle="Edit" onEdit={() => onEdit(contact)} />
                    <Divider orientation="vertical" flexItem />
                    <StyledDeleteIcon tooltipTitle="Remove" onDelete={() => setConfirmDialogOpen(true)} />
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        {
                            contact.email &&
                            <MenuItem onClick={handleClose}>
                                <a href={`mailto:${contact.email}`} style={{ textDecoration: 'none', color: 'rgb(32,33,36)' }} >
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => console.log("View")}>
                                        <MailOutlineIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        <span>Send email</span>
                                    </Grid>
                                </a>
                            </MenuItem>
                        }
                        {
                            contact.phone &&
                            <MenuItem onClick={handleClose}>
                                <a href={`tel:${contact.phone}`} style={{ textDecoration: 'none', color: 'rgb(32,33,36)' }} >
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => console.log("View")}>
                                        <CallIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        <span>Call</span>
                                    </Grid>
                                </a>
                            </MenuItem>
                        }
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => onEdit(contact)}>
                                <EditIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Edit</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Remove</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {
                confirmDialogOpen &&
                <AppDeleteConfirmDialog
                    message='This contact will be deleted permanently with all related forms. Please confirm.'
                    onCancel={() => setConfirmDialogOpen(false)}
                    onDelete={onDeleteContact}
                />
            }
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={сloseSnackbar}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </div>
    )
}