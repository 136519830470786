import { FormAction, FormActionTypes, FormFillState } from "../../types/appForm";
import { IImage } from "../../types/image";
import { IOption, IQuestion } from "../../types/question";

const initialState: FormFillState = {
    form: null,
    user: null,
    step: 0,
    loading: true,
    error: null,
    showResults: false,
    latitude: null,
    longitude: null
}

export const formFillReducer = (state: FormFillState = initialState, action: FormAction): FormFillState => {
    switch (action.type) {
        case FormActionTypes.GET_FORMFILL:
            return { ...state, form: action.payload.form, user: action.payload.user };
        case FormActionTypes.CREATE_FORMFILL_IMAGES: // todo
            console.log('CREATE_FORMFILL_IMAGES payload: ', action.payload)
            return { ...state, form: { ...state.form, questions: createImages(state, action.payload) } };
        case FormActionTypes.REMOVE_FORMFILL_IMAGE: // todo
            return { ...state, form: { ...state.form, questions: removeImage(state, action.payload) } };
        case FormActionTypes.SET_FORMFILL_ERROR:
            return { ...state, error: action.payload };
        case FormActionTypes.SET_FORMFILL_LOADING:
            return { ...state, loading: action.payload };
        case FormActionTypes.SET_SHOW_RESULTS:
            return { ...state, showResults: action.payload };
        case FormActionTypes.SET_FORMFILL_STEP:
            return { ...state, step: action.payload };
        case FormActionTypes.UPDATE_FORMFILL:
            return { ...state, form: action.payload }
        case FormActionTypes.UPDATE_FORMFILL_QUESTION:
            return { ...state, form: { ...state.form, questions: updateQuestion(state, action.payload) } }
        case FormActionTypes.UPDATE_FORMFILL_OPTION:
            return { ...state, form: { ...state.form, questions: updateOption(state, action.payload) } };
        case FormActionTypes.SET_GEOPOSITION:
            return { ...state, latitude: action.payload.latitude, longitude: action.payload.longitude }
        default: return state;
    }
}

function updateQuestion(state: FormFillState, questionToUpdate: IQuestion): Array<IQuestion> {
    return state.form.questions.map((question: IQuestion) => {
        if (question.id === questionToUpdate.id) return questionToUpdate;
        return question;
    })
}

function updateOption(state: FormFillState, optionToUpdate: IOption): Array<IQuestion> {
    let questionToUpdate = { ...state.form.questions.filter(question => question.id === optionToUpdate.questionId)[0] };
    questionToUpdate.options = questionToUpdate.options.map(option => {
        if (option.id === optionToUpdate.id) return optionToUpdate;
        return option;
    });

    return (updateQuestion(state, questionToUpdate));
}

// remark: all images belong to the same question!
function createImages(state: FormFillState, images: IImage[]): Array<IQuestion> {
    let questionToUpdate = { ...state.form.questions.filter(question => question.id === images[0].questionId)[0] };
    questionToUpdate.images = questionToUpdate.images.concat(images);

    return (updateQuestion(state, questionToUpdate));
}

function removeImage(state: FormFillState, imageToRemove: IImage): Array<IQuestion> {
    let questionToUpdate = { ...state.form.questions.filter(question => question.id === imageToRemove.questionId)[0] };
    questionToUpdate.images = questionToUpdate.images.filter(image => image.id !== imageToRemove.id);

    return (updateQuestion(state, questionToUpdate));
}
