import { OrderType } from "../../types/common/orderType";
import { InvoiceAction, InvoiceActionTypes, InvoiceState, IInvoice } from "../../types/invoice";

const initialState: InvoiceState = {
    invoiceSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Descending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    sortField: "Created At",
    loading: true,
    error: null
}

export const invoiceReducer = (state: InvoiceState = initialState, action: InvoiceAction): InvoiceState => {
    switch (action.type) {
        case InvoiceActionTypes.GET_INVOICES:
            return { ...state, invoiceSearchResult: action.payload };
        case InvoiceActionTypes.LOAD_MORE_INVOICES:
            return {
                ...state,
                invoiceSearchResult: {
                    ...action.payload,
                    itemList: state.invoiceSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case InvoiceActionTypes.SET_INVOICE_ERROR:
            return { ...state, error: action.payload };
        case InvoiceActionTypes.SET_INVOICE_LOADING:
            return { ...state, loading: action.payload };
        case InvoiceActionTypes.SET_INVOICE_PAGE:
            return { ...state, invoiceSearchResult: { ...state.invoiceSearchResult, currentPageNumber: action.payload } };
        case InvoiceActionTypes.SET_INVOICE_SORTFIELD:
            return { ...state, sortField: action.payload };
        case InvoiceActionTypes.SET_INVOICE_SORT:
            return { ...state, invoiceSearchResult: { ...state.invoiceSearchResult, order: action.payload } };
        case InvoiceActionTypes.CREATE_INVOICE:
            return { ...state, invoiceSearchResult: { ...state.invoiceSearchResult, itemList: [action.payload, ...state.invoiceSearchResult.itemList] } }
        case InvoiceActionTypes.UPDATE_INVOICE:
            return { ...state, invoiceSearchResult: { ...state.invoiceSearchResult, itemList: updateInvoice(state, action.payload) } }
        case InvoiceActionTypes.REMOVE_INVOICE:
            return { ...state, invoiceSearchResult: { ...state.invoiceSearchResult, itemList: deleteInvoice(state, action) } };
        default: return state;
    }
}

function updateInvoice(state: InvoiceState, invoiceToUpdate: IInvoice): Array<IInvoice> {
    return state.invoiceSearchResult.itemList.map((invoice: IInvoice) => {
        if (invoice.id === invoiceToUpdate.id) return invoiceToUpdate;
        return invoice;
    })
}

function deleteInvoice(state: InvoiceState, action: InvoiceAction): Array<IInvoice> {
    return state.invoiceSearchResult.itemList.filter(invoice => invoice.id !== action.payload)
}
