import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { ContactFormCardProps } from './types'
import { AppFormStatus, IAppForm } from "../../../types/appForm";
import { Box, Grid, Tooltip } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';

export default function ContactFormCard({ form }: ContactFormCardProps) {
    const navigate = useNavigate();

    const onPreviewHandler = (form: IAppForm) => {
        switch (form.status) {
            case AppFormStatus.Sent:
                navigate(`/forms/f/${form.id}`);
                break;
            case AppFormStatus.Done:
                navigate(`/forms/f/${form.id}`);
                break;
            default: //AppFormStatus.Draft
                navigate(`/forms/drafts`);
                break;
        }
    }

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" gap='10px' sx={{ minHeight: '56px', margin: '5px 0' }}>
            <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" >
                    {
                        form.logo ?
                            <img
                                src={(process.env.NODE_ENV === "production" ?
                                    process.env.REACT_APP_BASE_API_URL_PROD :
                                    process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                                width="38px"
                                alt="Form"
                            /> :
                            <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                    }
                    <Grid item sx={{ cursor: 'pointer', marginLeft: "8px" }}>
                        <p className="text-14" style={{ margin: "0" }}>
                            {form.title.length > 16 ? form.title.substring(0, 16) + "..." : form.title}
                        </p>
                        <p className="text-12" style={{ margin: "0" }}>
                            Created at: {moment(form.createdAt).format('DD.MM.YYYY HH:mm')}
                        </p>
                        {
                            form.status === AppFormStatus.Draft &&
                            <p className="text-12" style={{ margin: "0" }}>Draft</p>
                        }
                        {
                            form.status === AppFormStatus.Sent &&
                            <p className="text-12" style={{ margin: "0" }}>
                                Sent at {form.sentAt.toString().includes("Z") ? (new Date(form.sentAt.toString())).toLocaleString() : (new Date(form.sentAt.toString() + "Z")).toLocaleString()}
                            </p>
                        }
                        {
                            form.status === AppFormStatus.Done &&
                            <div>
                                <p className="text-12" style={{ margin: "0" }}>
                                    Sent at {form.sentAt.toString().includes("Z") ? (new Date(form.sentAt.toString())).toLocaleString() : (new Date(form.sentAt.toString() + "Z")).toLocaleString()}
                                </p>
                                <p className="text-12" style={{ margin: "0" }}>
                                    Completed at {(new Date(form.completedAt.toString() + "Z")).toLocaleString()}
                                </p>
                            </div>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Box className="table-actions" sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <Tooltip title={form.status === AppFormStatus.Draft ? "Go to Drafts" : "Show Form"}>
                        <PreviewOutlinedIcon
                            sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                            onClick={() => onPreviewHandler(form)}
                        />
                    </Tooltip>
                </Box>
            </Grid>
        </Grid>
    )
}
