import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutocompleteContactSelect() {
    const { contactSearchResult, sortField, searchContacts } = useTypedSelector(state => state.contact);
    const { getContacts, setContactPage, setContactSearchCriteria } = useActions();

    const searchTitleChanged = (event, values) => {
        setContactPage(1);
        setContactSearchCriteria(values);
        getContacts(contactSearchResult.pageSize, 1, { searchText: values }, sortField, contactSearchResult.order);
    }

    return (
        <Autocomplete
            freeSolo
            disableClearable={false}
            options={searchContacts.map((option) => option.firstName + " " + option.lastName)}
            onChange={searchTitleChanged}
            renderInput={(params) => (
                <TextField
                    sx={{ minWidth: 200 }}
                    fullWidth
                    {...params}
                    label="Choose a contact"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                />
            )}
        />
    );
}

