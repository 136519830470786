import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, TextField } from "@mui/material";
import { FillFormQuestionCardProps } from "../types";
import { clonedObject } from "../../../hooks/clonedObject";
import ImageWrapper from "../../../components/ImageWrapper/ImageWrapper";
import UploadBlock from "./UploadBlock";

export default function CheckboxGroupQuestionCard({ question }: FillFormQuestionCardProps) {
    const { showResults } = useTypedSelector(state => state.formFill)
    const { updateFormFillOption } = useActions();

    const isOptionValueValid = (): boolean => {
        if (question.isRequired) return question.options.filter((v) => v.checked === true).length > 0;
        return true;
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let optionToUpdate = clonedObject(question.options.filter(option => option.id === event.target.id)[0]);
        optionToUpdate.checked = event.target.checked;
        updateFormFillOption(optionToUpdate);
    }

    return (
        <Grid container direction="column" gap={1} justifyContent="center" alignItems="flex-start" >
            <FormControl
                required={question.isRequired}
                error={!isOptionValueValid()} // source: https://mui.com/material-ui/react-checkbox/#formgroup
                component="fieldset"
                sx={{ margin: "24px 0", width: "100%" }}
                variant="standard"
            >
                {
                    question.picture &&
                    <ImageWrapper source={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + question.picture} />
                }
                <FormLabel component="p">{question.text}</FormLabel>
                <FormGroup>
                    {
                        question.options.map(option =>
                            <FormControlLabel
                                key={option.id}
                                control={
                                    <Checkbox
                                        id={option.id}
                                        disabled={showResults}
                                        checked={option.checked}
                                        onChange={onChange}
                                        name={option.name}
                                    />
                                }
                                label={option.value}
                            />
                        )
                    }
                </FormGroup>
                <FormHelperText>{!isOptionValueValid() && "Choose an option"}</FormHelperText>
            </FormControl>
            {question.showUpload && <UploadBlock question={question} isDisabled={showResults} />}
            {
                showResults &&
                <TextField
                    label="Sender's comment"
                    fullWidth
                    disabled
                    margin="normal" multiline rows={2}
                    variant='outlined' style={{ height: 'none' }}
                    value={question.comment || ''}
                />
            }
        </Grid>
    )
}
