import { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { IUser } from '../../../types/user';
import { getRemindersStatusAxios, setRemindersTimerAxios } from '../../../api/reminder';
import { Checkbox, Container, FormControlLabel, Grid, Switch } from '@mui/material';
import Spinner from '../../../components/Spinner/Spinner';
import ErrorMessage from '../../../components/Messages/ErrorMessage';
import { IntersectionObserverComponent } from '../../../components/IntersectionObserver/IntersectionObserverComponent';
import ManageUser from '../AdminUser/ManageUser';
import AdminReminderCard from './AdminReminderCard';
import AdminReminderSortFieldBar from './AdminReminderSortFieldBar';

export default function AdminUsersPage(): JSX.Element {
    const { reminderSearchResult, filters, loading, error, sortField } = useTypedSelector(state => state.reminder);
    const { getReminders, loadMoreReminders, setRemindersFilter, setReminderPage } = useActions();
    const [user, setUser] = useState<IUser | null>(null);
    // const [previewReminder, setPreviewReminder] = useState<IReminder | null>(null);
    const [isRunning, setIsRunning] = useState(false);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (reminderSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && reminderSearchResult.currentPageNumber * reminderSearchResult.pageSize <= reminderSearchResult.totalItemCount) {
                loadMoreReminders(reminderSearchResult.pageSize, reminderSearchResult.currentPageNumber + 1, filters, sortField, reminderSearchResult.order);
                setReminderPage(reminderSearchResult.currentPageNumber + 1);
            }
        });
    };

    const initializeReminderStatus = async () => {
        const actualReminderStatus = await getRemindersStatusAxios();
        setIsRunning(actualReminderStatus);
    }

    useEffect(() => {
        initializeReminderStatus();
    }, [])

    useEffect(() => {
        getReminders(reminderSearchResult.pageSize, reminderSearchResult.currentPageNumber, filters, sortField, reminderSearchResult.order)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, reminderSearchResult.currentPageNumber, sortField, reminderSearchResult.order]);

    const onEarlyThanNowChanged = (checked: boolean): void => {
        setReminderPage(1);
        setRemindersFilter(checked);
    }

    const onIsRunningChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsRunning(event.target.checked);
        await setRemindersTimerAxios(event.target.checked);
    };

    return (
        <>
            <Helmet>
                <title>DoYourForm.com - Reminders</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            {
                user ? <ManageUser user={user} onClose={() => setUser(null)} /> :
                    <Container maxWidth="lg" className='layout-container'>
                        {/* Page Header */}
                        <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="isRunning"
                                            color="success"
                                            checked={isRunning}
                                            onChange={onIsRunningChanged}
                                        />}
                                    label="Running" />

                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="check-filter"
                                            onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) => onEarlyThanNowChanged(checked)}
                                            checked={filters.earlyThanNow}
                                        />}
                                    label="Early than now" />
                            </Grid>
                            <Grid item>
                                <AdminReminderSortFieldBar fields={["Start", "Next", "Finish", "Period"]} />
                            </Grid>
                        </Grid>
                        {/* Reminders List */}
                        {
                            reminderSearchResult.itemList.length > 0 ?
                                <div style={{ margin: "30px 0" }}>
                                    {reminderSearchResult.itemList.map(reminder =>
                                        <AdminReminderCard
                                            key={reminder.id}
                                            reminder={reminder}
                                            onPreview={reminder => alert(`reminder: ${JSON.stringify(reminder)}`)}
                                        />
                                    )}
                                </div> :
                                loading ? <Spinner /> :
                                    error ? <ErrorMessage appearance="large">{error}</ErrorMessage> :
                                        <p style={{ padding: "0 15px" }}>No reminders</p>
                        }
                        <IntersectionObserverComponent onIntersection={handleIntersection} />
                        {(reminderSearchResult.itemList.length > 0 && loading) && <Spinner />}
                    </Container>
            }
        </>
    );
};
