import { Dispatch } from "redux";
import { getFormsAxios, getSearchFormsAxios } from "../../api/form";
import { IAppForm, FormAction, FormActionTypes, AppFormStatus } from "../../types/appForm";
import { OrderType } from "../../types/common/orderType";
import { IQuestion } from "../../types/question";
import { IReminder } from "../../types/reminder";

export const getDraftForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_FILTER_STATUS, payload: AppFormStatus.Draft })
            dispatch({
                type: FormActionTypes.GET_DRAFT_FORMS, payload:
                    await getFormsAxios(limit, page,
                        search || "", contactId || "",
                        AppFormStatus.Draft,
                        sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: false });
        }
    }
}

export const loadMoreDraftForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: null });
            dispatch({
                type: FormActionTypes.LOAD_MORE_DRAFT_FORMS, payload: await getFormsAxios(limit, page,
                    search || "", contactId || "",
                    AppFormStatus.Draft, sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: false });
        }
    }
}

export const getSearchDraftForms = (status: AppFormStatus) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_SEARCH_DRAFT_FORMS, payload: await getSearchFormsAxios(status) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: error.message || "Error of loading search forms." })
        }
    }
}

export const setDraftFormError = (message: string) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_DRAFT_FORM_ERROR, payload: message });
}

export const setDraftFormLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: isLoading });
}

export const setDraftFormPage = (page: number) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_DRAFT_FORM_PAGE, payload: page });
}

export const setDraftFormSearchCriteria = (search: string) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_DRAFT_FORM_FILTER, payload: search });
}

export const setDraftFormSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_DRAFT_FORM_SORTFIELD, payload: sortField });
}

export const setDraftFormSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_DRAFT_FORM_SORT, payload: sort });
}

export const createDraftForm = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.CREATE_DRAFT_FORM, payload: form });
}

export const updateDraftForm = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.UPDATE_DRAFT_FORM, payload: form });
}

export const removeDraftForm = (id: string) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.REMOVE_DRAFT_FORM, payload: id });
}

export const createQuestion = (question: IQuestion) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.CREATE_QUESTION, payload: question });
}

export const createReminder = (reminder: IReminder) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.UPDATE_REMINDER, payload: reminder });
}

export const updateDraftFormQuestion = (question: IQuestion) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.UPDATE_QUESTION, payload: question });
}

export const updateDraftFormReminder = (reminder: IReminder) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.UPDATE_REMINDER, payload: reminder });
}

export const removeQuestion = (question: IQuestion) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.REMOVE_QUESTION, payload: question });
}

export const removeReminder = (reminder: IReminder) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.REMOVE_REMINDER, payload: reminder });
}