import { Avatar, Grid } from '@mui/material'
import StepsHeader from './components/StepsHeader';
import Spinner from '../../components/Spinner/Spinner';

export default function Step0Loading() {

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor="#158f7d" isPhoneDialogActive={false} />
            </Grid>
            <Grid item sx={{ margin: "45px 0 5px" }}>
                <Avatar
                    alt="Budda"
                    src={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/Assets/budda-avatar.png"}
                    sx={{ width: 250, height: 250 }}
                />
            </Grid>
            <Grid item sx={{ width: '90%', textAlign: 'center' }}>
                <img
                    src={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/Assets/onload-replacer1.gif"}
                    alt="Replacer"
                    style={{ margin: '25px 0 12px', maxWidth: "80%", borderRadius: "10px" }}
                />
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
                <Spinner />
            </Grid>
        </Grid>
    )
}
