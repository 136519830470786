import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { clonedObject } from '../../hooks/clonedObject';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { AppFormStatus, SentByOption } from '../../types/appForm';
import { INotification } from '../../types/notification';
import { ISnackBarMessageState } from '../../types/common/snackBarMessageState';
import { InputType } from '../../types/question';
import { Alert, Button, Container, Grid, Snackbar } from '@mui/material'
import CheckboxGroupQuestionCard from './components/CheckboxGroupQuestionCard';
import { CheckIsFormFilled } from './components/CheckIsFormFilled';
import RadioGroupQuestionCard from './components/RadioGroupQuestionCard';
import StepsHeader from './components/StepsHeader';
import TextQuestionCard from './components/TextQuestionCard';

export default function Step2FillAnswers() {
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const { form, step, user } = useTypedSelector(state => state.formFill)
    const { createNotification, setFormFillStep, updateFormFill } = useActions();
    const [isFormFilled, setIsFormFilled] = useState<boolean>(false);

    useEffect(() => {
        setIsFormFilled(CheckIsFormFilled(form.questions));
    }, [form.questions])

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const onSave = () => {
        const formToUpdate = clonedObject(form);
        formToUpdate.status = AppFormStatus.Done;
        formToUpdate.completedAt = new Date();
        updateFormFill(formToUpdate);
        let notification: INotification = {
            senderAvatarUrl: "",
            senderFullName: "",
            message: "",
            isRead: false,
            callBackUrl: "",
            createdAt: new Date(),
            recipientId: form.userId,
            recipientEmail: user.email
        }
        if (form.sentByOption === SentByOption.Email) {
            notification.senderAvatarUrl = form.contact ?
                (process.env.NODE_ENV === "production" ?
                    process.env.REACT_APP_BASE_API_URL_PROD :
                    process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.contact.avatar :
                null;
            notification.senderFullName = form.contact.firstName + " " + form.contact.lastName;
            notification.message = form.title + " has been completed by " + form.contact.firstName + " " + form.contact.lastName;
            notification.callBackUrl = '/forms/f/' + form.id;
        } else {
            notification.senderAvatarUrl = null;
            notification.senderFullName = "Do Form";
            notification.message = form.title + " has been completed";
            notification.callBackUrl = '/forms/done';
        }
        createNotification(notification);
        form.skipSignature ? setFormFillStep(step + 2) : setFormFillStep(step + 1)
    }

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor={user.brandColor1} isPhoneDialogActive={true} />
            </Grid>
            <Container maxWidth="md">
                {
                    form.logo &&
                    <div style={{ textAlign: 'center' }}>
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            alt="Company"
                            width="100%"
                            style={{ margin: '25px 0 12px' }}
                        />
                    </div>
                }
                <h3 style={{ lineHeight: 1.2, textAlign: 'center', margin: "30px 0 5px" }}>{form.title}</h3>
                <div style={{ margin: "30px 0" }}>
                    {ReactHtmlParser(form.statement)}
                </div>
                {
                    form.questions.map(question => {
                        switch (question.inputType) {
                            case InputType.Checkbox:
                                return (
                                    <div style={{ marginBottom: "25px" }} key={question.id}>
                                        <CheckboxGroupQuestionCard question={question} />
                                    </div>)
                            case InputType.Radio:
                                return (
                                    <div style={{ marginBottom: "25px" }} key={question.id}>
                                        <RadioGroupQuestionCard question={question} />
                                    </div>)
                            default:
                                return (
                                    <div style={{ marginBottom: "25px" }} key={question.id}>
                                        <TextQuestionCard question={question} />
                                    </div>)
                        }
                    })
                }
            </Container>
            <Grid item sx={{ margin: '20px 0' }}>
                <div style={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        disabled={!isFormFilled}
                        style={{ textTransform: 'none', borderRadius: "16px", background: isFormFilled && `${user.brandColor1}`, color: isFormFilled && `${user.brandColor2}` }}
                        onClick={onSave}>
                        Save & Continue
                    </Button>
                </div>
                {!isFormFilled && <p className="error-wrapper" style={{ marginTop: "10px" }}>Please fill all required questions.</p>}
            </Grid>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Grid>
    )
}
