import { useState } from 'react';
import { AdminFormCardProps } from './types'
import { AppFormStatus } from '../../../types/appForm';
import { removeFormAxios } from '../../../api/form';
import { Box, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import AppDeleteConfirmDialog from '../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StyledDeleteIcon from '../../../components/StyledIcons/StyledDeleteIcon';
import Spinner from '../../../components/Spinner/Spinner';

export default function AdminFormCard({ form, onDelete }: AdminFormCardProps) {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [message, setMessage] = useState<null | string>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

    const handleHidenMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);

    const handleHidenMenuClose = () => setAnchorElUser(null);

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setMessage(null);
    };

    const removeForm = async () => {
        try {
            setLoading(true);
            setMessage(null);
            await removeFormAxios(form.id);
            setMessage("Form deleted");
            onDelete(form);
        } catch (error) {
            setMessage(error.message || 'Unable to remove the form');
        } finally {
            setLoading(false);
        }
    }
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" gap='5px' sx={{ minHeight: '56px', margin: '5px 0' }}>
            <Grid item>
                <Grid container justifyContent="flex-start" alignItems="center" gap='10px'>
                    <Grid item>
                        {
                            form.logo ?
                                <img
                                    src={(process.env.NODE_ENV === "production" ?
                                        process.env.REACT_APP_BASE_API_URL_PROD :
                                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                                    style={{ width: '32px' }}
                                    alt=''
                                /> :
                                <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                        }
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" sx={{
                            width: { xs: "70px", sm: "230px", md: "370px", lg: "700px" }
                        }}>
                            <span>{form.title}</span>
                            <span className='text-14'>Status: {AppFormStatus[form.status]}</span>
                            <span className='text-12'>Created: {(new Date(form.createdAt.toString())).toLocaleString()}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Box className="table-actions" sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    {
                        loading ? <Spinner /> :
                            <StyledDeleteIcon tooltipTitle="Remove" onDelete={() => setConfirmDialogOpen(true)} />
                    }
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleHidenMenuOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleHidenMenuClose}
                    >
                        <MenuItem onClick={handleHidenMenuClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Remove</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Grid>
            {confirmDialogOpen && <AppDeleteConfirmDialog onCancel={() => setConfirmDialogOpen(false)} onDelete={() => removeForm()} />}
            <Snackbar
                open={message !== null}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
                message={message}
            />
        </Grid>
    )
}
