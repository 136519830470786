import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { AppFormStatus, IAppForm } from "../../../../types/appForm";
import { Container, Grid } from "@mui/material";
import AutocompleteFormSelect from "../AutocompleteFormSelect/AutocompleteFormSelect";
import Spinner from "../../../../components/Spinner/Spinner";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import { IntersectionObserverComponent } from "../../../../components/IntersectionObserver/IntersectionObserverComponent";
import SendFormCard from "./SendFormCard";
import SelectContactsFilter from "../SelectContactFilter/SelectContactsFilter";
import SentSortFieldBar from "./SentSortFieldBar";
import PreviewForm from "../../../../components/PreviewForm/PreviewForm";

export default function SentPage(): JSX.Element {
    const { formSearchResult, searchForms, filters, sortField, loading, error } = useTypedSelector(state => state.sendForm);
    const { searchContacts } = useTypedSelector(state => state.contact);
    const { auth } = useTypedSelector(state => state.auth);
    const { getSendForms, getSearchSendForms, getSearchContacts, setSendFormPage, setSendFormSearchCriteria,
        loadMoreSendForms, setSendFormContactFilter } = useActions();
    const [previewForm, setPreviewForm] = useState<IAppForm | null>(null);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (formSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && formSearchResult.currentPageNumber * formSearchResult.pageSize <= formSearchResult.totalItemCount) {
                loadMoreSendForms(formSearchResult.pageSize, formSearchResult.currentPageNumber + 1, filters.searchText, filters.contactId, sortField, formSearchResult.order);
                setSendFormPage(formSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (formSearchResult.itemList.length === 0)
            getSendForms(formSearchResult.pageSize, formSearchResult.currentPageNumber, filters.searchText, filters.contactId, sortField, formSearchResult.order);
        if (searchForms.length === 0) getSearchSendForms(AppFormStatus.Sent);
        if (searchContacts.length === 0) getSearchContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSearchChanged = (text: string) => {
        setSendFormPage(1);
        setSendFormSearchCriteria(text);
        getSendForms(formSearchResult.pageSize, 1, text, filters.contactId, sortField, formSearchResult.order);
    }

    const onSelectContactChanged = (contactId: string) => {
        setSendFormPage(1);
        setSendFormContactFilter(contactId);
        getSendForms(formSearchResult.pageSize, 1, filters.searchText, contactId, sortField, formSearchResult.order);
    }

    return (
        <Container maxWidth="lg" className='layout-container' >
            {
                previewForm ?
                    <PreviewForm form={previewForm} user={auth.user} closeForm={() => setPreviewForm(null)} />
                    :
                    <>
                        <Helmet>
                            <title>DoYourForm.com - Sent</title>
                            <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
                        </Helmet>
                        {/* Page Header */}
                        <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Grid>
                                        <AutocompleteFormSelect forms={searchForms} onChange={onSearchChanged} />
                                    </Grid>
                                    <Grid item>
                                        <SentSortFieldBar fields={["Title", "Sent At"]} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <SelectContactsFilter
                                    contacts={searchContacts}
                                    label="By contact"
                                    onSelectChanged={onSelectContactChanged}
                                    value={filters.contactId}
                                />
                            </Grid>
                        </Grid>
                        {/* List of forms */}
                        {
                            formSearchResult.itemList.length > 0 ?
                                <div style={{ margin: "30px 0" }}>
                                    {formSearchResult.itemList.map(form =>
                                        <SendFormCard key={form.id} form={form} onPreview={form => setPreviewForm(form)} />
                                    )}
                                </div> :
                                loading ? <Spinner /> :
                                    error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> :
                                        <p style={{ padding: "0 15px" }}>No sent forms</p>
                        }
                        <IntersectionObserverComponent onIntersection={handleIntersection} />
                        {(formSearchResult.itemList.length > 0 && loading) && <Spinner />}
                    </>
            }
        </Container>
    )
}
