import { Link } from "react-router-dom";
import { RouteNames } from "../../../routing";
import { Helmet } from "react-helmet";
import { Container, Grid, Typography } from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import LoginForm from "./LoginForm";

export default function LoginPage(): JSX.Element {

    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Sign In</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Sign In" />
            <Grid container direction="column" alignItems="center">
                <LoginForm />
                <Typography component={'p'} my={3} >
                    Or <Link to={RouteNames.REGISTER}>Sign Up</Link> if you don't have an account yet.
                </Typography>
                <Typography component={'p'} mb={3} >
                    <Link to={RouteNames.FORGOT_PASSWORD}>Forgot password?</Link>
                </Typography>
            </Grid>
        </Container>
    )
}
