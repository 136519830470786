import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { RouteNames } from "../../../routing";
import { Roles } from "../../../types/auth";
import { Container, Grid } from "@mui/material";
import { IntersectionObserverComponent } from "../../../components/IntersectionObserver/IntersectionObserverComponent";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import Spinner from "../../../components/Spinner/Spinner";
import InvoiceSortFieldBar from "../../GoFormUserPages/Billing/InvoiceSortFieldBar";
import AdminInvoiceCard from "./AdminInvoiceCard";

export default function AdminInvoicesPage() {
    const { invoiceSearchResult, sortField, loading, error } = useTypedSelector(state => state.invoice);
    const { auth } = useTypedSelector(state => state.auth);
    const { getAllInvoices, setInvoicePage, loadMoreAllInvoices } = useActions();
    const navigate = useNavigate();

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (invoiceSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && invoiceSearchResult.currentPageNumber * invoiceSearchResult.pageSize <= invoiceSearchResult.totalItemCount) {
                loadMoreAllInvoices(invoiceSearchResult.pageSize, invoiceSearchResult.currentPageNumber + 1, sortField, invoiceSearchResult.order);
                setInvoicePage(invoiceSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (!auth.roles.includes(Roles.ADMIN)) navigate(RouteNames.LOGIN);
        if (invoiceSearchResult.itemList.length === 0)
            getAllInvoices(invoiceSearchResult.pageSize, invoiceSearchResult.currentPageNumber, sortField, invoiceSearchResult.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container maxWidth="lg" className={loading ? 'layout-container loading-opacity' : 'layout-container'} >
            <Helmet>
                <title>DoYourForm.com - Admin Invoices</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            {/* Page Header */}
            <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <InvoiceSortFieldBar fields={["Invoice No", "Created At"]} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* Invoices List */}
            {
                invoiceSearchResult.itemList.length > 0 ?
                    <div style={{ margin: "30px 0" }}>
                        {invoiceSearchResult.itemList.map(invoice =>
                            <AdminInvoiceCard key={invoice.id} invoice={invoice} />
                        )}
                    </div> :
                    loading ? <Spinner /> :
                        error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> :
                            <p style={{ padding: "0 15px" }}>No invoices</p>
            }
            <IntersectionObserverComponent onIntersection={handleIntersection} />
            {(invoiceSearchResult.itemList.length > 0 && loading) && <Spinner />}
        </Container>
    )
}
