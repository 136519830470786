import { useEffect, useState } from 'react';
import { getContactsByUserIdAxios } from '../../../api/contact';
import { getFormsByUserIdAxios } from '../../../api/form';
import { AccountFormProps, TabPanelProps } from './types';
import { IAppForm } from '../../../types/appForm';
import { IContact } from '../../../types/contact';
import { clonedObject } from '../../../hooks/clonedObject';
import { OrderType } from '../../../types/common/orderType';
import { ISearchResult } from '../../../types/common/searchResult';
import { IAccount } from '../../../types/account';
import { Box, Container, Grid, Snackbar, Tab, Tabs } from '@mui/material';
import ErrorMessage from '../../../components/Messages/ErrorMessage';
import Spinner from '../../../components/Spinner/Spinner';
import AdminContactCard from './AdminContactCard';
import AdminFormCard from './AdminFormCard';
import AccountInfo from './AccountInfo';
import RolesList from './RolesList';

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ManageUser({ user, onClose }: AccountFormProps) {
    const [tabValue, setTabValue] = useState(0);
    const [account, setAccount] = useState<null | IAccount>(null);
    const [contacts, setContacts] = useState<null | ISearchResult<IContact>>(null);
    const [forms, setForms] = useState<null | ISearchResult<IAppForm>>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<null | string>(null);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);

    const changeTabHandler = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackbarMessage(null)
    };

    const getContacts = async (id: string) => {
        try {
            setLoading(true);
            setError(null);
            setContacts(await getContactsByUserIdAxios(1, id, "Created At", OrderType.Ascending));
        } catch (error) {
            setError(error.message || 'Unable to get users contacts.');
        } finally {
            setLoading(false);
        }
    }

    const getForms = async (id: string) => {
        try {
            setLoading(true);
            setError(null);
            setForms(await getFormsByUserIdAxios(1, id, "Created At", OrderType.Ascending));
        } catch (error) {
            setError(error.message || 'Unable to get users forms.');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getContacts(user.id);
        getForms(user.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setAccount({ ...user.account })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const onDeleteContact = async (contact: IContact) => {
        let contactsStateToUpdate: ISearchResult<IContact> = clonedObject(contacts);
        contactsStateToUpdate.itemList = contacts.itemList.filter(c => c.id !== contact.id);
        contactsStateToUpdate.totalItemCount = contacts.totalItemCount - 1;

        setContacts(contactsStateToUpdate);
        setAccount({ ...account, currentContacts: account.currentContacts - 1 });
    }

    const onDeleteForm = async (form: IAppForm) => {
        let formsStateToUpdate: ISearchResult<IAppForm> = clonedObject(forms);
        formsStateToUpdate.itemList = forms.itemList.filter(f => f.id !== form.id);
        formsStateToUpdate.totalItemCount = forms.totalItemCount - 1;

        setForms(formsStateToUpdate);
    }

    return (
        <Container maxWidth="lg" className='layout-container' >
            <AccountInfo user={user} onClose={onClose} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={changeTabHandler} aria-label="Account tabs">
                        <Tab label={contacts ? `Contacts(${contacts.totalItemCount})` : "Contacts"}  {...a11yProps(1)} sx={{ textTransform: "none" }} />
                        <Tab label={forms ? `Forms(${forms.totalItemCount})` : "Forms"}  {...a11yProps(2)} sx={{ textTransform: "none" }} />
                        <Tab label="Roles"  {...a11yProps(3)} sx={{ textTransform: "none" }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    {
                        loading ? <Spinner /> :
                            error ? <ErrorMessage appearance="small">{error}</ErrorMessage> :
                                contacts &&
                                <Container maxWidth="lg" className='layout-container' >
                                    <Grid container spacing={2} sx={{ margin: '30px 0', padding: '0', width: '100%' }}>
                                        {
                                            contacts.itemList.map(contact =>
                                                <AdminContactCard key={contact.id} contact={contact} onDelete={onDeleteContact} />)
                                        }
                                    </Grid>
                                </Container>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    {
                        loading ? <Spinner /> :
                            error ? <ErrorMessage appearance="small">{error}</ErrorMessage> :
                                forms &&
                                <Container maxWidth="lg" className='layout-container' >
                                    <Grid container spacing={2} sx={{ margin: '30px 0', padding: '0', width: '100%' }}>
                                        {
                                            forms.itemList.map(form =>
                                                <AdminFormCard key={form.id} form={form} onDelete={onDeleteForm} />)
                                        }
                                    </Grid>
                                </Container>
                    }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <RolesList userId={user.id} />
                </CustomTabPanel>
            </Box>
            <Snackbar
                open={snackbarMessage !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
                message={snackbarMessage}
            />
        </Container>
    )
}
