import { useState } from 'react';
import { clonedObject } from '../../../../hooks/clonedObject';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { ISnackBarMessageState } from '../../../../types/common/snackBarMessageState';
import { SendFormCardProps } from '../types';
import { SentByOption } from '../../../../types/appForm';
import { updateAccountAxios } from '../../../../api/account';
import { getImagesSizeAxios, removeFormAxios, resendFormAxios } from '../../../../api/form';
import { Alert, Box, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import StyledDeleteIcon from '../../../../components/StyledIcons/StyledDeleteIcon';
import AppDeleteConfirmDialog from '../../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog';

export default function SendFormCard({ form, onPreview }: SendFormCardProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const { removeSendForm, resendForm, updateAuthUser } = useActions();

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleClose = () => setAnchorElUser(null);

    const сloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const onResendForm = async () => {
        try {
            setLoading(true);
            setSnackBarState(null);
            const formToResend = await resendFormAxios(form);
            resendForm(formToResend);
            setSnackBarState({ message: "The form has been sent.", severity: "success" });
        } catch (error) {
            setSnackBarState({ message: "Unable to resend the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onDeleteSentForm = async () => {
        try {
            setLoading(true);
            setSnackBarState(null);
            const filesSizeToBeDeleted = await getImagesSizeAxios(form);
            await removeFormAxios(form.id);
            removeSendForm(form.id);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize -= filesSizeToBeDeleted;
            if (userToUpdate.account.currentFileSize < 0) userToUpdate.account.currentFileSize = 0;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
        } catch (error) {
            setSnackBarState({ message: "Unable to remove the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div onClick={() => onPreview(form)} style={{ flexBasis: "24px", flexShrink: 0, cursor: "pointer" }}>
                {
                    form.logo ?
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            width="32px"
                            alt=''
                        /> :
                        <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                }
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start"
                sx={{ flexGrow: 1, margin: "0 10px", cursor: "pointer" }}
                onClick={() => onPreview(form)}
            >
                <span>{form.title}</span>
                <span className='text-14'>{form.contact ? form.contact.firstName + ' ' + form.contact.lastName : 'Not assigned'}</span>
                <span className='text-12'>
                    Sent by {SentByOption[form.sentByOption]} at {form.sentAt.toString().includes("Z") ? (new Date(form.sentAt.toString())).toLocaleString() : (new Date(form.sentAt.toString() + "Z")).toLocaleString()}
                </span>
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "140px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    {loading && <CircularProgress size="1rem" sx={{ m: '4px 10px 0 0' }} />}
                    {
                        form.sentByOption === SentByOption.Email &&
                        <Tooltip title='Resend' placement="top">
                            <ForwardToInboxOutlinedIcon
                                sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                onClick={onResendForm}
                            />
                        </Tooltip>
                    }
                    {form.sentByOption === SentByOption.Email && <Divider orientation="vertical" flexItem />}
                    <Tooltip title='Preview' placement="top">
                        <PreviewOutlinedIcon
                            sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                            onClick={() => onPreview(form)}
                        />
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    <StyledDeleteIcon tooltipTitle="Remove" onDelete={() => setConfirmDialogOpen(true)} />
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={onResendForm}>
                                <ForwardToInboxOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Resend</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px"
                                onClick={() => onPreview(form)}
                            >
                                <PreviewOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Preview</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Remove</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {confirmDialogOpen && <AppDeleteConfirmDialog onCancel={() => setConfirmDialogOpen(false)} onDelete={onDeleteSentForm} />}
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={сloseSnackbar}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </div>
    )
}