import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Grid } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { PhoneDialog } from './PhoneDialog';
import { RouteNames } from '../../../routing';
import { StepsHeaderProps } from '../types';
import { AppFormStatus } from '../../../types/appForm';

export default function StepsHeader({ brandColor, isPhoneDialogActive }: StepsHeaderProps) {
    const { form, step, user } = useTypedSelector(state => state.formFill)
    const { setFormFillStep } = useActions();
    const [openPhoneDialog, setOpenPhoneDialog] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <Grid container direction='row' justifyContent='space-between' alignItems='center' id='steps-header'
            sx={{ boxShadow: '0px 5px 10px 0px #C6C6C6', width: '100%', minHeight: '64px' }}
        >
            <Grid item>
                {
                    step > 1 && form.status === AppFormStatus.Sent ?
                        <ArrowBackIosNewOutlinedIcon
                            sx={{ cursor: 'pointer', marginLeft: '5px', fill: `${brandColor}` }}
                            onClick={() => form.skipSignature && step === 4 ? setFormFillStep(step - 2) : setFormFillStep(step - 1)}
                        />
                        :
                        <HomeOutlinedIcon
                            sx={{ cursor: 'pointer', marginLeft: '5px', fill: `${user ? user.brandColor1 : "#158f7d"}` }}
                            onClick={() => navigate(RouteNames.HOME)}
                        />
                }
            </Grid>
            {
                user ?
                    user.phoneNumber ?
                        <PhoneEnabledOutlinedIcon
                            sx={{ cursor: 'pointer', marginRight: '5px', fill: `${user ? user.brandColor1 : "#158f7d"}` }}
                            onClick={() => setOpenPhoneDialog(true)}
                        /> :
                        <a href={`mailto:${user.email}`} style={{ textDecoration: "none" }}>
                            <MailOutlineIcon sx={{ cursor: 'pointer', marginRight: '5px', fill: `${user ? user.brandColor1 : "#158f7d"}` }} />
                        </a> :
                    <PhoneEnabledOutlinedIcon
                        sx={{ cursor: 'pointer', marginRight: '5px', fill: "var(--lightGrey)" }}
                    />
            }
            <PhoneDialog
                isOpen={isPhoneDialogActive && openPhoneDialog}
                firstName={user ? user.firstName : ''}
                lastName={user ? user.lastName : ''}
                phoneNumber={user ? user.phoneNumber : ''}
                onClose={() => setOpenPhoneDialog(false)}
            />
        </Grid>
    )
}
