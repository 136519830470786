import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducer';
import { completedFormReducer } from './reducers/completedFormReducer';
import { contactReducer } from './reducers/contactReducer';
import { draftFormReducer } from './reducers/draftFormReducer';
import { formFillReducer } from './reducers/formFillReducer';
import { invoiceReducer } from './reducers/invoiceReducer';
import { notificationReducer } from './reducers/notificationReducer';
import { pricepackageReducer } from './reducers/pricepackageReducer';
import { reminderReducer } from './reducers/remindersReducer';
import { sendFormReducer } from './reducers/sendFormReducer';
import { templateFormReducer } from './reducers/templateFormReducer';
import { userReducer } from './reducers/userReduser';

export const rootReducer = combineReducers({
    auth: authReducer,
    completedForm: completedFormReducer,
    contact: contactReducer,
    draftForm: draftFormReducer,
    formFill: formFillReducer,
    invoice: invoiceReducer,
    notification: notificationReducer,
    pricepackage: pricepackageReducer,
    reminder: reminderReducer,
    sendForm: sendFormReducer,
    templateForm: templateFormReducer,
    user: userReducer
})

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    })
}

export type RootState = ReturnType<typeof rootReducer>
