import { PriceCardProps } from './types'
import { PricePackageType } from '../../../types/pricepackage';
import { Grid, Paper, Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check';
import MuiButton from '../../../components/Button/MuiButton';

export default function PriceCard(props: PriceCardProps) {

    const options: string[] = props.pricepackage.posibilities.split('|');

    return (
        <Paper elevation={6} className='price-card' sx={{ overflow: 'hidden', padding: "20px 14px", marginBottom: "30px" }}>
            <Typography component='p' sx={{ fontSize: "20px", lineHeight: "28px", textAlign: "center" }}>
                {PricePackageType[props.pricepackage.pricePackageType]}
            </Typography>
            <Typography component='p' sx={{ color: "#3b78e7", margin: "16px 0 22px", fontSize: "36px", lineHeight: "44px", textAlign: "center" }}>
                ${props.pricepackage.price}
            </Typography>
            <Typography component='p' sx={{ color: "#5f7c78", margin: "16px 0 22px", fontSize: "13px", lineHeight: "18px", textAlign: "center" }}>
                {props.pricepackage.description}
            </Typography>
            <Typography component='p' sx={{ textAlign: "center" }}>
                <MuiButton
                    disabled={props.pricepackage.pricePackageType === PricePackageType.Free}
                    size='medium' variant='contained'
                    onClickHandler={() => props.onOrder(props.pricepackage)}
                >
                    Order
                </MuiButton>
            </Typography>
            {
                options.map((option, index) =>
                    <Grid container key={index} direction="row" justifyContent="flex-start" alignItems="center" gap='15px' sx={{ margin: "10px 0" }} >
                        <Grid item>
                            <CheckIcon sx={{ fill: '#5F7C78' }} />
                        </Grid>
                        <Grid item sx={{ width: "calc(100% - 70px)" }}>
                            <Typography component={'span'} sx={{ fontSize: "14px", lineHeight: "18px" }}>
                                {option}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
        </Paper>
    )
}
