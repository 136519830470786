import axios from 'axios';
import { OrderType } from '../types/common/orderType';
import { ISearchResult } from '../types/common/searchResult';
import { CreateInvoiceDto, IInvoice } from '../types/invoice';

/**
 * Get list of Invoices
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getInvoicesAxios(
    limit: number,
    page: number,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IInvoice>> {
    return (
        await axios.get(`/invoice/get?limit=${limit}&page=${page}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get list of Invoices
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getAllInvoicesAxios(
    limit: number,
    page: number,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IInvoice>> {
    return (
        await axios.get(`/invoice/getAll?limit=${limit}&page=${page}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get Invoice specified by identifier
 * @param id<string> Invoice identifier
 */
export async function getInvoiceByIdAxios(id: string): Promise<IInvoice> {
    return (await axios.get(`/invoice/get/${id.toString()}`)).data;
}

/**
 * Creates a new Invoice
 * @param createInvoiceDto<CreateInvoiceDto> object of type CreateInvoiceDto
 * @returns<IInvoice> Created Invoice
 */
export async function createInvoiceAxios(createInvoiceDto: CreateInvoiceDto): Promise<IInvoice> {
    return (await axios.post("/invoice/create", createInvoiceDto)).data;
}

/**
 * Updates the existing Invoice
 * @param invoice<IInvoice> Object of type IInvoice
 * @returns<IInvoice> Updated Invoice object
 */
export async function updateInvoiceAxios(invoice: IInvoice): Promise<IInvoice> {
    return (await axios.put("/invoice/update", invoice)).data;
}

/**
 * @param id<string> Invoice identifier
 * @param isRead<boolean> Invoice's IsPaid status
 * @returns<IInvoice> Updated Invoice object
 */
export async function updateInvoiceIsPaidAxios(id: string, isPaid: boolean): Promise<IInvoice> {
    return (await axios.patch(`/invoice/partialupdate/${id}`, [{ op: "replace", path: "/IsPaid", value: isPaid }])).data;
}

/**
 * @param id<string> Invoice identifier
 * @param isRead<boolean> Invoice's IsRead status
 * @returns<IInvoice> Updated Invoice object
 */
export async function updateInvoiceIsReadAxios(id: string, isRead: boolean): Promise<IInvoice> {
    return (await axios.patch(`/invoice/partialupdate/${id}`, [{ op: "replace", path: "/IsRead", value: isRead }])).data;
}

/**
 * Delete's the Invoice specified by identifier
 * @param id<string> Identifier
 */
export async function removeInvoiceAxios(id: string): Promise<void> {
    return await axios.delete(`/invoice/delete/${id}`);
}