import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Button, Container, Grid } from '@mui/material'
import StepsHeader from './components/StepsHeader';
import { useActions } from "../../hooks/useActions";
import FormResultsPage from "./FormResultsPage";
import { SentByOption } from '../../types/appForm';

export default function Step5Congratulations() {
    const { form, user, showResults } = useTypedSelector(state => state.formFill)
    const { setShowResults } = useActions();

    if (showResults) return <FormResultsPage />

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor={user.brandColor1} isPhoneDialogActive={true} />
            </Grid>
            {
                user.logo &&
                <Grid item sx={{ textAlign: 'center' }}>
                    <img
                        src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.logo}
                        alt="Company"
                        style={{ margin: '25px 0 12px', maxWidth: "80%" }}
                    />
                </Grid>
            }
            <Grid item sx={{ width: '90%', textAlign: 'center' }}>
                <Container maxWidth="sm">
                    <p className='pt1' style={{ textAlign: 'center' }}>Congratulations!</p>
                    <h3 style={{ lineHeight: 1.2, textAlign: 'center' }}>{form.title}</h3>
                    <p className='pt1' style={{ textAlign: 'center' }}>is completed.</p>
                </Container>
            </Grid>
            {
                form.sentByOption === SentByOption.Email &&
                <Grid item sx={{ margin: '20px 0' }}>
                    <Button
                        variant="contained"
                        style={{ textTransform: 'none', borderRadius: "16px", background: `${user.brandColor1}`, color: `${user.brandColor2}` }}
                        onClick={() => setShowResults(true)}>
                        View Results
                    </Button>
                </Grid>
            }
        </Grid>
    )
}
