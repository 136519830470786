export interface IRoute {
    path: string;
    component: JSX.Element;
    title: string;
}

export enum RouteNames {
    ADMIN_INVOICES = "/admin/invoices",
    ADMIN_REMINDERS = '/admin/reminders',
    ADMIN_USERS = '/admin/users',
    BRANDING = '/branding',
    CONTACTS = '/contacts',
    CHANGE_EMAIL = '/change-email',
    CHANGE_PASSWORD = '/change-password',
    DASHBOARD = '/dashboard',
    EMAIL_CONFIRM = '/email-confirm',
    ERROR = '/error',
    FORGOT_PASSWORD = '/forgot-password',
    FORMS = '/forms',
    FORMS_DRAFT = '/forms/drafts',
    FORMS_SENT = '/forms/sent',
    FORMS_DONE = '/forms/done',
    FORM_FILL = '/forms/f/:id',
    HELP = '/help',
    HOME = "/",
    INVOICES = "/invoices",
    INVOICE = "/invoices/:id",
    LOGIN = '/login',
    PRIVACY_POLICY = "/privacy",
    PROFILE = "/profile",
    REGISTER = '/register',
    REGISTER_COMPLETE = '/register-complete',
    RESET_PASSWORD = '/reset-password',
    TERMS_CONDITIONS = "/terms",
    TEST_UI = '/test'
}
