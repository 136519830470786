import { OrderType } from "../../types/common/orderType";
import { INotification, NotificationActionTypes, NotificationAction, NotificationState } from "../../types/notification";

const initialState: NotificationState = {
    notificationSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Ascending,
        pageCount: 0,
        pageSize: 25,
        searchCriteria: "",
        totalItemCount: 0
    },
    loading: true,
    error: null
}

export const notificationReducer = (state: NotificationState = initialState, action: NotificationAction): NotificationState => {
    switch (action.type) {
        case NotificationActionTypes.GET_NOTIFICATIONS:
            return { ...state, notificationSearchResult: action.payload };
        case NotificationActionTypes.LOAD_MORE_NOTIFICATIONS:
            return {
                ...state,
                notificationSearchResult: { ...action.payload, itemList: state.notificationSearchResult.itemList.concat(action.payload.itemList) },
            };
        case NotificationActionTypes.SET_NOTIFICATION_ERROR:
            return { ...state, error: action.payload };
        case NotificationActionTypes.SET_NOTIFICATION_LOADING:
            return { ...state, loading: action.payload };
        case NotificationActionTypes.SET_NOTIFICATION_PAGE:
            return { ...state, notificationSearchResult: { ...state.notificationSearchResult, currentPageNumber: action.payload } };
        case NotificationActionTypes.CREATE_NOTIFICATION:
            return { ...state, notificationSearchResult: { ...state.notificationSearchResult, itemList: [action.payload, ...state.notificationSearchResult.itemList] } }
        case NotificationActionTypes.UPDATE_NOTIFICATION_ISREAD:
            return { ...state, notificationSearchResult: { ...state.notificationSearchResult, itemList: updateNotificationIsRead(state, action.payload) } };
        case NotificationActionTypes.REMOVE_NOTIFICATION:
            return { ...state, notificationSearchResult: { ...state.notificationSearchResult, itemList: deleteNotification(state, action) } };
        default: return state;
    }
}

function deleteNotification(state: NotificationState, action: NotificationAction): Array<INotification> {
    return state.notificationSearchResult.itemList.filter(notification => notification.id !== action.payload)
}

function updateNotificationIsRead(state: NotificationState, notificationToUpdate: INotification): Array<INotification> {
    return state.notificationSearchResult.itemList.map((notification: INotification) => {
        if (notification.id === notificationToUpdate.id) return notificationToUpdate;
        return notification;
    })
}