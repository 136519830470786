import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../../routing';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Box, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PaletteIcon from '@mui/icons-material/Palette';
import SettingsIcon from '@mui/icons-material/Settings';
import { PricePackageType } from '../../../types/pricepackage';

export default function SettingsBar() {
    const { auth } = useTypedSelector(state => state.auth);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Tooltip title="App Settings">
                <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                    <SettingsIcon sx={{ color: "#5F7C78" }} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to={RouteNames.PROFILE} onClick={handleClose}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" gap="20px">
                        <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Profile</Typography>
                        <AccountCircleIcon sx={{ fill: '#5F7C78' }} />
                    </Grid>
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={RouteNames.BRANDING}
                    onClick={handleClose}
                    disabled={auth.user.account.pricePackageType === PricePackageType.Free}
                >
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" gap="20px">
                        <Typography sx={{ textAlign: "left", fontSize: "0.95rem" }}>Branding</Typography>
                        <PaletteIcon sx={{ fill: '#5F7C78' }} />
                    </Grid>
                </MenuItem>
            </Menu>
        </Box>
    )
}
