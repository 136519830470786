import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Step1Welcome from './Step1Welcome';
import Step2FillAnswers from './Step2FillAnswers';
import Step3Signature from './Step3Signature';
import Step4Congratulations from './Step4Congratulations';
import StepNotFound from './StepNotFound';
import Step0Loading from './Step0Loading';

export default function FormFillPage() {
    const { id } = useParams();
    const { error, step } = useTypedSelector(state => state.formFill)
    const { getFormFill, setGeoPosition } = useActions();
    // geo position
    // https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
    // https://www.w3schools.com/html/html5_geolocation.asp
    function success(pos: { coords: any; }) {
        const crd = pos.coords;
        setGeoPosition({ latitude: crd.latitude, longitude: crd.longitude })
    }

    useEffect(() => {
        getFormFill(id);
        navigator.geolocation.getCurrentPosition(success);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (error) return <StepNotFound />

    switch (step) {
        case 0:
            return (<Step0Loading />)
        case 1:
            return (<Step1Welcome />)
        case 2:
            return (<Step2FillAnswers />)
        case 3:
            return (<Step3Signature />)
        case 4:
            return (<Step4Congratulations />)
        default:
            return (<StepNotFound />)
    }
}
