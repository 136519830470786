import ReactHtmlParser from 'react-html-parser';
import { Container, Grid, TextField } from '@mui/material';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import StepsHeader from './components/StepsHeader';
import { InputType } from '../../types/question';
import CheckboxGroupQuestionCard from './components/CheckboxGroupQuestionCard';
import RadioGroupQuestionCard from './components/RadioGroupQuestionCard';
import TextQuestionCard from './components/TextQuestionCard';
import { AppFormStatus, SentByOption } from '../../types/appForm';

export default function FormResultsPage() {
    const { form, user } = useTypedSelector(state => state.formFill)

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor={user.brandColor1} isPhoneDialogActive={true} />
            </Grid>
            {
                user.logo &&
                <Grid item sx={{ textAlign: 'center' }}>
                    <img
                        src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.logo}
                        alt="Company"
                        style={{ margin: '25px 0 12px', maxWidth: "80%" }}
                    />
                </Grid>
            }
            <Grid item sx={{ width: '90%', textAlign: 'center' }}>
                <Container maxWidth="sm">
                    {
                        form.logo &&
                        <p>
                            <img src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                                alt="Form Logo" className='preview-image'
                                style={{ minWidth: '100%' }}
                            />
                        </p>
                    }
                    <p className='pt1' style={{ textAlign: 'center' }}>{form.title}</p>
                    <h3 style={{ lineHeight: 1.2, textAlign: 'center' }}>is completed.</h3>
                </Container>
            </Grid>
            <Container maxWidth="md">
                <div style={{ margin: "30px 0" }}>
                    {ReactHtmlParser(form.statement)}
                </div>
                {
                    form.questions.map(question => {
                        switch (question.inputType) {
                            case InputType.Checkbox:
                                return <CheckboxGroupQuestionCard key={question.id} question={question} />
                            case InputType.Radio:
                                return <RadioGroupQuestionCard key={question.id} question={question} />
                            default://InputType.Text || InputType.Number || InputType.Date || InputType.Time
                                return <TextQuestionCard key={question.id} question={question} />
                        }
                    })
                }
                {
                    (form.sentByOption === SentByOption.Email && form.status === AppFormStatus.Done) &&
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            label="Comment of the form result"
                            fullWidth
                            margin="normal" multiline rows={3}
                            variant='outlined' style={{ height: 'none' }}
                            defaultValue={form.comment || ''}
                            disabled
                        />
                    </Grid>
                }
            </Container>
        </Grid>
    )
}
