import axios from 'axios';
import { INotification } from '../types/notification';
import { ISearchResult } from '../types/common/searchResult';

/**
 * Get list of Notifications
 * @param limit<number> Page size
 * @param page<number> Current page
 */
export async function getNotificationsAxios(limit: number, page: number): Promise<ISearchResult<INotification>> {
    return (
        await axios.get(`/notification/get?limit=${limit}&page=${page}`)).data;
}

/**
 * Creates a new Notification
 * @param Notification<INotification> object of type INotification
 * @returns<INotification> Created Notification
 */
export async function createNotificationAxios(notification: INotification): Promise<INotification> {
    return (await axios.post("/notification/create", notification)).data;
}

/**
 * @param id<string> Notification identifier
 * @param isRead<boolean> Notification's IsRead status
 */
export async function updateNotificationIsReadAxios(id: string, isRead: boolean): Promise<void> {
    await axios.patch(`/notification/partialupdate/${id}`, [{ op: "replace", path: "/IsRead", value: isRead }]);
}

/**
 * Delete's the Notification specified by identifier
 * @param id<string> Identifier
 */
export async function removeNotificationAxios(id: string): Promise<void> {
    return await axios.delete(`/notification/delete/${id}`);
}