import { Dispatch } from "redux";
import { getSearchFormsAxios, getTemplatesAxios } from "../../api/form";
import { OrderType } from "../../types/common/orderType";
import { FormAction, FormActionTypes, AppFormStatus } from "../../types/appForm";

export const getTemplateForms = (limit: number, page: number, search: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: null });
            dispatch({
                type: FormActionTypes.GET_TEMPLATE_FORMS, payload:
                    await getTemplatesAxios(limit, page, search || "", sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: false });
        }
    }
}

export const loadMoreTemplateForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: null });
            dispatch({
                type: FormActionTypes.LOAD_MORE_TEMPLATE_FORMS, payload:
                    await getTemplatesAxios(limit, page, search || "", sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: false });
        }
    }
}

export const getSearchTemplateForms = (status: AppFormStatus) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_SEARCH_TEMPLATE_FORMS, payload: await getSearchFormsAxios(status) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: error.message || "Error of loading search forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: false });
        }
    }
}

export const setTemplateFormLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_LOADING, payload: isLoading });
}

export const setTemplateFormPage = (page: number) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_PAGE, payload: page });
}

export const setTemplateFormSearchCriteria = (search: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_FILTER, payload: search });
}

export const setTemplateFormError = (message: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_ERROR, payload: message });
}

export const setTemplateFormSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_SORTFIELD, payload: sortField });
}

export const setTemplateFormSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_TEMPLATE_FORM_SORT, payload: sort });
}