import { AccountStatus } from '../../../types/account';
import { AccountFormProps } from './types';
import { PricePackageType } from '../../../types/pricepackage';
import { Avatar, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';

export default function AccountInfo({ user, onClose }: AccountFormProps) {

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" gap='10px' sx={{ minHeight: '56px', margin: '5px 0' }}>
            <Grid item>
                <Grid container justifyContent="flex-start" alignItems="flex-start" gap='10px'>
                    <Grid item>
                        {
                            user.avatar ?
                                <Avatar
                                    alt="Avatar"
                                    src={(process.env.NODE_ENV === "production" ?
                                        process.env.REACT_APP_BASE_API_URL_PROD :
                                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.avatar}
                                /> :
                                <Avatar sx={{ bgcolor: "#158f7d" }}>X</Avatar>
                        }
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" sx={{
                            width: { xs: "150px", sm: "330px", md: "450px", lg: "700px" }
                        }}>
                            <span style={{ fontWeight: "bold" }}>{user.firstName + " " + user.lastName}</span>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap='15px' sx={{ margin: "10px 0" }} >
                                <span className='text-14'>Email confirmed:</span>
                                {
                                    user.emailConfirmed ?
                                        <DoneAllIcon fontSize='small' sx={{ fill: '#2e7d32' }} /> :
                                        <RemoveDoneIcon fontSize='small' sx={{ fill: 'var(--red)' }} />
                                }
                                <span className='text-14'>Registered: {(new Date(user.createdAt.toString())).toLocaleDateString()}</span>
                            </Grid>
                            <span className='text-14'>{user.email}</span>
                            <span className='text-14'>Price package: {PricePackageType[user.account.pricePackageType]}</span>
                            <span className='text-14'>Account Status: {AccountStatus[user.account.accountStatus]}</span>
                            <span className='text-14' style={{ color: user.account.currentContacts > user.account.contactsLimit ? '#d32f2f' : '#4b605c' }}>
                                Contacts: {user.account.currentContacts} ({user.account.contactsLimit})
                            </span>
                            <span className='text-14' style={{ color: user.account.currentFileSize > user.account.fileSizeLimit ? '#d32f2f' : '#4b605c' }}>
                                Files Size: {(user.account.currentFileSize / 1000000).toFixed(1)} ({user.account.fileSizeLimit / 1000000}Mb)
                            </span>
                            <span className='text-12'>Start Date: {(new Date(user.account.startDate.toString())).toLocaleString()}</span>
                            <span className='text-12' style={{
                                color: new Date() > new Date(user.account.finishDate) ? '#d32f2f' : '#4b605c',
                                fontWeight: new Date() > new Date(user.account.finishDate) && 'bold'
                            }}>
                                Finish Date: {(new Date(user.account.finishDate.toString())).toLocaleString()}
                            </span>
                            <span className='text-12'>Last Login: {(new Date(user.account.lastLoginDate.toString())).toLocaleString()}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <CloseIcon
                    sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" } }}
                    onClick={onClose}
                />
            </Grid>
        </Grid>
    )
}
