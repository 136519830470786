import { useState } from 'react'
import { IAppDeleteConfirmDialogProps } from './types';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiButton from '../Button/MuiButton';

export default function AppDeleteConfirmDialog({ message, onCancel, onDelete }: IAppDeleteConfirmDialogProps) {
    const [open, setOpen] = useState(true);

    const onCloseHandler = () => {
        setOpen(false);
        onCancel();
    };

    const onDeleteHandler = () => {
        onDelete();
        onCloseHandler();
    };

    return (
        <Dialog
            open={open}
            onClose={onCloseHandler}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={onCloseHandler}
            />
            <DialogTitle id="alert-dialog-title">
                Delete Confirmation.
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message || "This entity will be deleted permanently. Please confirm."}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: "20px" }}>
                <MuiButton variant='outlined' onClickHandler={onCloseHandler}>Cancel</MuiButton>
                <MuiButton variant='contained' color='error' onClickHandler={onDeleteHandler}>Confirm</MuiButton>
            </DialogActions>
        </Dialog>
    )
}
