import { useState } from "react";
import { clonedObject } from "../../../../hooks/clonedObject";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { useActions } from "../../../../hooks/useActions";
import { SearchContact } from "../../../../types/contact";
import { ContactsMultiSelectProps } from "../types";
import { updateAccountAxios } from "../../../../api/account";
import { getImagesSizeAxios, sendFormAxios } from "../../../../api/form";
import { TextField, Autocomplete, MenuItem, Modal, Box, Grid } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from '@mui/icons-material/Close';
import MuiButton from "../../../../components/Button/MuiButton";
import ErrorMessage from "../../../../components/Messages/ErrorMessage";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: '80%',
    display: 'block',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minWidth: '20rem',
    width: '80%',
    maxWidth: '60rem'
};

export default function ContactsMultiSelect({ form, onClose }: ContactsMultiSelectProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const { searchContacts } = useTypedSelector(state => state.contact);
    const { sendForm, updateAuthUser } = useActions();
    const [contactsState, setContactsState] = useState<SearchContact[]>([]);
    const [errorContactState, setErrorContactState] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (event, value) => {
        if (value) setErrorContactState(null);
        setContactsState(value);
    };

    const onSendHandler = async () => {
        if (contactsState.length === 0) {
            setErrorContactState("Choose one or more contacts")
            return;
        }
        try {
            setLoading(true);
            setError(null);
            const filesSizeToBeCopied = await getImagesSizeAxios(form);
            if (auth.user.account.currentFileSize + filesSizeToBeCopied * contactsState.length > auth.user.account.fileSizeLimit) {
                setError(`Your files size limit doesn't allow to send the form to ${contactsState.length} contacts.`);
                return;
            }
            const sentFormToCreate = await sendFormAxios({ appFormId: form.id, contacts: contactsState, sentForms: [] });
            sendForm(sentFormToCreate);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize += filesSizeToBeCopied * contactsState.length;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
            onClose();
        } catch (error) {
            setError("Unable to send the form.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
        >
            <Box sx={style}>
                <CloseIcon
                    sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "15px", right: "15px" }}
                    onClick={onClose}
                />
                <Grid container direction="column" justifyContent="center" spacing={2} sx={{ padding: '20px' }}>
                    <Grid item>
                        <Autocomplete
                            fullWidth
                            multiple
                            options={searchContacts.filter(contact => contact.email !== null && contact.email.length > 0)}
                            getOptionLabel={(option) => option['firstName'] + ' ' + option['lastName']}
                            disableCloseOnSelect
                            onChange={handleChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Choose your contacts"
                                    placeholder="Contacts"
                                    error={errorContactState !== null}
                                    helperText={errorContactState?.toString()}
                                />
                            )}
                            renderOption={(props, option, { selected }) => (
                                <MenuItem
                                    {...props}
                                    key={option.id}
                                    value={option.id}
                                    sx={{ justifyContent: "space-between" }}
                                >
                                    {option.firstName + ' ' + option.lastName}
                                    {selected ? <CheckIcon color="info" /> : null}
                                </MenuItem>
                            )}
                        />
                    </Grid>
                    <Grid item>
                        Choose contacts from the list for which the email is known.
                        Each of them will be sent the form to fill out.
                    </Grid>
                    {error && <ErrorMessage appearance="small" >{error}</ErrorMessage>}
                    <Grid item>
                        <Grid container direction='row' justifyContent="center" spacing={2} sx={{ padding: '20px' }} >
                            <Grid item sm={6} sx={{ textAlign: 'center' }}>
                                <MuiButton variant="outlined" onClickHandler={onClose}>
                                    Cancel
                                </MuiButton>
                            </Grid>
                            <Grid item sm={6} sx={{ textAlign: 'center' }}>
                                <MuiButton variant="outlined" onClickHandler={onSendHandler}>
                                    {loading && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Send
                                </MuiButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
