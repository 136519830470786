import { OrderType } from "../../types/common/orderType";
import { AppFormStatus, FormAction, FormActionTypes, FormState, IAppForm } from "../../types/appForm";
import { IQuestion } from "../../types/question";
import { IReminder } from "../../types/reminder";

const initialState: FormState = {
    formSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Descending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    searchForms: [],
    filters: {
        searchText: "",
        status: AppFormStatus.Draft,
        contactId: ""
    },
    sortField: "Created At",
    loading: false,
    error: null
}

export const draftFormReducer = (state: FormState = initialState, action: FormAction): FormState => {
    switch (action.type) {
        case FormActionTypes.GET_DRAFT_FORMS:
            return { ...state, formSearchResult: action.payload };
        case FormActionTypes.LOAD_MORE_DRAFT_FORMS:
            return {
                ...state,
                formSearchResult: {
                    ...action.payload,
                    itemList: state.formSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case FormActionTypes.SET_DRAFT_FORM_ERROR:
            return { ...state, error: action.payload };
        case FormActionTypes.SET_DRAFT_FORM_LOADING:
            return { ...state, loading: action.payload };
        case FormActionTypes.SET_DRAFT_FORM_PAGE:
            return { ...state, formSearchResult: { ...state.formSearchResult, currentPageNumber: action.payload } };
        case FormActionTypes.SET_SEARCH_DRAFT_FORMS:
            return { ...state, searchForms: action.payload };
        case FormActionTypes.SET_DRAFT_FORM_FILTER:
            return { ...state, filters: { ...state.filters, searchText: action.payload } };
        case FormActionTypes.SET_DRAFT_FORM_SORTFIELD:
            return { ...state, sortField: action.payload };
        case FormActionTypes.SET_DRAFT_FORM_SORT:
            return { ...state, formSearchResult: { ...state.formSearchResult, order: action.payload } };
        case FormActionTypes.SET_DRAFT_FORM_FILTER_STATUS:
            return { ...state, filters: { ...state.filters, status: action.payload } };
        case FormActionTypes.CREATE_DRAFT_FORM:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: [action.payload, ...state.formSearchResult.itemList] } }
        case FormActionTypes.UPDATE_DRAFT_FORM:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateForm(state, action.payload) } }
        case FormActionTypes.REMOVE_DRAFT_FORM:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: deleteForm(state, action) } };
        case FormActionTypes.CREATE_QUESTION:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: createFormsQuestion(state, action.payload) } }
        case FormActionTypes.UPDATE_QUESTION:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateFormsQuestion(state, action.payload) } }
        case FormActionTypes.UPDATE_REMINDER:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: updateFormReminder(state, action.payload) } }
        case FormActionTypes.REMOVE_QUESTION:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: removeFormsQuestion(state, action.payload) } };
        case FormActionTypes.REMOVE_REMINDER:
            return { ...state, formSearchResult: { ...state.formSearchResult, itemList: removeFormReminder(state, action.payload) } };
        default: return state;
    }
}

function updateForm(state: FormState, formToUpdate: IAppForm): Array<IAppForm> {
    return state.formSearchResult.itemList.map((form: IAppForm) => {
        if (form.id === formToUpdate.id) return formToUpdate;
        return form;
    })
}

function deleteForm(state: FormState, action: FormAction): Array<IAppForm> {
    return state.formSearchResult.itemList.filter(form => form.id !== action.payload)
}

function createFormsQuestion(state: FormState, questionToCreate: IQuestion): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === questionToCreate.appFormId)[0] };
    formToUpdate.questions = [...formToUpdate.questions, questionToCreate];

    return updateForm(state, formToUpdate);
}

function updateFormsQuestion(state: FormState, questionToUpdate: IQuestion): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === questionToUpdate.appFormId)[0] };
    formToUpdate.questions = formToUpdate.questions.map((question: IQuestion) => {
        if (question.id === questionToUpdate.id) return questionToUpdate;
        return question;
    })
    // added sorting by 'rank' implementing 'Move Up'-functionality
    formToUpdate.questions = formToUpdate.questions.sort((a, b) => a.rank - b.rank);

    return updateForm(state, formToUpdate);
}

function updateFormReminder(state: FormState, reminderToUpdate: IReminder): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === reminderToUpdate.appFormId)[0] };
    formToUpdate.reminder = reminderToUpdate;

    return updateForm(state, formToUpdate);
}

function removeFormsQuestion(state: FormState, questionToRemove: IQuestion): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === questionToRemove.appFormId)[0] };
    formToUpdate.questions = formToUpdate.questions.filter(question => question.id !== questionToRemove.id);

    return updateForm(state, formToUpdate);
}

function removeFormReminder(state: FormState, reminderToRemove: IReminder): Array<IAppForm> {
    let formToUpdate: IAppForm = { ...state.formSearchResult.itemList.filter(f => f.id === reminderToRemove.appFormId)[0] };
    formToUpdate.reminder = null;

    return updateForm(state, formToUpdate);
}
