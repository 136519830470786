import { PricePackageType } from "./pricepackage";

export interface IAccount {
    id?: string;
    pricePackageType: PricePackageType;
    userId: string;
    accountStatus: AccountStatus;
    price: number;
    startDate: Date;
    finishDate: Date;
    fileSizeLimit: number;
    currentFileSize: number;
    contactsLimit: number;
    currentContacts: number;
    remindersLimit: number;
    currentReminders: number;
    createdAt: Date;
    notes?: string;
    lastLoginDate: Date;
}

export enum AccountStatus {
    "All",
    "Active user",
    "To remove",
    "Payed user warned"
}

export interface AccountFilters {
    pricePackageType: PricePackageType; 
}
