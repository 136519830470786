import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { IContact } from "../../../types/contact";
import { Alert, Container, Grid, Snackbar } from "@mui/material";
import ErrorMessage from "../../../components/Messages/ErrorMessage";
import MuiButton from "../../../components/Button/MuiButton";
import Spinner from "../../../components/Spinner/Spinner";
import AutocompleteContactSelect from "./AutocompleteContactSelect";
import ContactCard from "./ContactCard";
import ContactForm from "./ContactForm";
import ContactSortFieldBar from "./ContactSortFieldBar";
import { IntersectionObserverComponent } from "../../../components/IntersectionObserver/IntersectionObserverComponent";
import { ISnackBarMessageState } from "../../../types/common/snackBarMessageState";

export default function ContactPage(): JSX.Element {
    const { contactSearchResult, searchContacts, filters, sortField, loading, error } = useTypedSelector(state => state.contact);
    const { auth } = useTypedSelector(state => state.auth);
    const { getContacts, getSearchContacts, setContactPage, loadMoreContacts } = useActions();
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [contact, setContact] = useState<IContact | null>(null);

    const сloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (contactSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && contactSearchResult.currentPageNumber * contactSearchResult.pageSize <= contactSearchResult.totalItemCount) {
                loadMoreContacts(contactSearchResult.pageSize, contactSearchResult.currentPageNumber + 1, filters, sortField, contactSearchResult.order);
                setContactPage(contactSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (searchContacts.length === 0) getSearchContacts();
        if (contactSearchResult.itemList.length === 0)
            getContacts(contactSearchResult.pageSize, contactSearchResult.currentPageNumber, filters, sortField, contactSearchResult.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onEdit = (contact: null | IContact) => setContact(contact);

    const onCreate = () => {
        if (auth.user.account.currentContacts >= auth.user.account.contactsLimit) {
            setSnackBarState({ message: "You have used the Contacts limit.", severity: "warning" });
            return;
        }
        const contact: IContact = {
            id: null,
            firstName: '',
            lastName: '',
            phone: null,
            email: null,
            linkForMessage: null,
            position: null,
            company: null,
            birthday: null,
            avatar: null,
            notes: null,
            appForms: [],
            userId: auth.user.id,
            createdAt: new Date()
        }
        setContact(contact);
    }

    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Contacts</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            {/* Page Header */}
            <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid>
                            <AutocompleteContactSelect />
                        </Grid>
                        <Grid item>
                            <ContactSortFieldBar
                                fields={["First Name", "Last Name", "Phone", "Email", "Position", "Company", "Birthday", "Created At"]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <MuiButton
                        variant="contained"
                        color="inherit"
                        onClickHandler={onCreate}>
                        <span style={{ padding: "8px" }}>
                            + New Contact
                        </span>
                    </MuiButton>
                </Grid>
            </Grid>
            {/* Contact List */}
            {
                contactSearchResult.itemList.length > 0 ?
                    <div style={{ margin: "30px 0" }}>
                        {contactSearchResult.itemList.map(contact =>
                            <ContactCard key={contact.id} contact={contact} onEdit={onEdit} />
                        )}
                    </div> :
                    loading ? <Spinner /> :
                        error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> :
                            <p style={{ padding: "0 15px" }}>No contacts</p>
            }
            <IntersectionObserverComponent onIntersection={handleIntersection} />
            {(contactSearchResult.itemList.length > 0 && loading) && <Spinner />}
            {contact && <ContactForm contact={contact} closeForm={() => setContact(null)} />}
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={сloseSnackbar}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Container>
    )
}
