import { Dispatch } from "redux";
import { getRemindersAxios } from "../../api/reminder";
import { OrderType } from "../../types/common/orderType";
import { ReminderAction, ReminderActionTypes, ReminderFilters } from "../../types/reminder";

export const getReminders = (limit: number, page: number, filters: ReminderFilters, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<ReminderAction>) => {
        try {
            dispatch({ type: ReminderActionTypes.SET_REMINDERS_LOADING, payload: true });
            dispatch({ type: ReminderActionTypes.SET_REMINDER_ERROR, payload: null });
            dispatch({
                type: ReminderActionTypes.GET_REMINDERS, payload:
                    await getRemindersAxios(limit, page, filters.earlyThanNow, sortField, order)
            });
        } catch (error) {
            dispatch({ type: ReminderActionTypes.SET_REMINDER_ERROR, payload: error.message || "Error of loading reminders." })
        } finally {
            dispatch({ type: ReminderActionTypes.SET_REMINDERS_LOADING, payload: false });
        }
    }
}

export const loadMoreReminders = (limit: number, page: number, filters: ReminderFilters, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<ReminderAction>) => {
        try {
            dispatch({ type: ReminderActionTypes.SET_REMINDERS_LOADING, payload: true });
            dispatch({ type: ReminderActionTypes.SET_REMINDER_ERROR, payload: null });
            dispatch({ type: ReminderActionTypes.LOAD_MORE_REMINDERS, payload: await getRemindersAxios(limit, page, filters.earlyThanNow, sortField, order) });
        } catch (error) {
            dispatch({ type: ReminderActionTypes.SET_REMINDER_ERROR, payload: error.message || "Error of loading reminders." })
        } finally {
            dispatch({ type: ReminderActionTypes.SET_REMINDERS_LOADING, payload: false });
        }
    }
}

export const setReminderLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDERS_LOADING, payload: isLoading });
}

export const setReminderError = (message: string) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDER_ERROR, payload: message });
}

export const setReminderPage = (page: number) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDERS_PAGE, payload: page });
}

export const setRemindersFilter = (earlyThanNow: boolean) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDERS_FILTER, payload: earlyThanNow });
}

export const setRemindersSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDERS_SORTFIELD, payload: sortField });
}

export const setRemindersSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<ReminderAction>) =>
        dispatch({ type: ReminderActionTypes.SET_REMINDERS_SORT, payload: sort });
}