import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { RouteNames } from '../../routing';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { IAppForm } from '../../types/appForm';
import TemplatePreview from './TemplatePreview';
import { Box, Container, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckIcon from '@mui/icons-material/Check';
import GroupsIcon from '@mui/icons-material/Groups';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import PaletteIcon from '@mui/icons-material/Palette';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorMessage from '../../components/Messages/ErrorMessage';
import MuiButton from '../../components/Button/MuiButton';
import PageHeader from '../../components/PageHeader/PageHeader';
import Spinner from '../../components/Spinner/Spinner';
import AdventagesChapter from './AdventagesChapter';
import HomeTemplateCard from './HomeTemplateCard';
import HomePriceCard from './HomePriceCard';

export default function HomePage(): JSX.Element {
    const { pricepackageSearchResult, sortField, error, loading } = useTypedSelector(state => state.pricepackage);
    const { formSearchResult, filters, sortField: templateSortField, loading: templateLoading, error: templateError } = useTypedSelector(state => state.templateForm);
    const { getPricepackages, getTemplateForms } = useActions();
    const [template, setTemplate] = useState<IAppForm | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (pricepackageSearchResult.itemList.length === 0) getPricepackages(pricepackageSearchResult.pageSize, pricepackageSearchResult.currentPageNumber, sortField, pricepackageSearchResult.order);
        if (formSearchResult.itemList.length === 0) getTemplateForms(formSearchResult.pageSize, formSearchResult.currentPageNumber, filters.searchText, templateSortField, formSearchResult.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container maxWidth="lg">
            <Helmet>
                <title>DoYourForm.com - Forms for remote work and control with customers</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Why DoYourForm?" id="home" />
            <Grid container alignItems="center">
                <Grid item xs={12} md={6} sx={{ padding: '0 40px' }}>
                    <p className='pt2' style={{ textAlign: 'center' }}>Forms for working with customers, business units and personnel.</p>
                    <p style={{ textAlign: 'center' }}>
                        Create questions of different types, add photos, answer options and your comments.
                    </p>
                </Grid>
                <Grid item xs={12} md={6} textAlign='center' sx={{ padding: '0 40px' }}>
                    <Box sx={{ width: '100%' }}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <MailOutlineIcon sx={{ fill: '#5F7C78' }} />
                                </ListItemIcon>
                                <ListItemText primary="Share your forms in email and messengers" />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <SettingsIcon sx={{ fill: '#5F7C78' }} />
                                </ListItemIcon>
                                <ListItemText primary="Automation of business process control by setting reminders" />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemIcon>
                                    <PaletteIcon sx={{ fill: '#5F7C78' }} />
                                </ListItemIcon>
                                <ListItemText primary="Promote your brand by adding a logo and styling the form with corporate colors" />
                            </ListItem>
                        </List>
                    </Box>
                </Grid>
            </Grid>
            <PageHeader title="Features" />
            <Grid container alignItems="flex-start" sx={{ background: 'var(--lightgreywhite)', padding: '20px 0' }}>
                <Grid item xs={12} md={4} sx={{ padding: '0 20px', mb: "30px" }}>
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                        <Grid item>
                            <ApartmentIcon fontSize='large' sx={{ fill: '#5F7C78', mr: "10px" }} />
                        </Grid>
                        <Grid item>
                            <span className='pt2' style={{ textAlign: 'center' }}>Business Units</span>
                        </Grid>
                    </Grid>
                    <p style={{ textAlign: 'center' }}>
                        Control of real estate, vehicles, infrastructure and equipment
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="For landlords and tenants - receive regular inspections about property state and counters." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="For carriers - receive a daily inspection of the car with photo confirmation and geolocation." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="For technical services and security - create forms for visual control of infrastructure objects." />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} sx={{ padding: '0 20px', mb: "30px" }}>
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                        <Grid item>
                            <NetworkCheckIcon fontSize='large' sx={{ fill: '#5F7C78', mr: "10px" }} />
                        </Grid>
                        <Grid item>
                            <span className='pt2' style={{ textAlign: 'center' }}>Processes</span>
                        </Grid>
                    </Grid>
                    <p style={{ textAlign: 'center' }}>
                        Tracking the status of tasks and jobs
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Get orders in forms with personalized customer information about quantity, delivery and payment options." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Registration forms for visitors, entrants, ticket sales, etc." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Receive confirmation in forms from staff about the stages of task performance." />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={4} sx={{ padding: '0 20px', mb: "30px" }}>
                    <Grid container direction='row' justifyContent='center' alignItems='center'>
                        <Grid item>
                            <GroupsIcon fontSize='large' sx={{ fill: '#5F7C78', mr: "10px" }} />
                        </Grid>
                        <Grid item>
                            <span className='pt2' style={{ textAlign: 'center' }}>People</span>
                        </Grid>
                    </Grid>
                    <p style={{ textAlign: 'center' }}>
                        Survey and data collection forms
                    </p>
                    <List>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Post jobs in forms and receive candidate data before interviews." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Create online tests for your training programs, check performance and assign grades on your smartphone." />
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemIcon>
                                <CheckIcon sx={{ fill: '#5F7C78' }} />
                            </ListItemIcon>
                            <ListItemText primary="Customer Service - survey customers about satisfaction with the quality of your service." />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <PageHeader title="Adventages" />
            <AdventagesChapter />
            <PageHeader title="Templates Gallery" id="templates" />
            <Grid container alignItems="flex-start">
                {formSearchResult.itemList.slice(0, 6).map(form =>
                    <HomeTemplateCard key={form.id} form={form} onPreview={form => setTemplate(form)} />
                )}
                {
                    templateLoading ? <Container><Spinner /></Container> :
                        templateError ? <ErrorMessage appearance="regular">{templateError}</ErrorMessage> : null
                }
                <Container sx={{ textAlign: "center", margin: "20px 0" }}>
                    <MuiButton variant='outlined' onClickHandler={() => navigate(RouteNames.LOGIN)}>
                        Sign In to start
                    </MuiButton>
                </Container>
            </Grid>
            <PageHeader title="Prices" id="prices" />
            <Grid container>
                {
                    pricepackageSearchResult.itemList.map(pricepackage =>
                        <Grid item key={pricepackage.id} xs={12} md={4} sx={{ padding: "0 !important", marginBottom: "15px" }}
                            display="flex" justifyContent="center" alignItems="center">
                            <HomePriceCard pricepackage={pricepackage} />
                        </Grid>)
                }
                {
                    loading ? <Container><Spinner /></Container> :
                        error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> : null
                }
            </Grid>
            <PageHeader title="Get Started" text="Free price plan & Access to dashboard" />
            <div style={{ textAlign: 'center' }}>
                <MuiButton variant='contained' onClickHandler={() => navigate(RouteNames.REGISTER)}>Sign Up</MuiButton>
            </div>
            {template && <TemplatePreview form={template} onClose={() => setTemplate(null)} />}
        </Container>
    );
};
