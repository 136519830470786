import { AutocompleteFormSelectProps } from './types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutocompleteFormSelect({ forms, onChange }: AutocompleteFormSelectProps) {

    const onChangeHandler = (event, values) => {
        onChange(values)
    }

    return (
        <Autocomplete
            freeSolo
            disableClearable={false}
            options={forms.map((option) => option.title)}
            onChange={onChangeHandler}
            renderInput={(params) => (
                <TextField
                    sx={{ minWidth: 250 }}
                    fullWidth
                    {...params}
                    label="Choose a form"
                    InputProps={{ ...params.InputProps, type: 'search' }}
                />
            )}
        />
    );
}

