import { Button, Grid } from '@mui/material'
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import StepsHeader from './components/StepsHeader';

export default function Step3Signature() {
    const { step, user } = useTypedSelector(state => state.formFill)
    const { setFormFillStep } = useActions();

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor={user.brandColor1} isPhoneDialogActive={true} />
            </Grid>

            <Grid item>
                Step 3 Signature
            </Grid>
            <Grid item>
                <Button onClick={() => setFormFillStep(step + 1)}>Continue</Button>
            </Grid>
        </Grid>
    )
}
