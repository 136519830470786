import { IQuestion, InputType } from "../../../types/question";

export function CheckIsFormFilled(questions: IQuestion[]): boolean {
    let requiredQuestionsFilled = false;
    const requiredQuestions = questions.filter(question => question.isRequired === true);
    if (requiredQuestions.length === 0) return true;
    for (let i = 0; i < requiredQuestions.length; i++) {
        const question = requiredQuestions[i];
        switch (question.inputType) {
            case InputType.Checkbox:
                requiredQuestionsFilled = question.options.filter(option => option.checked === true).length === 0 ? false : true;
                break;
            case InputType.Radio:
                requiredQuestionsFilled = question.options.filter(option => option.checked === true).length === 0 ? false : true;
                break;
            default://InputType.Text || InputType.Number || || InputType.Date || || InputType.Time
                requiredQuestionsFilled = question.options[0].value.length === 0 ? false : true;
                break;
        }
        if (!requiredQuestionsFilled) return false;
    }
    return requiredQuestionsFilled;
}