import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PhoneDialogProps } from '../types';
import { DialogContentText } from '@mui/material';
import MuiButton from '../../../components/Button/MuiButton';

export const PhoneDialog = ({ isOpen, firstName, lastName, phoneNumber, onClose }: PhoneDialogProps): JSX.Element => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={onClose}
            />
            <DialogTitle id="alert-dialog-title" >
                Phone Call
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Would you like to call ${firstName + ' ' + lastName}? `}
                    Call: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: "20px" }}>
                <MuiButton variant='outlined' onClickHandler={onClose}>Cancel</MuiButton>
            </DialogActions>
        </Dialog>
    );
};
