import { Helmet } from "react-helmet";
import { ErrorPageProps } from './types';
import { Container, Grid, Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader/PageHeader';
import '../../index.css';

export default function ErrorPage({ status = '404', message = 'Page Not Found' }: ErrorPageProps): JSX.Element {

    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Page not found</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Oops! Something went wrong." />
            <Grid>
                <Typography component={'p'} sx={{ color: 'var(--red)', padding: '10px' }}>
                    {message}
                </Typography>
                <p className='text-14' style={{ padding: '10px', marginTop: '20px' }}>Error status: {status}</p>
            </Grid>
        </Container>
    )

};
