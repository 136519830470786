import { Avatar, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { SelectContactsFilterProps } from "../types";

export default function SelectContactsFilter({ contacts, label, onSelectChanged, value }: SelectContactsFilterProps): JSX.Element {
    let contactsToSelect = contacts.slice();
    contactsToSelect.unshift({ id: "", firstName: "All", lastName: "Contacts", phone: "", userId: "" })

    return (
        <FormControl sx={{ width: '250px' }}>
            <InputLabel>{label}</InputLabel>
            <Select name="contact"
                value={value}
                label={label}
                onChange={(event: SelectChangeEvent) => onSelectChanged(event.target.value)}
            >
                {contactsToSelect.map((contact) =>
                    <MenuItem key={contact.id} value={contact.id}>
                        <Grid container direction='row' justifyContent={'flex-start'} alignItems={'center'}>
                            <Grid item>
                                {
                                    contact.avatar ?
                                        <Avatar
                                            alt="Avatar"
                                            src={(process.env.NODE_ENV === "production" ?
                                                process.env.REACT_APP_BASE_API_URL_PROD :
                                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + contact.avatar}
                                            sx={{ mr: '10px', width: 36, height: 36 }}
                                        /> :
                                        <Avatar
                                            alt="Avatar"
                                            sx={{ mr: '10px', width: 36, height: 36, bgcolor: 'teal' }}
                                        >
                                            {contact.firstName.charAt(0) + contact.lastName.charAt(0)}
                                        </Avatar>
                                }
                            </Grid>
                            <Grid item>
                                {contact.firstName + ' ' + contact.lastName}
                            </Grid>
                        </Grid>
                    </MenuItem>)}
            </Select>
        </FormControl>
    )
}