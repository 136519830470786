import axios from 'axios';
import { OrderType } from '../types/common/orderType';
import { ISearchResult } from '../types/common/searchResult';
import { IContact, SearchContact } from '../types/contact';

/**
 * Get list of Contacts
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param search<string> Search text for contact full name
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getContactsAxios(
    limit: number,
    page: number,
    search: string,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IContact>> {
    return (
        await axios.get(`/contact/get?limit=${limit}&page=${page}&search=${search}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get list of Contacts by user id 
 * @param page<number> Current page
 * @param userId<string> Search for userId
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getContactsByUserIdAxios(
    page: number,
    userId: string,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IContact>> {
    return (
        await axios.get(`/contact/getByUserId?page=${page}&userId=${userId}&sortField=${sortField}&order=${order}`)).data;
}

export async function getSearchContactsAxios(): Promise<SearchContact[]> {
    return (await axios.get(`/contact/getSearchContacts`)).data;
}

/**
 * Get Contact specified by identifier
 * @param id<string> Contact identifier
 */
export async function getContactByIdAxios(id: string): Promise<IContact> {
    return (await axios.get(`/contact/get/${id}`)).data;
}

/**
 * Creates a new Contact
 * @param contact<IContact> object of type IContact
 * @returns<IContact> Created Contact
 */
export async function createContactAxios(contact: IContact): Promise<IContact> {
    return (await axios.post("/contact/create", contact)).data;
}

/**
 * Updates the existing Contact
 * @param contact<IContact> Object of type IContact
 * @returns<IContact> Updated Contact object
 */
export async function updateContactAxios(contact: IContact): Promise<IContact> {
    return (await axios.put("/contact/update", contact)).data;
}

/**
 * Delete's the Contact specified by identifier
 * @param id<string> Identifier
 */
export async function deleteContactAxios(id: string): Promise<void> {
    return await axios.delete(`/contact/delete?id=${id}`);
}