import { Dispatch } from "redux";
import { getFormToFillOutAxios, updateFormAxios } from "../../api/form";
import { removeQuestionImageAxios } from "../../api/image";
import { updateQuestionAxios } from "../../api/question";
import { updateOptionAxios } from "../../api/option";
import { AppFormStatus, FormAction, FormActionTypes, IAppForm, SentByOption } from "../../types/appForm";
import { IOption, IQuestion } from "../../types/question";
import { IImage } from "../../types/image";

export const getFormFill = (id: string) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: null });
            const formToFill = await getFormToFillOutAxios(id);
            if (formToFill.form.status === AppFormStatus.Sent ||
                (formToFill.form.status === AppFormStatus.Done && formToFill.form.sentByOption === SentByOption.Link)) {
                dispatch({ type: FormActionTypes.SET_FORMFILL_STEP, payload: 1 });
            } else {//AppFormStatus.Done && Email
                dispatch({
                    type: FormActionTypes.SET_SHOW_RESULTS,
                    payload: true
                });
                dispatch({ type: FormActionTypes.SET_FORMFILL_STEP, payload: 4 });
            }
            dispatch({ type: FormActionTypes.GET_FORMFILL, payload: formToFill });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: error.message || "Unable to load the form." })
        } finally {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: false });
        }
    }
}

export const setFormFillError = (message: string) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: message });
}

export const setFormFillLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_DRAFT_FORM_LOADING, payload: isLoading });
}

export const setFormFillStep = (step: number) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_FORMFILL_STEP, payload: step });
}

export const setShowResults = (showResults: true) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SHOW_RESULTS, payload: showResults });
}

export const updateFormFill = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: null });
            dispatch({ type: FormActionTypes.UPDATE_FORMFILL, payload: await updateFormAxios(form) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: error.message || "Error while updating the form." })
        } finally {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: false });
        }
    }
}

export const updateFormFillQuestion = (question: IQuestion) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: null });
            dispatch({ type: FormActionTypes.UPDATE_FORMFILL_QUESTION, payload: await updateQuestionAxios(question) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: error.message || "Error while updating the question." })
        } finally {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: false });
        }
    }
}

export const updateFormFillOption = (option: IOption) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: null });
            dispatch({ type: FormActionTypes.UPDATE_FORMFILL_OPTION, payload: await updateOptionAxios(option) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: error.message || "Error while updating the option." })
        } finally {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: false });
        }
    }
}

export const uploadFormFillQuestionImages = (images: IImage[]) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.CREATE_FORMFILL_IMAGES, payload: images });
}

export const setGeoPosition = (geoposition: { latitude: number, longitude: number }) => {
    return async (dispatch: Dispatch<FormAction>) => dispatch({ type: FormActionTypes.SET_GEOPOSITION, payload: geoposition });
}

export const removeFormFillImage = (image: IImage) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: null });
            await removeQuestionImageAxios(image.id);
            dispatch({ type: FormActionTypes.REMOVE_FORMFILL_IMAGE, payload: image });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_FORMFILL_ERROR, payload: error.message || "Error while remove the image." })
        } finally {
            dispatch({ type: FormActionTypes.SET_FORMFILL_LOADING, payload: false });
        }
    }
}
