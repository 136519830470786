import { useState } from 'react';
import { isAppFileValid } from '../../../hooks/IsAppFileValid';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { AppFormStatus } from '../../../types/appForm';
import { IImage } from '../../../types/image';
import { IQuestion } from '../../../types/question';
import { ISnackBarMessageState } from '../../../types/common/snackBarMessageState';
import { UploadBlockProps } from '../types';
import { uploadQuestionImageAxios } from '../../../api/image';
import { Alert, Button, Grid, Snackbar } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import MyLocationIcon from '@mui/icons-material/MyLocation';

export default function UploadBlock({ question, isDisabled }: UploadBlockProps) {
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const { form, latitude, longitude, user } = useTypedSelector(state => state.formFill);
    const { removeFormFillImage, uploadFormFillQuestionImages } = useActions();
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const onSelectFiles = async (event: React.ChangeEvent<HTMLInputElement>, question: IQuestion) => {
        if (event.target.files.length === 0) return;
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => isAppFileValid(file) === true);
        if (validFiles.length === 0) {
            setSnackBarState({ message: "Choose only png or jpg files to upload.", severity: "warning" });
            return;
        }
        const filesSizeToUpload = files.reduce((total, file) => { return total + file.size }, 0)
        if (user.account.currentFileSize + filesSizeToUpload >= user.account.fileSizeLimit) {
            setSnackBarState({ message: "Files size limit exceeded. Please contact the form owner.", severity: "warning" });
            return;
        }
        try {
            setSnackBarState(null);
            setLoadingState(true)
            let uploadedImages: IImage[] = [];
            for (let index = 0; index < files.length; index++) {
                let imageToUpload: IImage = await uploadQuestionImageAxios(files[index], question.id, user.id, latitude, longitude);
                uploadedImages.push(imageToUpload);
            }
            uploadFormFillQuestionImages(uploadedImages)
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to upload", severity: "error" });
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <Grid item>
            <Button variant="outlined" component="label"
                startIcon={<CloudUploadIcon />}
                disabled={isDisabled} sx={{ textTransform: 'none', borderRadius: "16px" }}
            >
                Upload File
                {loadingState && <CircularProgress size={16} sx={{ marginLeft: "6px" }} />}
                <input type="file" hidden multiple onChange={event => onSelectFiles(event, question)} />
            </Button>
            {
                question.images.length > 0 &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" mt={2}
                >
                    {
                        question.images.map((image) =>
                            <Grid item key={image.id} sx={{ marginRight: "10px", borderRadius: "10px", padding: "5px", maxHeight: "60px", overflow: 'hidden' }}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" >
                                    <a href={image.fullPath} target="_blank" rel="noreferrer">
                                        <img src={image.fullPath} alt="" width={64} />
                                    </a>
                                    {
                                        form.status !== AppFormStatus.Done &&
                                        <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} onClick={() => removeFormFillImage(image)} />
                                    }
                                    {(form.status !== AppFormStatus.Done && image.latitude) && <span style={{ color: '#5F7C78' }}>|</span>}
                                    {
                                        image.latitude &&
                                        <a href={`https://maps.google.com/?q=${image.latitude},${image.longitude}`}
                                            target="_blank" rel="noreferrer">
                                            <MyLocationIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        </a>
                                    }
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            }
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Grid>
    )
}
