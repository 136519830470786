import axios from 'axios';
import { IImage } from '../types/image';

/**
 * Creates a new Image
 * @param picture<File> file to upload
 * @returns<IImage> Created Image
 */
export async function uploadImageAxios(picture: File): Promise<IImage> {
    let formData: FormData = new FormData();
    formData.append('formFile', picture);
    return (await axios.post<IImage>(`image/upload`, formData)).data;
}

/**
 * Creates a new Image
 * @param picture<File> file to upload
 * @returns<IImage> Created Image
 */
export async function uploadQuestionImageAxios(formFile: File, questionId: string, userId: string, latitude: number, longitude: number): Promise<IImage> {
    let formData: FormData = new FormData();
    formData.append('formFile', formFile);
    return (await axios.post<IImage>(`image/uploadquestionimage?questionId=${questionId}&userId=${userId}&latitude=${latitude || ""}&longitude=${longitude || ""}`,
        formData, { headers: { 'Content-Type': 'multipart/form-data' } })).data;
}

/**
 * Updates the existing Image
 * @param Image<IImage> Object of type IImage
 * @returns<IImage> Updated Image object
 */
export async function updateImageAxios(image: IImage): Promise<IImage> {
    return (await axios.put("/image/update", image)).data;
}

/**
 * Deletes the Image specified by unique filename for autherized users
 * @param fileName<string> file name
 */
export async function removeImageAxios(fileName: string): Promise<void> {
    return await axios.delete(`/image/delete?fileName=${fileName}`);
}

/**
 * Removes the Image specified by identifier from form question for anonimous users
 * @param fileName<string> file name
 */
export async function removeQuestionImageAxios(id: string): Promise<void> {
    return await axios.delete(`/image/remove?id=${id}`);
}