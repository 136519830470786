import { OrderType } from "./common/orderType";
import { ISearchResult } from "./common/searchResult";

export interface IReminder {
    id?: string;
    appFormId: string;
    period: ReminderPeriod;
    start: Date;
    next: Date;
    finish: Date;
    timezoneOffset: number;
}

export enum ReminderPeriod {
    "Daily",
    "Business days",
    "Weekly",
    "Monthly",
    "Yearly"
}

export interface ReminderFilters {
    earlyThanNow: boolean;
}

export interface ReminderState {
    reminderSearchResult: ISearchResult<IReminder>;
    filters: ReminderFilters;
    sortField: string;
    loading: boolean;
    error: null | string;
}

export enum ReminderActionTypes {
    GET_REMINDERS = "GET_REMINDERS",
    LOAD_MORE_REMINDERS = "LOAD_MORE_REMINDERS",
    SET_REMINDER_ERROR = "SET_REMINDER_ERROR",
    SET_REMINDERS_LOADING = "SET_REMINDERS_LOADING",
    SET_REMINDERS_PAGE = "SET_REMINDERS_PAGE",
    SET_REMINDERS_FILTER = "SET_REMINDERS_FILTER",
    SET_REMINDERS_SORTFIELD = "SET_REMINDERS_SORTFIELD",
    SET_REMINDERS_SORT = "SET_REMINDERS_SORT"
}

interface GetRemindersAction {
    type: ReminderActionTypes.GET_REMINDERS;
    payload: ISearchResult<IReminder>;
}

interface LoadMoreRemindersAction {
    type: ReminderActionTypes.LOAD_MORE_REMINDERS;
    payload: ISearchResult<IReminder>;
}

interface SetReminderErrorAction {
    type: ReminderActionTypes.SET_REMINDER_ERROR;
    payload: null | string;
}

interface SetReminderLoadingAction {
    type: ReminderActionTypes.SET_REMINDERS_LOADING;
    payload: boolean;
}

interface SetReminderPageAction {
    type: ReminderActionTypes.SET_REMINDERS_PAGE;
    payload: number;
}

interface SetReminderFilterAction {
    type: ReminderActionTypes.SET_REMINDERS_FILTER;
    payload: boolean;
}

interface SetReminderSortField {
    type: ReminderActionTypes.SET_REMINDERS_SORTFIELD;
    payload: string;
}

interface SetReminderSort {
    type: ReminderActionTypes.SET_REMINDERS_SORT;
    payload: OrderType;
}

export type ReminderAction = GetRemindersAction | LoadMoreRemindersAction | SetReminderErrorAction |
    SetReminderLoadingAction | SetReminderPageAction | SetReminderFilterAction | SetReminderSortField | SetReminderSort