import React, { useState } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { OrderType } from '../../../types/common/orderType';
import { SortFieldBarProps } from '../../../types/common/sortFieldBarProps';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function AdminReminderSortFieldBar({ fields }: SortFieldBarProps) {
    const { reminderSearchResult, sortField } = useTypedSelector(state => state.reminder);
    const { setReminderPage, setRemindersSortfield, setRemindersSort } = useActions();
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleClose = () => setAnchorElUser(null);

    const onSelectHandler = (field: string) => {
        if (field === sortField) {
            reminderSearchResult.order === OrderType.Ascending ? setRemindersSort(OrderType.Descending) : setRemindersSort(OrderType.Ascending)
        } else {
            setRemindersSortfield(field);
        }
        setReminderPage(1);
    }

    return (
        <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Tooltip title="Sort by">
                <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                    <SortOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-sortfield-bar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleClose}
            >
                {
                    fields.map(field =>
                        <MenuItem key={field} onClick={() => {
                            onSelectHandler(field)
                            handleClose();
                        }}>
                            {field}
                            &nbsp;
                            {
                                field === sortField ?
                                    reminderSearchResult.order === OrderType.Ascending ?
                                        <ArrowUpwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        :
                                        <ArrowDownwardIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                    : null
                            }
                        </MenuItem>
                    )
                }
            </Menu>
        </Box>
    )
}
