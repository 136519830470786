import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Avatar, Button, Container, Grid, Typography } from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import StepsHeader from './components/StepsHeader';

export default function Step1Welcome() {
    const { form, step, user } = useTypedSelector(state => state.formFill)
    const { setFormFillStep } = useActions();

    return (
        <Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{ width: '100%' }}>
                <StepsHeader brandColor={user.brandColor1} isPhoneDialogActive={true} />
            </Grid>
            {
                user.logo &&
                <Grid item sx={{ textAlign: 'center', margin: "30px 0 5px" }}>
                    <img
                        src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.logo}
                        alt="Company"
                        style={{ margin: '25px 0 12px', maxWidth: "80%" }}
                    />
                </Grid>
            }
            {
                user.avatar &&
                <Grid item sx={{ margin: "10px 0 5px" }}>
                    <Avatar
                        alt="Form owner"
                        src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.avatar}
                        sx={{ margin: '12px 0', width: 64, height: 64 }}
                    />
                </Grid>
            }
            <Grid item style={{ color: "#4b605c", margin: "30px 0 5px" }}>
                <i>{user.firstName} {user.lastName}</i>
            </Grid>
            <Grid item sx={{ width: '90%', textAlign: 'center' }}>
                <Container maxWidth="sm">
                    <p className='pt1' style={{ textAlign: 'center' }}>Welcomes to your</p>
                    <h3 style={{ lineHeight: 1.2, textAlign: 'center' }}>{form.title}</h3>
                </Container>
            </Grid>
            {
                form.logo &&
                <Grid item style={{ textAlign: 'center' }}>
                    <img
                        src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                        alt="Form"
                        width="90%"
                    />
                </Grid>
            }
            <Grid item>
                <Typography variant="body2" color="text.secondary" align="center">
                    <span>If you need help click</span>
                    {
                        user.phoneNumber ?
                            <PhoneEnabledOutlinedIcon sx={{ margin: '0 6px', fill: `${user.brandColor1}` }} /> :
                            <MailOutlineIcon sx={{ margin: '0 6px', fill: `${user ? user.brandColor1 : "#158f7d"}` }} />
                    }
                    <span>(top right) to contact me.</span>
                </Typography>
            </Grid>
            <Grid item sx={{ margin: '20px 0' }}>
                <Button
                    variant="contained"
                    style={{ textTransform: 'none', borderRadius: "16px", background: `${user.brandColor1}`, color: `${user.brandColor2}` }}
                    onClick={() => setFormFillStep(step + 1)}>
                    Get Started
                </Button>
            </Grid>
            <Grid item>
                <Typography variant="body2" color="text.secondary" align="center">
                    By clicking “Get Started” you agree to our <a href="https://doyourform.com/terms" target='_blank' rel="noreferrer">terms and conditions</a>.
                </Typography>
            </Grid>
        </Grid>
    )
}
