import { useState } from "react";
import { FillFormQuestionCardProps } from "../types";
import { InputType } from "../../../types/question";
import { clonedObject } from "../../../hooks/clonedObject";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { FormControl, FormHelperText, FormLabel, Grid, TextField } from "@mui/material";
import ImageWrapper from "../../../components/ImageWrapper/ImageWrapper";
import UploadBlock from "./UploadBlock";

export default function TextQuestionCard({ question }: FillFormQuestionCardProps) {
    const { showResults } = useTypedSelector(state => state.formFill)
    const { updateFormFillOption } = useActions();
    const [answer, setAnswer] = useState<string>(question.options[0].value);

    const isOptionValueValid = (value: string): boolean => {
        if (question.isRequired) return value.length > 0;
        return true;
    }

    const onChangeOption = async (value: string) => {
        setAnswer(value);
        let optionToUpdate = clonedObject(question.options[0]);
        optionToUpdate.value = value;
        updateFormFillOption(optionToUpdate);
    }

    return (
        <Grid container direction="column" gap={1} justifyContent="center" alignItems="flex-start">
            <FormControl
                sx={{ margin: "24px 0", width: "100%" }}
                required={question.isRequired}
                component="fieldset"
                error={!isOptionValueValid(answer)} // source: https://mui.com/material-ui/react-radio-button/#show-error
                variant="standard"
            >
                {
                    question.picture &&
                    <ImageWrapper source={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + question.picture} />
                }
                <FormLabel component="p">{question.text}</FormLabel>
                <TextField
                    type={question.inputType}
                    fullWidth
                    disabled={showResults}
                    margin="normal"
                    multiline={question.inputType === InputType.Text ? true : false}
                    rows={question.inputType === InputType.Text ? 2 : 1}
                    variant='outlined' style={{ height: 'none' }}
                    value={answer}
                    onChange={event => onChangeOption(event.target.value)}
                    error={!isOptionValueValid(answer)}
                    helperText={!isOptionValueValid(answer) && "Answer required."}
                />
                <FormHelperText>{!isOptionValueValid(answer) && "Type an answer"}</FormHelperText>
            </FormControl>
            {question.showUpload && <UploadBlock question={question} isDisabled={showResults} />}
            {
                showResults &&
                <TextField
                    label="Sender's comment"
                    fullWidth
                    disabled
                    margin="normal" multiline rows={2}
                    variant='outlined' style={{ height: 'none' }}
                    value={question.comment || ''}
                />
            }
        </Grid>
    )
}
