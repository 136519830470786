import axios from 'axios';
import { OrderType } from '../types/common/orderType';
import { ISearchResult } from '../types/common/searchResult';
import { AppFormStatus, FormFillDto, IAppForm, SearchForm, SendForm } from '../types/appForm';

/**
 * Get list of Forms
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param search<string> Search text for candidate full name
 * @param contactId<> Contact's identifier
 * @param status<> AppFormStatus
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getFormsAxios(
    limit: number,
    page: number,
    search: string,
    contactId: string,
    status: AppFormStatus,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IAppForm>> {
    return (
        await axios.get(`/appform/get?limit=${limit}&page=${page}&search=${search}&contactId=${contactId}&status=${status}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get list of Templates for public users 
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param search<string> Search text for candidate full name
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getTemplatesAxios(
    limit: number,
    page: number,
    search: string,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IAppForm>> {
    return (
        await axios.get(`/appform/gettemplates?limit=${limit}&page=${page}&search=${search}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get list of Forms
 * @param page<number> Current page
 * @param userId<string> Search for userId
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getFormsByUserIdAxios(
    page: number,
    userId: string,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IAppForm>> {
    return (
        await axios.get(`/appform/getByUserId?page=${page}&userId=${userId}&sortField=${sortField}&order=${order}`)).data;
}

export async function getSearchFormsAxios(status: AppFormStatus): Promise<SearchForm[]> {
    return (await axios.get(`/appform/getSearchForms?status=${status}`)).data;
}

/**
 * Gets files size of form (logo + questions pictures)
 * @param form<IAppForm> object of type IAppForm
 * @returns<number> files size
 */
export async function getImagesSizeAxios(form: IAppForm): Promise<number> {
    return (await axios.post("/appform/getimagessize", form)).data;
}

/**
 * Get Form to fill out specified by identifier
 * @param id<string> Form identifier
 */
export async function getFormToFillOutAxios(id: string): Promise<FormFillDto> {
    return (await axios.get(`/appform/getformtofill?id=${id}`)).data;
}

/**
 * Creates a new IAppForm
 * @param form<IAppForm> object of type IAppForm
 * @returns<IAppForm> Created IAppForm
 */
export async function createFormAxios(form: IAppForm): Promise<IAppForm> {
    return (await axios.post("/appform/create", form)).data;
}

/**
 * Creates a new IAppForm
 * @param form<IAppForm> Existing draft
 * @returns<IAppForm> created IAppForm (status Draft)
 */
export async function copyDraftFormAxios(form: IAppForm): Promise<IAppForm> {
    return (await axios.post("/appform/copydraft", form)).data;
}

/**
 * Creates a new IAppForm (Draft) based on Template
 * @param form<IAppForm> Existing template
 * @returns<IAppForm> Created IAppForm (Draft status)
 */
export async function copyTemplateFormAxios(form: IAppForm): Promise<IAppForm> {
    return (await axios.post("/appform/copytemplate", form)).data;
}

/**
 * @param id<string> AppForm identifier
 * @param comment<string> form's owner can add comment to whole form
 */
export async function updateFormCommentAxios(id: string, comment: string): Promise<void> {
    await axios.patch(`/appform/partialupdate/${id}`, [{ op: "replace", path: "/Comment", value: comment }]);
}

/**
 * @param id<string> AppForm identifier
 * @param isRead<boolean> AppForm's IsRead status
 */
export async function updateFormIsReadAxios(id: string, isRead: boolean): Promise<void> {
    await axios.patch(`/appform/partialupdate/${id}`, [{ op: "replace", path: "/IsRead", value: isRead }]);
}

/**
 * Delete's the Form specified by identifier
 * @param email<string> Contact's email
 * @param title<string> Form's title
 * @param formId<string> Form's identifier
 * @returns<IAppForm> Updated form
 */
export async function resendFormAxios(form: IAppForm): Promise<IAppForm> {
    return (await axios.post(`/appform/resendform`, form)).data;
}

/**
 * Creates a new Form
 * @param form<IForm> object of type IForm
 * @returns<IAppForm> Created form
 */
export async function sendFormAxios(sendForm: SendForm): Promise<SendForm> {
    return (await axios.post("/appform/send", sendForm)).data;
}

/**
 * Updates the existing Form
 * @param form<IForm> Object of type IForm
 * @returns<IForm> Updated Form object
 */
export async function updateFormAxios(form: IAppForm): Promise<IAppForm> {
    return (await axios.put("/appform/update", form)).data;
}

/**
 * Delete's the Form specified by identifier
 * @param id<string> Identifier
 */
export async function removeFormAxios(id: string): Promise<void> {
    return await axios.delete(`/appform/delete/${id}`);
}