import { useState } from 'react';
import { clonedObject } from '../../../../hooks/clonedObject';
import { useActions } from '../../../../hooks/useActions';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { DraftFormCardProps } from '../types';
import { IAppForm, SentByOption } from '../../../../types/appForm';
import { IReminder, ReminderPeriod } from '../../../../types/reminder';
import { ISnackBarMessageState } from '../../../../types/common/snackBarMessageState';
import { copyDraftFormAxios, getImagesSizeAxios, removeFormAxios } from '../../../../api/form';
import { updateAccountAxios } from '../../../../api/account';
import { Alert, Box, CircularProgress, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import AppDeleteConfirmDialog from '../../../../components/AppDeleteConfirmDialog/AppDeleteConfirmDialog';
import ContactsMultiSelect from './ContactsMultiSelect';
import ReminderForm from './ReminderForm';
import ShareLink from './ShareLink';
import StyledEditIcon from '../../../../components/StyledIcons/StyledEditIcon';
import StyledDeleteIcon from '../../../../components/StyledIcons/StyledDeleteIcon';

export default function DraftFormCard({ form, onEdit }: DraftFormCardProps) {
    const { auth } = useTypedSelector(state => state.auth);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [reminder, setReminder] = useState<IReminder | null>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
    const { createDraftForm, removeDraftForm, updateAuthUser } = useActions();
    const [formToSend, setFormToSend] = useState<IAppForm>(null);
    const [shareLinkState, setShareLinkState] = useState<string | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
    const handleClose = () => setAnchorElUser(null);

    const сloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    const onSendForm = () => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        if (form.questions.length === 0) {
            setSnackBarState({ message: "Form doesn't have any questions. Click Edit button and create at least one.", severity: "warning" });
            return;
        }
        setFormToSend(form);
    }

    const onShareLink = () => {
        if (form.questions.length === 0) {
            setSnackBarState({ message: "Form doesn't have any questions. Click Edit button and create at least one.", severity: "warning" });
            return;
        }
        setShareLinkState((process.env.NODE_ENV === "production" ?
            process.env.REACT_APP_ROOT_PROD :
            process.env.REACT_APP_ROOT_DEV) + `/forms/f/${form.id}`)
    }

    const onCopyHandler = async () => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        try {
            setLoading(true);
            const filesSizeToBeCopied = await getImagesSizeAxios(form);
            if (auth.user.account.currentFileSize + filesSizeToBeCopied > auth.user.account.fileSizeLimit) {
                setSnackBarState({ message: "Your files size limit doesn't allow to copy the form.", severity: "warning" });
                return;
            }
            const copiedDraft = await copyDraftFormAxios(form);
            createDraftForm(copiedDraft);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize += filesSizeToBeCopied;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
            setSnackBarState({ message: "Form created.", severity: "success" });
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to create the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onDeleteDraftForm = async () => {
        try {
            setLoading(true);
            setSnackBarState(null);
            const filesSizeToBeDeleted = await getImagesSizeAxios(form);
            await removeFormAxios(form.id);
            removeDraftForm(form.id);
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account.currentFileSize -= filesSizeToBeDeleted;
            if (userToUpdate.account.currentFileSize < 0) userToUpdate.account.currentFileSize = 0;
            if (form.reminder) userToUpdate.account.currentReminders -= 1;
            userToUpdate.account = await updateAccountAxios(userToUpdate.account);
            updateAuthUser(userToUpdate);
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to remove the form.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onCreateReminder = () => {
        if (auth.user.account.currentReminders >= auth.user.account.remindersLimit) {
            setSnackBarState({ message: "You have used the Reminders limit.", severity: "warning" });
            return;
        }
        const today = new Date();
        const reminder: IReminder = {
            appFormId: form.id,
            period: ReminderPeriod.Daily,
            start: new Date(today.setTime(today.getTime() + 60 * 1000)),
            next: new Date(today.setTime(today.getTime() + 60 * 1000)),
            finish: new Date(today.setDate(today.getDate() + 30)),
            timezoneOffset: today.getTimezoneOffset()
        }
        setReminder(reminder);
    }

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                {
                    form.logo ?
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            width="32px"
                            alt=''
                        /> :
                        <ImageOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
                }
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, margin: "0 10px" }}>
                <span>{form.title}</span>
                <span className='text-14'>{form.contact ? form.contact.firstName + ' ' + form.contact.lastName : 'Not assigned'}</span>
                <span className='text-12'>Send by {SentByOption[form.sentByOption]}</span>
                <span className='text-12'>Created at {(new Date(form.createdAt.toString())).toLocaleDateString()}</span>
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "210px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    {loading && <CircularProgress size="1rem" sx={{ m: '4px 10px 0 0' }} />}
                    {
                        form.sentByOption === SentByOption.Email ?
                            <Tooltip title='Send' placement="top">
                                <EmailOutlinedIcon
                                    sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                    onClick={onSendForm}
                                />
                            </Tooltip> :
                            <Tooltip title='Link' placement="top">
                                <LinkOutlinedIcon
                                    sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                    onClick={onShareLink}
                                />
                            </Tooltip>
                    }
                    <Divider orientation="vertical" flexItem />
                    <Tooltip title='Copy' placement="top">
                        <ContentCopyIcon
                            sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                            onClick={onCopyHandler} />
                    </Tooltip>
                    <Divider orientation="vertical" flexItem />
                    {
                        form.contact &&
                        <div style={{ display: "flex" }}>
                            <div style={{ margin: '0 5px' }}>
                                <Tooltip title={form.reminder ? 'Reminder' : 'Set Reminder'} placement="top">
                                    {
                                        form.reminder ?
                                            <ScheduleSendOutlinedIcon
                                                sx={{ cursor: 'pointer', margin: '0 5px', fill: 'green' }}
                                                onClick={() => setReminder(form.reminder)}
                                            /> :
                                            <ScheduleSendOutlinedIcon
                                                sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                                onClick={onCreateReminder}
                                            />
                                    }
                                </Tooltip>
                            </div>
                            <Divider orientation="vertical" flexItem />
                        </div>
                    }
                    <StyledEditIcon tooltipTitle="Edit" onEdit={() => onEdit(form)} />
                    <Divider orientation="vertical" flexItem />
                    <StyledDeleteIcon tooltipTitle="Remove" onDelete={() => setConfirmDialogOpen(true)} />
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Actions">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        {
                            form.sentByOption === SentByOption.Email ?
                                <MenuItem onClick={handleClose}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setFormToSend(form)}>
                                        <EmailOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        <span>Send</span>
                                    </Grid>
                                </MenuItem> :
                                <MenuItem onClick={handleClose}>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px"
                                        onClick={() => setShareLinkState((process.env.NODE_ENV === "production" ?
                                            process.env.REACT_APP_ROOT_PROD :
                                            process.env.REACT_APP_ROOT_DEV) + `/forms/f/${form.id}`)}
                                    >
                                        <LinkOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                        <span>Link</span>
                                    </Grid>
                                </MenuItem>
                        }
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={onCopyHandler}>
                                <ContentCopyIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Copy</span>
                            </Grid>
                        </MenuItem>
                        {
                            form.contact &&
                            <MenuItem onClick={handleClose}>
                                {
                                    form.reminder ?
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setReminder(form.reminder)}>
                                            <ScheduleSendOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: 'green' }} />
                                            <span>Reminder</span>
                                        </Grid>
                                        :
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={onCreateReminder}>
                                            <ScheduleSendOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                            <span>Set Reminder</span>
                                        </Grid>
                                }
                            </MenuItem>
                        }
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => onEdit(form)}>
                                <EditIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Edit</span>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px" onClick={() => setConfirmDialogOpen(true)}>
                                <DeleteIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Remove</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            {confirmDialogOpen && <AppDeleteConfirmDialog onCancel={() => setConfirmDialogOpen(false)} onDelete={onDeleteDraftForm} />}
            {formToSend && <ContactsMultiSelect form={formToSend} onClose={() => setFormToSend(null)} />}
            {shareLinkState && <ShareLink link={shareLinkState} onClose={() => setShareLinkState(null)} />}
            {reminder && <ReminderForm reminder={reminder} closeForm={() => setReminder(null)} />}
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={сloseSnackbar}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </div>
    )
}