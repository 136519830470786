import { Dispatch } from "redux";
import { getFormsAxios, getSearchFormsAxios } from "../../api/form";
import { OrderType } from "../../types/common/orderType";
import { IAppForm, FormAction, FormActionTypes, AppFormStatus, SendForm } from "../../types/appForm";

export const getSendForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_SEND_FORM_FILTER_STATUS, payload: AppFormStatus.Sent })
            dispatch({
                type: FormActionTypes.GET_SEND_FORMS, payload:
                    await getFormsAxios(limit, page,
                        search || "",
                        contactId || "",
                        AppFormStatus.Sent,
                        sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_LOADING, payload: false });
        }
    }
}

export const loadMoreSendForms = (limit: number, page: number, search: string, contactId: string, sortField: string, order: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_LOADING, payload: true });
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: null });
            dispatch({
                type: FormActionTypes.LOAD_MORE_SEND_FORMS, payload:
                    await getFormsAxios(limit, page,
                        search || "", contactId || "",
                        AppFormStatus.Sent, sortField, order)
            });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: error.message || "Error of loading forms." })
        } finally {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_LOADING, payload: false });
        }
    }
}

export const getSearchSendForms = (status: AppFormStatus) => {
    return async (dispatch: Dispatch<FormAction>) => {
        try {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: null });
            dispatch({ type: FormActionTypes.SET_SEARCH_SEND_FORMS, payload: await getSearchFormsAxios(status) });
        } catch (error) {
            dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: error.message || "Error of loading search forms." })
        }
    }
}

export const setSendFormError = (message: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_ERROR, payload: message });
}

export const setSendFormLoading = (isLoading: boolean) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_LOADING, payload: isLoading });
}

export const setSendFormPage = (page: number) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_PAGE, payload: page });
}

export const setSendFormSearchCriteria = (search: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_FILTER, payload: search });
}

export const setSendFormSortfield = (sortField: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_SORTFIELD, payload: sortField });
}

export const setSendFormSort = (sort: OrderType) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_SORT, payload: sort });
}

export const setSendFormContactFilter = (contactId: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SET_SEND_FORM_FILTER_CONTACT, payload: contactId });
}

export const sendForm = (sendForm: SendForm) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.SEND_FORM, payload: sendForm });
}

export const resendForm = (form: IAppForm) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.UPDATE_SEND_FORM, payload: form });
}

export const removeSendForm = (id: string) => {
    return async (dispatch: Dispatch<FormAction>) =>
        dispatch({ type: FormActionTypes.REMOVE_SEND_FORM, payload: id });
}