import axios from 'axios';
import { ISearchResult } from '../types/common/searchResult';
import { IUser, UserFilters } from '../types/user';
import { OrderType } from '../types/common/orderType';

/**
 * Get users list
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param search<string> Search text for candadate full name
 */
export async function getUsersAxios(limit: number, page: number, filters: UserFilters, sortField: string, order: OrderType): Promise<ISearchResult<IUser>> {
    return (await axios.get(`/user/get?limit=${limit}&page=${page}&search=${filters.searchText}&pricepackageType=${filters.pricePackageType}&accountStatus=${filters.accountStatus}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get user by id
 * @param id<number> Identifier
 * @returns<IUser> user object
 */
export async function getUserByIdAxios(id: string): Promise<IUser> {
    return (await axios.get(`/user/get/${id}`)).data;
}

/**
 * @param id<string> User identifier
 * @param confirmed<boolean> User's emailConfirmed status
 */
export async function updateUserEmailConfirmedStatusAxios(id: string, confirmed: boolean): Promise<void> {
    await axios.patch(`/user/partialuserupdate/${id}`, [{ op: "replace", path: "/emailConfirmed", value: confirmed }]);
}

/**
 * Updates the existing user
 * @param user<IUser> Object of type IUser
 * @returns<IUser> Updated user object
 */
export async function updateUserAxios(user: IUser): Promise<IUser> {
    return (await axios.post("/user/update", user)).data;
}

/**
 * Delete's the User specified by identifier
 * @param id<string> Identifier
 */
export async function removeUserAxios(id: string): Promise<void> {
    return await axios.delete(`/account/delete/${id}`);
}
