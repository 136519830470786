import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { RouteNames } from '../../../routing';
import { clonedObject } from '../../../hooks/clonedObject';
import { dateDiffInDays } from '../../../hooks/dateDiffInDays';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { PricePackageType } from '../../../types/pricepackage';
import { IAppForm } from '../../../types/appForm';
import { refreshRegisteredUserAccountAxios } from '../../../api/account';
import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import ErrorMessage from '../../../components/Messages/ErrorMessage';
import { IntersectionObserverComponent } from '../../../components/IntersectionObserver/IntersectionObserverComponent';
import MuiButton from '../../../components/Button/MuiButton';
import PreviewForm from '../../../components/PreviewForm/PreviewForm';
import Spinner from '../../../components/Spinner/Spinner';
import TemplateCard from './TemplateCard';
import TemplateSortFieldBar from './TemplateSortFieldBar';
import "./styles.css";

export default function Dashboard() {
    const { formSearchResult, filters, sortField, loading, error } = useTypedSelector(state => state.templateForm);
    const { auth, loading: authLoading, error: authError } = useTypedSelector(state => state.auth);
    const { getTemplateForms, loadMoreTemplateForms, setTemplateFormPage, setAuthLoading, setAuthError, updateAuthUser } = useActions();
    const [showAccountInfo, setShowAccountInfo] = useState<boolean>(true);
    const [previewForm, setPreviewForm] = useState<IAppForm | null>(null);
    const navigate = useNavigate();
    const userAccount = auth.user.account;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (formSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && formSearchResult.currentPageNumber * formSearchResult.pageSize <= formSearchResult.totalItemCount) {
                loadMoreTemplateForms(formSearchResult.pageSize, formSearchResult.currentPageNumber + 1, filters.searchText, filters.contactId, sortField, formSearchResult.order);
                setTemplateFormPage(formSearchResult.currentPageNumber + 1);
            }
        });
    };

    useEffect(() => {
        if (formSearchResult.itemList.length === 0)
            getTemplateForms(formSearchResult.pageSize, formSearchResult.currentPageNumber, filters.searchText, sortField, formSearchResult.order);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRefresh = async () => {
        try {
            setAuthLoading(true);
            setAuthError(null);
            const accountToUpdate = await refreshRegisteredUserAccountAxios();
            let userToUpdate = clonedObject(auth.user);
            userToUpdate.account = accountToUpdate;
            updateAuthUser(userToUpdate);
        } catch (error) {
            setAuthError(error.message || 'Please refresh the page and try again.');
        } finally {
            setAuthLoading(false);
        }
    }

    return (
        <>
            {
                previewForm ?
                    <PreviewForm form={previewForm} user={auth.user} closeForm={() => setPreviewForm(null)} /> :
                    <>
                        <Helmet>
                            <title>DoYourForm.com - Dashboard</title>
                            <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
                        </Helmet>
                        {/* Price plan info */}
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Paper elevation={6} className='dahboard-card' sx={{ overflow: 'hidden' }}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6" color="var(--lightGrey)" noWrap fontWeight={500} sx={{ flexGrow: 1 }}  >
                                        {PricePackageType[auth.user.account.pricePackageType] + " account"}
                                    </Typography>
                                    <p className='text-12' style={{ color: "var(--blue)", cursor: "pointer" }}
                                        onClick={() => setShowAccountInfo(!showAccountInfo)}
                                    >
                                        {showAccountInfo ? "Hide" : "Show"}
                                    </p>
                                </Grid>
                                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ display: !showAccountInfo && "none" }}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            {(userAccount.currentFileSize / 1000000).toFixed(1) + " Mb"}
                                        </p>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            ({Math.round(userAccount.fileSizeLimit / 1000000) + " Mb"})
                                        </p>
                                        <p style={{ textAlign: 'center' }}>Files space</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <p className='pt1'
                                            style={{
                                                color: userAccount.currentContacts >= userAccount.contactsLimit ? 'var(--red)' : 'var(--blue)',
                                                textAlign: 'center',
                                                margin: "16px 0"
                                            }}
                                        >
                                            {userAccount.currentContacts}
                                        </p>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            ({userAccount ? userAccount.contactsLimit : 0})
                                        </p>
                                        <p style={{ textAlign: 'center' }}>Contacts</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <p className='pt1'
                                            style={{
                                                color: userAccount.currentReminders >= userAccount.remindersLimit ? 'var(--red)' : 'var(--blue)',
                                                textAlign: 'center',
                                                margin: "16px 0"
                                            }}
                                        >
                                            {userAccount ? userAccount.currentReminders : 0}
                                        </p>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            ({userAccount ? userAccount.remindersLimit : 0})
                                        </p>
                                        <p style={{ textAlign: 'center' }}>Reminders</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            {
                                                dateDiffInDays(
                                                    new Date(),
                                                    new Date(userAccount?.finishDate.toString().includes("Z") ?
                                                        new Date(userAccount?.finishDate.toString()) :
                                                        new Date(userAccount?.finishDate.toString() + 'Z')))
                                            }
                                        </p>
                                        <p className='pt1' style={{ color: 'var(--blue)', textAlign: 'center', margin: "16px 0" }} >
                                            ({userAccount ? (new Date(userAccount.finishDate.toString())).toLocaleDateString() : ""})
                                        </p>
                                        <p style={{ textAlign: 'center' }}>Days to completion</p>
                                    </Grid>
                                </Grid>
                                <Grid gap='15px' container direction="row" justifyContent="center" alignItems="center" sx={{ display: !showAccountInfo && "none" }}>
                                    <p style={{ margin: "20px 0 30px 0" }}>
                                        <MuiButton variant='outlined' onClickHandler={() => navigate(RouteNames.INVOICES)}>Upgrade</MuiButton>
                                    </p>
                                    <p style={{ margin: "20px 0 30px 0" }}>
                                        <MuiButton variant='outlined' color='success'
                                            onClickHandler={onRefresh}>
                                            {authLoading && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Refresh
                                        </MuiButton>
                                    </p>
                                </Grid>
                                {
                                    authError &&
                                    <div style={{ marginBottom: "30px" }}>
                                        <ErrorMessage appearance="small">{authError}</ErrorMessage>
                                    </div>
                                }
                            </Paper>
                        </Grid>
                        {/* Templates */}
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Paper elevation={6} className='dahboard-card' sx={{ overflow: 'hidden' }}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography variant="h6" color="var(--lightGrey)" noWrap fontWeight={500} sx={{ flexGrow: 1 }}  >
                                        Templates
                                    </Typography>
                                    <TemplateSortFieldBar fields={["Title"]} />
                                </Grid>
                                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                                    {
                                        formSearchResult.itemList.length > 0 ?
                                            formSearchResult.itemList.map(form =>
                                                <TemplateCard key={form.id} form={form} onPreview={form => setPreviewForm(form)} />
                                            ) :
                                            loading ? <div style={{ textAlign: "center", width: "100%" }}><Spinner /></div> :
                                                error ? <ErrorMessage appearance="regular">{error}</ErrorMessage> : <p style={{ padding: "0 15px" }}>No templates</p>
                                    }
                                </Grid>
                                <IntersectionObserverComponent onIntersection={handleIntersection} />
                                {(formSearchResult.itemList.length > 0 && loading) && <Spinner />}
                            </Paper>
                        </Grid>
                    </>
            }
        </>
    )
}
