import { Link } from 'react-router-dom';
import { RouteNames } from '../../routing';
import { Divider, Grid } from '@mui/material';

export default function Footer(): JSX.Element {
    return (
        <Grid container className='footer' direction="row" justifyContent="space-around" alignItems="center">
            <Grid item>
                <Grid container direction="row" gap="10px" justifyContent="flex-start" alignItems="center">
                    <Link to={RouteNames.HOME} className="pt3" style={{ textDecoration: 'none' }}>DoYourForm</Link>
                    <Divider orientation="vertical" flexItem />
                    <Link to={RouteNames.TERMS_CONDITIONS} style={{ textDecoration: 'none', color: "var(--darkGrey)" }}>Conditions</Link>
                    <Divider orientation="vertical" flexItem />
                    <Link to={RouteNames.PRIVACY_POLICY} style={{ textDecoration: 'none', color: "var(--darkGrey)" }}>Privacy Policy</Link>
                </Grid>
            </Grid>
            <Grid item>
                <p className='text-12'>Copyright © {new Date().getFullYear()} DoYourForm, inc. All Rights Reserved.</p>
            </Grid>
        </Grid>
    )
};
