import { OrderType } from "../../types/common/orderType";
import { ReminderAction, ReminderActionTypes, ReminderState } from "../../types/reminder";

const initialState: ReminderState = {
    reminderSearchResult: {
        itemList: [],
        currentPageNumber: 1,
        order: OrderType.Ascending,
        pageCount: 0,
        pageSize: 10,
        searchCriteria: "",
        totalItemCount: 0
    },
    filters: { earlyThanNow: false },
    sortField: "Next",
    loading: true,
    error: null
}

export const reminderReducer = (state: ReminderState = initialState, action: ReminderAction): ReminderState => {
    switch (action.type) {
        case ReminderActionTypes.GET_REMINDERS:
            return { ...state, reminderSearchResult: action.payload };
        case ReminderActionTypes.LOAD_MORE_REMINDERS:
            return {
                ...state,
                reminderSearchResult: {
                    ...action.payload,
                    itemList: state.reminderSearchResult.itemList.concat(action.payload.itemList)
                },
            };
        case ReminderActionTypes.SET_REMINDER_ERROR:
            return { ...state, error: action.payload };
        case ReminderActionTypes.SET_REMINDERS_LOADING:
            return { ...state, loading: action.payload };
        case ReminderActionTypes.SET_REMINDERS_PAGE:
            return { ...state, reminderSearchResult: { ...state.reminderSearchResult, currentPageNumber: action.payload } };
        case ReminderActionTypes.SET_REMINDERS_FILTER:
            return { ...state, filters: { ...state.filters, earlyThanNow: action.payload } };
        case ReminderActionTypes.SET_REMINDERS_SORTFIELD:
            return { ...state, sortField: action.payload };
        case ReminderActionTypes.SET_REMINDERS_SORT:
            return { ...state, reminderSearchResult: { ...state.reminderSearchResult, order: action.payload } };
        default: return state;
    }
}
