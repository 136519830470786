import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { AccountStatus } from '../../../types/account';
import { PricePackageType } from '../../../types/pricepackage';
import { IUser } from '../../../types/user';
import { Container, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import AdminUserSortFieldBar from './AdminUserSortFieldBar';
import Spinner from '../../../components/Spinner/Spinner';
import ErrorMessage from '../../../components/Messages/ErrorMessage';
import { IntersectionObserverComponent } from '../../../components/IntersectionObserver/IntersectionObserverComponent';
import AccountForm from './AccountForm';
import ManageUser from './ManageUser';
import UserCard from './UserCard';

export default function AdminUsersPage(): JSX.Element {
    const { userSearchResult, filters, loading, error, sortField } = useTypedSelector(state => state.user);
    const { getUsers, loadMoreUsers, setUserFilters, setUserPage } = useActions();
    const [user, setUser] = useState<IUser | null>(null);
    const [userAccount, setUserAccount] = useState<IUser | null>(null);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        if (userSearchResult.totalItemCount === 0) return;
        entries.forEach((entry) => {
            if (entry.isIntersecting && userSearchResult.currentPageNumber * userSearchResult.pageSize <= userSearchResult.totalItemCount) {
                loadMoreUsers(userSearchResult.pageSize, userSearchResult.currentPageNumber + 1, filters, sortField, userSearchResult.order);
                setUserPage(userSearchResult.currentPageNumber + 1);
            }
        });
    };

    const pricepackages: PricePackageType[] = [PricePackageType.All, PricePackageType.Free, PricePackageType.Optimal, PricePackageType.Premium];
    const accountStatuses: AccountStatus[] = [AccountStatus.All, AccountStatus['Active user'], AccountStatus['To remove'], AccountStatus['Payed user warned'],]

    useEffect(() => {
        getUsers(userSearchResult.pageSize, userSearchResult.currentPageNumber, filters, sortField, userSearchResult.order)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, userSearchResult.currentPageNumber, sortField, userSearchResult.order]);

    const onPricepackageFilterChanged = async (event: SelectChangeEvent<PricePackageType>) => {
        setUserPage(1);
        setUserFilters({ ...filters, pricePackageType: event.target.value as PricePackageType })
    }

    const onAccountstatusFilterChanged = async (event: SelectChangeEvent<AccountStatus>) => {
        setUserPage(1);
        setUserFilters({ ...filters, accountStatus: event.target.value as AccountStatus })
    }

    const onSearchFilterChanged = async (text: string) => {
        if (text.length > 0 && text.length < 3) return;
        setUserPage(1);
        setUserFilters({ ...filters, searchText: text })
    }

    return (
        <>
            <Helmet>
                <title>DoYourForm.com - Admin Users</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            {
                user ? <ManageUser user={user} onClose={() => setUser(null)} /> :
                    <Container maxWidth="lg" className='layout-container'>
                        {/* Page Header */}
                        <Grid container spacing={2} direction='row' justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                                <TextField
                                    label="Search"
                                    type="text"
                                    margin="normal"
                                    sx={{ width: 250, margin: 0 }}
                                    onChange={(event) => onSearchFilterChanged(event.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Grid>
                                    <FormControl sx={{ width: 200 }}>
                                        <InputLabel id="level-label">Price Package</InputLabel>
                                        <Select label="Price Package"
                                            defaultValue={pricepackages[0]}
                                            onChange={(e) => onPricepackageFilterChanged(e)}
                                        >
                                            {pricepackages.map((pricepackage, index) =>
                                                <MenuItem key={index} value={pricepackage} >{PricePackageType[pricepackage]}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FormControl sx={{ width: 200 }}>
                                    <InputLabel id="level-label">Account status</InputLabel>
                                    <Select label="Account status"
                                        defaultValue={accountStatuses[0]}
                                        onChange={(e) => onAccountstatusFilterChanged(e)}
                                    >
                                        {accountStatuses.map((status, index) =>
                                            <MenuItem key={index} value={status} >{AccountStatus[status]}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <AdminUserSortFieldBar fields={["First name", "Last name", "Email", "Email confirmed", "Start date",
                                    "Finish date", "Last login", "Created At"]} />
                            </Grid>
                        </Grid>
                        {/* Users List */}
                        {
                            userSearchResult.itemList.length > 0 ?
                                <div style={{ margin: "30px 0" }}>
                                    {userSearchResult.itemList.map(user =>
                                        <UserCard
                                            key={user.id}
                                            user={user}
                                            onEditAccount={user => setUserAccount(user)}
                                            onManageUser={user => setUser(user)}
                                        />
                                    )}
                                </div> :
                                loading ? <Spinner /> :
                                    error ? <ErrorMessage appearance="large">{error}</ErrorMessage> :
                                        <p style={{ padding: "0 15px" }}>No users</p>
                        }
                        <IntersectionObserverComponent onIntersection={handleIntersection} />
                        {(userSearchResult.itemList.length > 0 && loading) && <Spinner />}
                        {userAccount && <AccountForm user={userAccount} onClose={() => setUserAccount(null)} />}
                    </Container>
            }
        </>
    );
};
