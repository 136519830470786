import { useEffect, useState } from "react";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { isAppFileValid } from "../../../../hooks/IsAppFileValid";
import { QuestionFormProps } from "../types";
import { ISnackBarMessageState } from "../../../../types/common/snackBarMessageState";
import { IOption, InputType } from "../../../../types/question";
import { removeImageAxios, uploadImageAxios } from "../../../../api/image";
import { createOptionAxios, removeOptionAxios, updateOptionAxios } from "../../../../api/option";
import { updateQuestionAxios } from "../../../../api/question";
import { Alert, Box, Button, Checkbox, Chip, Divider, FormControlLabel, FormHelperText, Grid, Snackbar, SwipeableDrawer, TextField, Tooltip } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ErrorMessage from "../../../../components/Messages/ErrorMessage";
import MuiButton from "../../../../components/Button/MuiButton";

export default function QuestionForm({ question, onSave, closeForm }: QuestionFormProps): JSX.Element {
    const { auth } = useTypedSelector(state => state.auth);
    const { updateDraftFormQuestion } = useActions();
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [loadingRemoveOptionState, setLoadingRemoveOptionState] = useState<boolean>(false);
    const [loadingAddOptionState, setLoadingAddOptionState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<null | string>(null);
    const [questionText, setQuestionText] = useState<string>(question.text);
    const [optionsState, setOptionsState] = useState<IOption[]>(question.options);
    const [questionRequired, setQuestionRequired] = useState<boolean>(question.isRequired);
    const [questionShowUpload, setQuestionShowUpload] = useState<boolean>(question.showUpload);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);

    const [previewPictureVisible, setPreviewPictureVisible] = useState<boolean>(false);
    const [pictureFile, setPictureFile] = useState<File | null>(null);
    const [pictureSource, setPictureSource] = useState<string | ArrayBuffer | null | Blob>(null);

    useEffect(() => {
        if (question.picture) {
            setPictureSource((process.env.NODE_ENV === "production" ?
                process.env.REACT_APP_BASE_API_URL_PROD :
                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + question.picture)
            setPreviewPictureVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const onDeletePicture = () => {
        setPictureFile(null);
        setPictureSource(null);
        setPreviewPictureVisible(false);
    }

    const onPictureSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        if (e.target.files) {
            const file = e.target.files[0];
            if (!isAppFileValid(file)) {
                setSnackBarState({ message: "File is not valid!", severity: "warning" });
                return;
            }
            setPictureFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => { setPictureSource(reader.result) }
        }
        setPreviewPictureVisible(true);
    }

    const onChangeHandler = async (value: string, option: IOption) => {
        if (value.length === 0) return;
        const optionToUpdate = await updateOptionAxios({ ...option, value });
        setOptionsState(optionsState.map(o => { return o.id === optionToUpdate.id ? optionToUpdate : o }));
    }

    const onCreateOption = async () => {
        try {
            setLoadingAddOptionState(true);
            setErrorState(null);
            const optionToCreate = await createOptionAxios({
                questionId: question.id,
                name: 'NewOption',
                value: 'New Option',
                checked: false,
                createdAt: new Date()
            });
            setOptionsState([...optionsState, optionToCreate])
        } catch (error) {
            setErrorState("Unable to create a new option.");
        } finally {
            setLoadingAddOptionState(false);
        }
    }

    const onRemoveOption = async (id: string) => {
        try {
            setLoadingRemoveOptionState(true);
            await removeOptionAxios(id);
            question.text = questionText;
            question.isRequired = questionRequired;
            question.options = optionsState.filter(option => option.id !== id);
            setOptionsState(question.options);
            onSave(question);
        } catch {
            setErrorState("Unable to remove the option.");
        } finally {
            setLoadingRemoveOptionState(false);
        }
    }

    const toggleDrawer = (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
                return;
            }
            if (!open) closeForm();
        };

    const onSaveHandler = async () => {
        question.text = questionText;
        question.isRequired = questionRequired;
        question.showUpload = questionShowUpload;
        question.options = optionsState;
        try {
            setLoadingState(true);
            setErrorState(null)
            if (question.picture && pictureSource === null) { //picture file was deleted during editing the question
                await removeImageAxios(question.picture);
                question.picture = null;
            }
            if (pictureFile) { // picture file was updated so - remove old file, then upload new file:
                if (question.picture) await removeImageAxios(question.picture);
                const uploadedFile = await uploadImageAxios(pictureFile);
                question.picture = uploadedFile.fileName;
            }
            const questionToUpdate = await updateQuestionAxios(question);
            updateDraftFormQuestion(questionToUpdate);
            onSave(question);
            closeForm();
        } catch (error) {
            setErrorState(error.message || "Unable to save the question.");
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <SwipeableDrawer
            open={true}
            anchor='right'
            transitionDuration={1500}
            sx={{ zIndex: 1202 }}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={closeForm}
            />
            <Grid container direction={'column'} justifyContent="flex-start" spacing={2} sx={{ padding: '20px', marginTop: "30px" }}>
                {/* Picture */}
                <Grid item>
                    {
                        previewPictureVisible ?
                            <div className='preview-wrapper'>
                                <img
                                    src={(pictureSource && typeof (pictureSource) != undefined) && pictureSource.toString()}
                                    alt="" className='preview-image'
                                />
                                <div>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        onClick={onDeletePicture}
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                    >
                                        <DeleteOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Delete</span>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', borderRadius: "8px", margin: '0 3px' }}
                                    >
                                        <EditOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Replace</span>
                                        <input type="file" hidden onChange={onPictureSelected} />
                                    </Button>
                                </div>
                            </div>
                            :
                            <div className='uploader-wrapper'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '10px 0' }}
                                >
                                    <Tooltip title="Choose from gallery" placement="bottom">
                                        <ImageOutlinedIcon fontSize="large" sx={{ fill: '#5F7C78' }} />
                                    </Tooltip>
                                    <input type="file" hidden onChange={onPictureSelected} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    style={{ textTransform: 'none', boxShadow: 'none', margin: '15px 0' }}
                                >
                                    <ImageOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                    <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Add picture</span>
                                    <input type="file" hidden onChange={onPictureSelected} />
                                </Button>
                            </div>
                    }
                </Grid>
                <Grid item>
                    <TextField
                        error={questionText.length === 0}
                        sx={{ width: "300px" }}
                        multiline
                        rows={4}
                        label="Text"
                        value={questionText}
                        helperText={questionText.length === 0 && "Text is a required field."}
                        onChange={(event) => setQuestionText(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    Question type: {question.inputType}
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox
                            checked={questionRequired}
                            onChange={(event) => setQuestionRequired(event.target.checked)}
                        />}
                        label="Is Required?" />
                    <FormHelperText>Mark the question as required</FormHelperText>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox
                            checked={questionShowUpload}
                            onChange={(event) => setQuestionShowUpload(event.target.checked)}
                        />}
                        label="Show Upload?" />
                    <FormHelperText>Turn on - enable form recipients to upload images</FormHelperText>
                </Grid>
            </Grid>
            {/* Options section */}
            <div style={{ padding: '0 20px' }}><Divider><Chip label="Options" /></Divider></div>
            <Grid container direction={'column'} justifyContent="flex-start" spacing={2} sx={{ padding: '20px' }}>
                {
                    (question.inputType === InputType.Checkbox || question.inputType === InputType.Radio) &&
                    <Grid item>
                        <Grid container direction={'column'} justifyContent="flex-start" spacing={2} sx={{ mt: '20px' }} >
                            <Grid item>
                                {
                                    optionsState.map((option, index) => {
                                        return <Grid container key={option.id} direction="row" justifyContent="flex-start" alignItems="center" gap='15px' sx={{ margin: "10px 0" }} >
                                            <Grid item>
                                                {
                                                    question.inputType === InputType.Checkbox ?
                                                        <CheckBoxOutlineBlankIcon fontSize="small" sx={{ fill: '#5F7C78' }} /> :
                                                        <CircleOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                                }
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    sx={{ width: "200px" }}
                                                    label={`Option ${index + 1}`}
                                                    defaultValue={option.value}
                                                    onBlur={(event) => onChangeHandler(event.target.value, option)}
                                                    error={option.value.length === 0}
                                                    helperText={option.value.length === 0 && "Option is a required field."}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Box className="table-actions" sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                                                    <DeleteOutlineOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                                                        onClick={() => onRemoveOption(option.id)} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    })
                                }
                            </Grid>
                            <Grid item>
                                <MuiButton
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    onClickHandler={onCreateOption}
                                >
                                    {loadingAddOptionState && <CircularProgress size="1rem" sx={{ mr: '10px', color: "white" }} />} + Option
                                </MuiButton>
                            </Grid>
                            {
                                loadingRemoveOptionState &&
                                <div style={{ textAlign: "center" }}><CircularProgress size="1.5rem" sx={{ m: '5px' }} /></div>
                            }

                        </Grid>
                    </Grid>
                }
                {errorState && <ErrorMessage appearance="small" >{errorState}</ErrorMessage>}
                <Grid item>
                    <Grid container justifyContent="center" spacing={2} sx={{ padding: '20px' }} >
                        <Grid item sm={6} sx={{ textAlign: 'center' }}>
                            <MuiButton variant="outlined" onClickHandler={closeForm}>Cancel</MuiButton>
                        </Grid>
                        <Grid item sm={6} sx={{ textAlign: 'center' }}>
                            <MuiButton variant="outlined" onClickHandler={onSaveHandler}>
                                {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                            </MuiButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </SwipeableDrawer>
    )
}