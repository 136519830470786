import { useState } from 'react';
import { ISnackBarMessageState } from '../../../types/common/snackBarMessageState';
import { ReminderPeriod } from '../../../types/reminder';
import { Alert, Box, Grid, IconButton, Menu, MenuItem, Snackbar, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { ReminderCardProps } from './types'

export default function AdminReminderCard({ reminder, onPreview }: ReminderCardProps): JSX.Element {
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);

    const handleClose = () => setAnchorElUser(null);

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null);
    };

    return (
        <div style={{ display: "flex", padding: "15px 0" }}>
            <div style={{ flexBasis: "24px", flexShrink: 0 }}>
                <TimerOutlinedIcon fontSize='large' sx={{ fill: '#5F7C78' }} />
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ flexGrow: 1, margin: "0 10px", width: { xs: "140px" } }}>
                <span className='text-14'>Period: {ReminderPeriod[reminder.period]}</span>
                <span className='text-12'>Start: {(new Date(reminder.start.toString() + "Z")).toLocaleString()}</span>
                <span className='text-12'>Next: {(new Date(reminder.next.toString() + "Z")).toLocaleString()}</span>
                <span className='text-12'>Finish: {(new Date(reminder.finish.toString() + "Z")).toLocaleString()}</span>
            </Grid>
            <Box sx={{ flexBasis: { xs: "24px", sm: "140px" }, flexShrink: 0 }}>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, alignItems: 'right', justifyContent: "end" }}>
                    <Tooltip title='Preview' placement="top">
                        <PreviewOutlinedIcon
                            sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }}
                            onClick={() => onPreview(reminder)}
                        />
                    </Tooltip>
                </Box>
                {/* Hiden burger menu */}
                <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: "end" }}>
                    <Tooltip title="Preview">
                        <IconButton onClick={handleOpen} sx={{ p: 0 }}>
                            <MoreVertIcon sx={{ color: "var(--darkGrey)" }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="center" gap="10px"
                                onClick={() => onPreview(reminder)}
                            >
                                <PreviewOutlinedIcon sx={{ cursor: 'pointer', margin: '0 5px', fill: '#5F7C78' }} />
                                <span>Preview</span>
                            </Grid>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </div>
    )
}
