import { useState } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { FillFormQuestionCardProps } from "../types";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import ImageWrapper from "../../../components/ImageWrapper/ImageWrapper";
import UploadBlock from "./UploadBlock";

export default function RadioGroupQuestionCard({ question }: FillFormQuestionCardProps) {
    const [value, setValue] = useState('');
    const { showResults } = useTypedSelector(state => state.formFill);
    const { updateFormFillOption } = useActions();

    const isOptionValueValid = (): boolean => {
        if (question.isRequired) return question.options.filter((v) => v.checked === true).length > 0;
        return true;
    }

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (event.target.checked) {
            let currentCheckedOption = { ...question.options.filter(o => o.id === event.target.id)[0] };
            let prevCheckedOption = { ...question.options.filter(o => o.checked === true)[0] };
            currentCheckedOption.checked = true;
            updateFormFillOption(currentCheckedOption);
            if (Object.keys(prevCheckedOption).length > 0) {
                prevCheckedOption.checked = false;
                updateFormFillOption(prevCheckedOption);
            }
        }
    };

    return (
        <Grid container direction="column" gap={1} justifyContent="center" alignItems="flex-start" >
            <FormControl
                sx={{ margin: "24px 0", width: "100%" }}
                required={question.isRequired}
                component="fieldset"
                error={!isOptionValueValid()} // source: https://mui.com/material-ui/react-radio-button/#show-error
                variant="standard"
            >
                {
                    question.picture &&
                    <ImageWrapper source={(process.env.NODE_ENV === "production" ?
                        process.env.REACT_APP_BASE_API_URL_PROD :
                        process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + question.picture} />
                }
                <FormLabel component="p">{question.text}</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="quiz"
                    value={value}
                    onChange={onChangeHandler}
                >
                    {
                        question.options.map(option =>
                            <FormControlLabel
                                key={option.id}
                                value={option.value}
                                control={<Radio id={option.id} checked={option.checked} disabled={showResults} />}
                                label={option.value}
                            />)
                    }
                </RadioGroup>
                <FormHelperText>{!isOptionValueValid() && "Choose an option"}</FormHelperText>
            </FormControl>
            {question.showUpload && <UploadBlock question={question} isDisabled={showResults} />}
            {
                showResults &&
                <TextField
                    label="Sender's comment"
                    fullWidth
                    disabled
                    margin="normal" multiline rows={2}
                    variant='outlined' style={{ height: 'none' }}
                    value={question.comment || ''}
                />
            }
        </Grid>
    )
}
