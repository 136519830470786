import { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { clonedObject } from '../../hooks/clonedObject';
import { useActions } from '../../hooks/useActions';
import { AppFormStatus, SentByOption } from '../../types/appForm';
import { InputType } from '../../types/question';
import { MailTemplateKey } from '../../types/common/email';
import { PreviewFormProps } from './types';
import { ISnackBarMessageState } from '../../types/common/snackBarMessageState';
import { sendEmailAxios } from '../../api/email';
import { Alert, Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, Snackbar, TextField } from '@mui/material'
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import CheckboxCard from './CheckboxCard';
import RadioCard from './RadioCard';
import TextCard from './TextCard';

export default function PreviewForm({ form, user, closeForm }: PreviewFormProps) {
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [sendEmailState, setSendEmailSend] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const { updateCompletedFormComment } = useActions();

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const onChangeComment = (comment: string) => {
        let formToUpdate = clonedObject(form);
        formToUpdate.comment = comment;
        if (!formToUpdate.isRead) formToUpdate.isRead = true;
        updateCompletedFormComment(formToUpdate);
    }

    const onSave = async () => {
        try {
            if (sendEmailState) {
                setSnackBarState(null);
                setLoadingState(true)
                await sendEmailAxios({
                    to: form.contact.email,
                    from: process.env.REACT_APP_BASE_EMAIL,
                    mailTemplateKey: MailTemplateKey.NewFormCommentAdded,
                    transformWith: [
                        form.contact.firstName + " " + form.contact.lastName,
                        user.firstName + " " + user.lastName,
                        form.title,
                        process.env.REACT_APP_ROOT_PROD + "/forms/f/" + form.id
                    ]
                })
            }
            closeForm();
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to send email", severity: "error" });
            setSendEmailSend(false);
        } finally {
            setLoadingState(false);
        }
    }

    return (
        <Container maxWidth="lg" className='layout-container' sx={{ position: "relative" }}>
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "red" }, position: "absolute", top: "10px", right: "10px" }}
                onClick={closeForm}
            />
            <Grid container spacing={2} direction='column' justifyContent='center' alignItems='center'>
                {
                    user.logo &&
                    <Grid item sx={{ textAlign: 'center' }}>
                        <img
                            src={(process.env.NODE_ENV === "production" ?
                                process.env.REACT_APP_BASE_API_URL_PROD :
                                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + user.logo}
                            alt="Logo"
                            width="100%"
                            style={{ margin: '25px 0 12px' }}
                        />
                    </Grid>
                }
                <Grid item sx={{ width: '100%' }}>
                    <p className='pt1' style={{ textAlign: 'center' }}>{form.title}</p>
                    {
                        form.logo &&
                        <img src={(process.env.NODE_ENV === "production" ?
                            process.env.REACT_APP_BASE_API_URL_PROD :
                            process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + form.logo}
                            alt="Form Logo"
                            width="100%"
                            style={{ marginBottom: "25px" }}
                        />
                    }
                    <h3 style={{ lineHeight: 1.2, textAlign: 'center', margin: "0 0 24px" }}>
                        {form.status === AppFormStatus.Sent && "is sent."}{form.status === AppFormStatus.Done && "is completed."}
                    </h3>
                </Grid>
            </Grid>
            <div style={{ margin: "30px 0" }}>
                {ReactHtmlParser(form.statement)}
            </div>
            <Divider><Chip label={form.sentByOption === SentByOption.Email ? "Answers & Comments" : "Answers"} /></Divider>
            <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
                {
                    form.questions.map(question => {
                        switch (question.inputType) {
                            case InputType.Checkbox:
                                return <CheckboxCard
                                    key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                    commentsDisabled={form.status !== AppFormStatus.Done}
                                />
                            case InputType.Radio:
                                return <RadioCard
                                    key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                    commentsDisabled={form.status !== AppFormStatus.Done}
                                />
                            default://InputType.Text || InputType.Number || InputType.Date || InputType.Time
                                return <TextCard
                                    key={question.id} question={question} showComment={form.sentByOption === SentByOption.Email}
                                    commentsDisabled={form.status !== AppFormStatus.Done}
                                />
                        }
                    })
                }
                {
                    form.sentByOption === SentByOption.Email &&
                    <Grid item sx={{ width: "100%" }}>
                        <TextField
                            disabled={form.status !== AppFormStatus.Done}
                            label="Comment on the form result"
                            fullWidth
                            margin="normal" multiline rows={3}
                            variant='outlined' style={{ height: 'none' }}
                            defaultValue={form.comment || ''}
                            onBlur={(event) => onChangeComment(event.target.value)}
                        />
                    </Grid>
                }
                {
                    (form.sentByOption === SentByOption.Email && form.status === AppFormStatus.Done) &&
                    <Grid item sx={{ width: "100%" }}>
                        <FormControlLabel
                            label={`Send email to ${form.contact.firstName} ${form.contact.lastName} about adding my comments`}
                            control={
                                <Checkbox
                                    checked={sendEmailState}
                                    onChange={(event) => setSendEmailSend(event.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                        />
                    </Grid>
                }
            </Grid>
            <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Button
                    variant="outlined"
                    onClick={onSave}
                    sx={{
                        marginLeft: (form.sentByOption === SentByOption.Email && form.status === AppFormStatus.Done) ? "30px" : 0,
                        textTransform: 'none',
                        borderRadius: "16px"
                    }}
                >
                    {loadingState && <CircularProgress size={16} sx={{ marginRight: "6px" }} />} Close
                </Button>
            </div>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </Container>
    )
}
