import axios from 'axios';
import { OrderType } from '../types/common/orderType';
import { ISearchResult } from '../types/common/searchResult';
import { IPricepackage } from '../types/pricepackage';

/**
 * Get list of Pricepackages
 * @param limit<number> Page size
 * @param page<number> Current page
 * @param sortField<string> Field for sorting
 * @param order<OrderType> Sort direction (Ascending / Descending / None)
 */
export async function getPricepackagesAxios(
    limit: number,
    page: number,
    sortField: string,
    order: OrderType): Promise<ISearchResult<IPricepackage>> {
    return (
        await axios.get(`/pricepackage/get?limit=${limit}&page=${page}&sortField=${sortField}&order=${order}`)).data;
}

/**
 * Get Pricepackage specified by identifier
 * @param id<string> Pricepackage identifier
 */
export async function getPricepackageByIdAxios(id: number): Promise<IPricepackage> {
    return (await axios.get(`/pricepackage/get/${id.toString()}`)).data;
}

/**
 * Creates a new Pricepackage
 * @param Pricepackage<IPricepackage> object of type IPricepackage
 * @returns<IPricepackage> Created pricepackage
 */
export async function createPricepackageAxios(Pricepackage: IPricepackage): Promise<IPricepackage> {
    return (await axios.post("/pricepackage/create", Pricepackage)).data;
}

/**
 * Updates the existing Pricepackage
 * @param Pricepackage<IPricepackage> Object of type IPricepackage
 * @returns<IPricepackage> Updated Pricepackage object
 */
export async function updatePricepackageAxios(Pricepackage: IPricepackage): Promise<IPricepackage> {
    return (await axios.put("/pricepackage/update", Pricepackage)).data;
}

/**
 * Delete's the Pricepackage specified by identifier
 * @param id<string> Identifier
 */
export async function removePricepackageAxios(id: string): Promise<void> {
    return await axios.delete(`/pricepackage/delete?id=${id}`);
}