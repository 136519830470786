import { Link } from "react-router-dom";
import { RouteNames } from "../../../routing";
import { Helmet } from "react-helmet";
import { Container, Grid, Typography } from "@mui/material";
import PageHeader from "../../../components/PageHeader/PageHeader";
import RegisterForm from "./RegisterForm";

export default function RegisterPage(): JSX.Element {
    return (
        <Container maxWidth="lg" className='layout-container' >
            <Helmet>
                <title>DoYourForm.com - Sign Up</title>
                <meta name="description" content="Forms for remote work and control with customers, business units and personnel, create your form with questions, add images, answer options and your comments - DoYourForm.com" />
            </Helmet>
            <PageHeader title="Sign Up" />
            <Grid container direction="column" alignItems="center">
                <RegisterForm />
                <Typography component={'p'} my={3} >
                    Or <Link to={RouteNames.LOGIN}>Sign In</Link> if you already registered.
                </Typography>
            </Grid>
        </Container>
    )
}
