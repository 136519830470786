import { IImage } from "./image";

export interface IQuestion {
    id?: string;
    appFormId?: string;
    picture?: string;
    text: string;
    inputType: InputType;
    rank: number;
    isRequired: boolean;
    showUpload: boolean;
    comment?: string;
    options?: IOption[];
    images?: IImage[];
}

export interface IOption {
    id?: string;
    questionId?: string;
    name?: string;
    value?: string;
    checked: boolean;
    createdAt: Date;
}

export enum InputType {
    Checkbox = "checkbox",
    Color = "color",
    Date = "date",
    Datetime = "datetime",
    Email = "email",
    File = "file",
    Number = "number",
    Password = "password",
    Radio = "radio",
    Submit = "submit",
    Tel = "tel",
    Time = "time",
    Text = "text",
    Url = "url",
}