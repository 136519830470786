import axios from 'axios';
import { IAccount } from '../types/account';

/**
 * Updates the existing Account
 * @param account<IAccount> Object of type IAccount
 * @returns<IAccount> Updated Account object
 */
export async function updateAccountAxios(account: IAccount): Promise<IAccount> {
    return (await axios.put("/account/update", account)).data;
}

/**
 * Refresh Registered User account
 */
export async function refreshRegisteredUserAccountAxios(): Promise<IAccount> {
    return (await axios.get(`/account/refresh`)).data;
}

/**
 * Refresh account (Admin role)
 * @param id<string> user's account id
 */
export async function refreshAccountAxios(id: string): Promise<IAccount> {
    return (await axios.get(`/account/refresh/${id}`)).data;
}

/**
 * Delete's the User and his account specified by account identifier
 * @param id<string> Account identifier
 */
export async function removeUserAccountAxios(id: string): Promise<void> {
    return await axios.delete(`/account/deleteuseraccount/${id}`);
}