import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { isAppFileValid } from "../../../hooks/IsAppFileValid";
import { IUser } from "../../../types/user";
import { removeImageAxios, uploadImageAxios } from "../../../api/image";
import { updateUserAxios } from "../../../api/user";
import { ISnackBarMessageState } from "../../../types/common/snackBarMessageState";
import { Alert, Button, Grid, IconButton, InputAdornment, Snackbar, TextField, Tooltip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MuiButton from "../../../components/Button/MuiButton";
import PaletteIcon from '@mui/icons-material/Palette';

export default function BrandingForm(): JSX.Element {
    const { auth } = useTypedSelector(state => state.auth);
    const { setUserLogo, updateAuthUser } = useActions();
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarState, setSnackBarState] = useState<null | ISnackBarMessageState>(null);
    const [previewLogoVisible, setPreviewLogoVisible] = useState<boolean>(false);
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoSource, setLogoSource] = useState<string | ArrayBuffer | null | Blob>(null);

    useEffect(() => {
        if (auth.user.logo) {
            setLogoSource((process.env.NODE_ENV === "production" ?
                process.env.REACT_APP_BASE_API_URL_PROD :
                process.env.REACT_APP_BASE_API_URL_DEV) + "/uploads/images/" + auth.user.logo)
            setPreviewLogoVisible(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackBarState(null)
    };

    const validationSchema = Yup.object({
        brandColor1: Yup.string()//todo: implement custom validation like it was done in ContactForm
            .required('Required field.')
            .max(20, 'Brand Color 1 may not be greater than 20 characters.'),
        brandColor2: Yup.string()//todo: implement custom validation like it was done in ContactForm
            .required('Required field.')
            .max(20, 'Brand Color 2 may not be greater than 20 characters.'),
    })

    const defaultValues: IUser = {
        id: auth.user.id,
        account: auth.user.account,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
        email: auth.user.email,
        emailConfirmed: auth.user.emailConfirmed,
        phoneNumber: auth.user.phoneNumber || '',
        avatar: auth.user.avatar,
        logo: auth.user.logo,
        brandColor1: auth.user.brandColor1 || '',
        brandColor2: auth.user.brandColor2 || '',
        createdAt: auth.user.createdAt
    }

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema), defaultValues
    })

    const onDeleteLogo = () => {
        setLogoFile(null);
        setLogoSource(null);
        setPreviewLogoVisible(false);
    }

    const onLogoSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (auth.user.account.currentFileSize >= auth.user.account.fileSizeLimit) {
            setSnackBarState({ message: "You have used the files size limit.", severity: "error" });
            return;
        }
        if (e.target.files) {
            const file = e.target.files[0];
            if (!isAppFileValid(file)) {
                setSnackBarState({ message: "File is not valid", severity: "warning" });
                return;
            }
            setLogoFile(file)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => { setLogoSource(reader.result) }
        }
        setPreviewLogoVisible(true);
    }

    const onSubmit = async (user: IUser) => {
        try {
            if (user.logo && logoSource === null) { // logo file was deleted during editing the form
                await removeImageAxios(user.logo);
                user.logo = null;
                setUserLogo(null);
            }
            if (logoFile) { // logo file was updated so - remove old file, then upload new file:
                if (user.logo) await removeImageAxios(user.logo);
                const uploadedFile = await uploadImageAxios(logoFile);
                user.logo = uploadedFile.fileName;
                setUserLogo(uploadedFile.fileName);
            }
            setLoading(true);
            const updatedUser = await updateUserAxios(user);
            updateAuthUser(updatedUser)
            setSnackBarState({ message: "Branding settings updated.", severity: "success" });
        } catch (error) {
            setSnackBarState({ message: error.message || "Unable to save branding settings.", severity: "error" });
        } finally {
            setLoading(false);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" alignContent="center">
                <Grid item sx={{ marginBottom: '10px' }}>
                    {// Logo
                        previewLogoVisible ?
                            <div className='preview-wrapper'>
                                <img
                                    src={(logoSource && typeof (logoSource) != undefined) && logoSource.toString()}
                                    alt="Preview" className='preview-image'
                                />
                                <div>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        onClick={onDeleteLogo}
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '3px' }}
                                    >
                                        <DeleteOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Delete</span>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '3px' }}
                                    >
                                        <EditOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                        <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Replace</span>
                                        <input
                                            type="file"
                                            hidden
                                            onChange={onLogoSelected}
                                        />
                                    </Button>
                                </div>
                            </div>
                            :
                            <div className='uploader-wrapper'>
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{ textTransform: 'none', backgroundColor: 'transparent', boxShadow: 'none', margin: '10px 0' }}
                                >
                                    <Tooltip title="Choose logo" placement="bottom">
                                        <ImageOutlinedIcon fontSize="large" sx={{ fill: '#5F7C78' }} />
                                    </Tooltip>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={onLogoSelected}
                                    />
                                </Button>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    style={{ textTransform: 'none', boxShadow: 'none', margin: '15px 0' }}
                                >
                                    <ImageOutlinedIcon fontSize="small" sx={{ fill: '#5F7C78' }} />
                                    <span className="a-small-blue" style={{ margin: '2px 5px 0' }}>Add Company Logo</span>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={onLogoSelected}
                                    />
                                </Button>
                            </div>
                    }
                </Grid>
                <Grid item>
                    <Controller name="brandColor1" control={control}
                        render={({ field }) =>
                            <TextField  {...field} label="Brand Color 1" type={"color"} margin="normal" fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" >
                                                <PaletteIcon sx={{ fill: '#5F7C78' }} />
                                            </IconButton>
                                        </InputAdornment>),
                                }}
                                error={Boolean(errors.brandColor1)} helperText={errors.brandColor1?.message} />}
                    />
                </Grid>
                <Grid item>
                    <Controller name="brandColor2" control={control}
                        render={({ field }) =>
                            <TextField  {...field} label="Brand Color 2" type={"color"} fullWidth margin="normal"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" >
                                                <PaletteIcon sx={{ fill: '#5F7C78' }} />
                                            </IconButton>
                                        </InputAdornment>),
                                }}
                                error={Boolean(errors.brandColor2)} helperText={errors.brandColor2?.message} />}
                    />
                </Grid>
                <Grid item sx={{ textAlign: "center", margin: { xs: '24px 0' } }}>
                    <MuiButton variant="outlined" type="submit">
                        {loading && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                    </MuiButton>
                </Grid>
            </Grid>
            <Snackbar
                open={snackBarState !== null}
                autoHideDuration={4000}
                onClose={onSnackbarClose}
            >
                <Alert severity={snackBarState?.severity}>{snackBarState?.message}</Alert>
            </Snackbar>
        </form>
    )
}