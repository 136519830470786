import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useActions } from "../../../hooks/useActions";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import moment from "moment";
import { AccountStatus, IAccount } from "../../../types/account";
import { PricePackageType } from "../../../types/pricepackage";
import { AccountFormProps } from './types';
import { FormControl, Grid, InputLabel, MenuItem, Select, SwipeableDrawer, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import MuiButton from "../../../components/Button/MuiButton";
import { updateAccountAxios } from "../../../api/account";
import ErrorMessage from "../../../components/Messages/ErrorMessage";

export default function AccountForm({ user, onClose }: AccountFormProps) {
    const { updateUserAccount } = useActions();
    const [startDateState, setStartDateState] = useState<Date>(user.account.startDate);
    const [finishDateState, setFinishDateState] = useState<Date>(user.account.finishDate);
    const [loadingState, setLoadingState] = useState<boolean>(false);
    const [errorState, setErrorState] = useState<null | string>(null);
    const pricepackages: PricePackageType[] = [PricePackageType.Free, PricePackageType.Optimal, PricePackageType.Premium];
    const accountStatuses: AccountStatus[] = [AccountStatus["All"], AccountStatus["Active user"], AccountStatus["Payed user warned"], AccountStatus["To remove"]];

    const toggleDrawer = (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event && event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) return;
            if (!open) onClose();
        };

    const validationSchema = Yup.object({
        contactsLimit: Yup.number()
            .required('Required field.'),
        currentFileSize: Yup.number()
            .required('Required field.'),
        remindersLimit: Yup.number()
            .required('Required field.')
    })

    const defaultValues: IAccount = {
        id: user.account.id,
        pricePackageType: user.account.pricePackageType,
        userId: user.account.userId,
        accountStatus: user.account.accountStatus,
        price: user.account.price,
        startDate: user.account.startDate,
        finishDate: user.account.finishDate,
        fileSizeLimit: user.account.fileSizeLimit,
        currentFileSize: user.account.currentFileSize,
        contactsLimit: user.account.contactsLimit,
        currentContacts: user.account.currentContacts,
        remindersLimit: user.account.remindersLimit,
        currentReminders: user.account.currentReminders,
        createdAt: user.account.createdAt,
        notes: user.account.notes || '',
        lastLoginDate: user.account.lastLoginDate
    }

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues
    })

    const onSubmit = async (account: IAccount): Promise<void> => {
        account.startDate = startDateState;
        account.finishDate = finishDateState;
        try {
            setLoadingState(true);
            setErrorState(null);
            const accountToUpdate = await updateAccountAxios(account)
            updateUserAccount({ ...user, account: accountToUpdate });
            onCancelHandler();
        } catch (error) {
            setErrorState(error.toString());
        } finally {
            setLoadingState(false);
        }
    }

    const onCancelHandler = () => {
        reset();
        onClose();
    }

    return (
        <SwipeableDrawer
            open={true}
            anchor='right'
            transitionDuration={1500}
            sx={{ zIndex: 1202 }}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
        >
            <CloseIcon
                sx={{ cursor: 'pointer', fill: '#5F7C78', '&:hover': { fill: "var(--red)" }, position: "absolute", top: "15px", right: "15px" }}
                onClick={onCancelHandler}
            />
            <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '360px' }}>
                <Grid container direction={'column'} justifyContent="center" spacing={2} sx={{ padding: '50px 20px' }}>
                    <Grid item>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="level-label">Price Package</InputLabel>
                            <Controller
                                name="pricePackageType"
                                defaultValue={pricepackages[0]}
                                control={control}
                                render={({ field }) => (
                                    <Select label="Price Package" {...field}>
                                        {pricepackages.map((pricepackage, index) =>
                                            <MenuItem key={index} value={pricepackage}>{PricePackageType[pricepackage]}</MenuItem>)}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: 300 }}>
                            <InputLabel id="level-label">Account Status</InputLabel>
                            <Controller
                                name="accountStatus"
                                defaultValue={accountStatuses[0]}
                                control={control}
                                render={({ field }) => (
                                    <Select label="Account Status" {...field}>
                                        {accountStatuses.map((accountStatus, index) =>
                                            <MenuItem key={index} value={accountStatus}>{AccountStatus[accountStatus]}</MenuItem>)}
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Controller name="fileSizeLimit" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="File Size Limit" type="number" margin="normal" fullWidth
                                    error={Boolean(errors.fileSizeLimit)} helperText={errors.fileSizeLimit?.message} />} />
                    </Grid>
                    <Grid item>
                        <Controller name="contactsLimit" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Contacts Limit" type="number" margin="normal" fullWidth
                                    error={Boolean(errors.contactsLimit)} helperText={errors.contactsLimit?.message} />} />
                    </Grid>
                    <Grid item>
                        <Controller name="remindersLimit" control={control}
                            render={({ field }) =>
                                <TextField  {...field} label="Reminders Limit" type="number" margin="normal" fullWidth
                                    error={Boolean(errors.remindersLimit)} helperText={errors.remindersLimit?.message} />} />
                    </Grid>
                    <Grid item>
                        <Controller name="startDate" control={control}
                            render={({ field }) =>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        {...field}
                                        label="Start Date"
                                        value={moment(startDateState)}
                                        onChange={(newValue) => setStartDateState(newValue.toDate())}
                                    />
                                </LocalizationProvider>
                            } />
                    </Grid>
                    <Grid item>
                        <Controller name="finishDate" control={control}
                            render={({ field }) =>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        {...field}
                                        label="Finish Date"
                                        value={moment(finishDateState)}
                                        onChange={(newValue) => setFinishDateState(newValue.toDate())}
                                    />
                                </LocalizationProvider>
                            } />
                    </Grid>
                    <Grid item>
                        <Controller name="notes" control={control}
                            render={({ field }) =>
                                <TextField {...field} label="Notes" fullWidth
                                    margin="normal" multiline rows={4} variant='outlined' style={{ height: 'none' }}
                                    error={Boolean(errors.notes)} helperText={errors.notes?.message} />} />
                    </Grid>
                    {errorState && <ErrorMessage appearance="small" >{errorState}</ErrorMessage>}
                </Grid>
                <Grid container direction="row" justifyContent="space-around" alignItems="center" mb={2}>
                    <MuiButton variant="outlined" onClickHandler={onCancelHandler}>Cancel</MuiButton>
                    <MuiButton variant="outlined" type="submit">
                        {loadingState && <CircularProgress size="1rem" sx={{ mr: '10px' }} />} Save
                    </MuiButton>
                </Grid>
            </form>
        </SwipeableDrawer>
    )
}
